import React, { Component } from "react";
import "./video.css";
import { Form } from "react-bootstrap";
import Axios from "axios";
import swal from 'sweetalert';


export class Videos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/user/",
            title: "",
            description: "",
            file: "",
            file_Type: "Video",
            videoUrl: "",
            dataStatus: "false",
            data: []
        };
    }
    componentDidMount() {
        var id = sessionStorage.getItem("id");

        let formData = new FormData();
        formData.append("id", id);
        var url = this.state.url + "getVideoData.php";
        Axios.post(url, formData)
            .then((res) => {
                this.setState({ data: res.data });
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    handle = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === "file_Type") {
            if (e.target.value === "Video") {
                this.setState({ videoUrl: "" });
            }
        }
    }
    submit = (e) => {
        e.preventDefault();
        document.getElementsByClassName("fa-spin")[8].classList.add("fa-refresh");
        document.getElementById("addvideo").disabled = true;
        if (this.state.file_Type === "Video" && document.getElementById("videoFile").files[0].size / 1048576 > 10) {
            document.getElementsByClassName("fa-spin")[8].classList.remove("fa-refresh");
            document.getElementById("addvideo").disabled = false;
            swal({
                title: "Error!",
                text: "File size must be less then or equal to 10MB!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
            return;
        }
        var id = sessionStorage.getItem("id");

        let formData = new FormData();
        formData.append("id", id);
        formData.append("title", this.state.title);
        formData.append("description", this.state.description);
        formData.append("fileType", this.state.file_Type);
        var fileData = "";
        if (this.state.file_Type === "Video") {
            fileData = document.getElementById("videoFile").files[0];
        }
        else {
            fileData = this.state.videoUrl;
        }
        formData.append("file", fileData);

        var url = this.state.url + "updateVideo.php";
        if (this.state.title === "" || this.state.description === "") {
            document.getElementsByClassName("fa-spin")[8].classList.remove("fa-refresh");
            document.getElementById("addvideo").disabled = false;
            swal({
                title: "Error!",
                text: "Fill the whole form to proceed further!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
        else {
            document.getElementById("addvideo").disabled = true;
            Axios.post(url, formData).then((res) => {
                // alert(res.data);
                if (res.data === 1) {
                    document.getElementsByClassName("fa-spin")[8].classList.remove("fa-refresh");
                    document.getElementById("addvideo").disabled = false;
                    swal({
                        title: "Added!",
                        text: "Data added successfully!",
                        button: "Close",
                        icon: "success",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                    document.getElementById("videoForm").reset();
                    var id = sessionStorage.getItem("id");
                    let formData = new FormData();
                    formData.append("id", id);
                    var url = this.state.url + "getVideoData.php";
                    Axios.post(url, formData)
                        .then((res) => {
                            this.setState({ data: res.data });
                            // console.log(res.data);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            });
        }
    }
    Delete = (e) => {
        e.preventDefault();
        var id = e.target.value;

        swal({
            title: "Are you sure?",
            text: "Once removed, you will have to add again!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let formData = new FormData();
                    formData.append("id", id);
                    var url = this.state.url + "deleteVideoData.php";

                    Axios.post(url, formData)
                        .then((res) => {
                            swal({
                                title: "Deleted!",
                                text: "Data deleted successfully!",
                                button: "Close",
                                icon: "error",
                                timer: 3000,
                                button: {
                                    confirm: {
                                        text: "OK",
                                        value: true,
                                        visible: true,
                                        className: "",
                                        closeModal: true
                                    }
                                }
                            });
                            var id = sessionStorage.getItem("id");
                            let formData = new FormData();
                            formData.append("id", id);
                            var url = this.state.url + "getVideoData.php";
                            Axios.post(url, formData)
                                .then((res) => {
                                    this.setState({ data: res.data });
                                    // console.log(res.data);
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                        })
                        .catch((err) => {
                            // console.log(err);
                            this.setState({
                                res: err,
                            });
                        });
                }
            });
    }
    render() {
        return (
            <>
                <div id="messageAlertVideo">
                    <p id="messageVideo"></p>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12 col-12 px-0 mx-0">
                        <div className="Protofolio bg-white">
                            <h6 className="pl-3 Protofolio_title">Videos</h6>
                            <h5 className="text-center mt-3">
                                Simple & Best Way To Showcase Your Work
                            </h5>

                            <form id="videoForm" onSubmit={this.submit}>
                                <div className="form_section p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Form.Group className="row">
                                                <div className="col-sm-12">
                                                    <Form.Control
                                                        type="text"
                                                        id="title"
                                                        name="title"
                                                        onChange={this.handle}
                                                        placeholder="Video Title"
                                                        className="form-control-sm"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-12">
                                            <Form.Group className="row">
                                                <div className="col-sm-12">
                                                    <textarea className="form-control form-control-sm" placeholder="Enter Video Description" style={{ height: '100px' }} name="description" id="description" onChange={this.handle}></textarea>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="row">
                                                <div className="col-sm-12">
                                                    <label>Select Type</label>
                                                    <select id="file_Type" name="file_Type" className="form-control" required onChange={this.handle}>
                                                        <option value="Video" selected >Video</option>
                                                        <option value="URL" >URL</option>
                                                    </select>
                                                </div>
                                            </Form.Group>
                                        </div>

                                        {
                                            this.state.file_Type === "Video" ?
                                                (
                                                    <div className="col-md-12">
                                                        <Form.Group className="row">
                                                            <div className="col-sm-12">
                                                                <input
                                                                    required
                                                                    type="file"
                                                                    id="videoFile"
                                                                    name="videoFile"
                                                                    onChange={this.handle}
                                                                    accept=".mp4, .mov, .avi, .mwv, .webm, .mkv, .flv"
                                                                    className="form-control uplaod_image"
                                                                ></input>
                                                                <span style={{ color: 'grey', fontSize: '13px' }}>Allowed Files are: .mp4, .mov, .avi, .mwv, .webm, .mkv, .flv<br />Maximum Allowed File Size is: 10MB</span>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                ) :
                                                (
                                                    <div className="col-md-12">
                                                        <Form.Group className="row">
                                                            <div className="col-sm-12">
                                                                <input
                                                                    required
                                                                    type="text"
                                                                    id="videoUrl"
                                                                    name="videoUrl"
                                                                    value={this.state.videoUrl}
                                                                    onChange={this.handle}
                                                                    className="form-control"
                                                                ></input>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                )
                                        }

                                        <div className="col-md-12 mb-3">
                                            <button
                                                class="post_jp_btn"
                                                name="submit"
                                                value="Submit"
                                                type="submit"
                                                id="addvideo"
                                            >
                                                Add Video <i class="fa fa-spin"></i>
                                            </button>
                                        </div>


                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>

                    <div
                        className="col-md-12 grid-margin stretch-card"
                        style={{ marginTop: "10px" }}
                    >
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Certificate Details</h4>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>SN.</th>
                                                <th>Title</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data.length === 0 ? (
                                                <tr>
                                                    <td colSpan="3" className="text-center">
                                                        No Records Found
                                                    </td>
                                                </tr>
                                            ) : (
                                                this.state.data.map((ele, i) => {
                                                    return (
                                                        <tr>
                                                            <td>{i + 1}</td>
                                                            <td>{ele.title}</td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-danger m-1 badge badge-danger"
                                                                    onClick={this.Delete}
                                                                    value={ele.id}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}