import React, { Component } from "react";
import "./interest.css";
import { Form } from "react-bootstrap";
import Axios from "axios";
import swal from 'sweetalert';


class Interest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/user/",
      id: "",
      interest: "",
      data: []
    };
  }

  componentDidMount() {
    var id = sessionStorage.getItem("id");
    let formData = new FormData();
    formData.append("id", id);
    var url = this.state.url + "getInterestData.php";
    Axios.post(url, formData)
      .then((res) => {
        this.setState({ data: res.data });
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submit = (e) => {
    e.preventDefault();
    var id = sessionStorage.getItem("id");
    document.getElementsByClassName("fa-spin")[12].classList.add("fa-refresh");
    document.getElementById("interestSaveButton").disabled = true;

    let formData = new FormData();
    formData.append("id", id);
    formData.append("interest", this.state.interest);

    var url = this.state.url + "updateInterest.php";
    if (this.state.interest === "") {
      document.getElementsByClassName("fa-spin")[12].classList.remove("fa-refresh");
      document.getElementById("interestSaveButton").disabled = false;
      swal({
        title: "Error!",
        text: "Please fill all the data before submitting this form",
        button: "Close",
        icon: "error",
        timer: 3000,
        button: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      });
    }
    else {
      Axios.post(url, formData).then((res) => {
        if (res.data === 1) {
          document.getElementsByClassName("fa-spin")[11].classList.remove("fa-refresh");
          document.getElementById("interestSaveButton").disabled = false;
          swal({
            title: "Added!",
            text: "Interest added successfully!",
            button: "Close",
            icon: "success",
            timer: 3000,
            button: {
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "",
                closeModal: true
              }
            }
          });
          this.setState({
            interest: ''
          })
          document.getElementById("InterestForm").reset();
          var id = sessionStorage.getItem("id");
          let formData = new FormData();
          formData.append("id", id);
          var url = this.state.url + "getInterestData.php";
          Axios.post(url, formData)
            .then((res) => {
              this.setState({ data: res.data });
              // console.log(res.data);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    }
  };

  Delete = (e) => {
    e.preventDefault();
    var id = e.target.value;

    swal({
      title: "Are you sure?",
      text: "Once removed, you will have to add again!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          let formData = new FormData();
          formData.append("id", id);
          var url = this.state.url + "deleteInterestData.php";

          Axios.post(url, formData)
            .then((res) => {
              swal({
                title: "Deleted!",
                text: "Interest deleted successfully!",
                button: "Close",
                icon: "success",
                timer: 3000,
                button: {
                  confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                  }
                }
              });
              var id = sessionStorage.getItem("id");
              let formData = new FormData();
              formData.append("id", id);
              var url = this.state.url + "getInterestData.php";
              Axios.post(url, formData)
                .then((res) => {
                  this.setState({ data: res.data });
                  // console.log(res.data);
                })
                .catch(function (error) {
                  console.log(error);
                });
            })
            .catch((err) => {
              // console.log(err);
              this.setState({
                res: err,
              });
            });
        }
      });
  };

  render() {

    return (
      <>
        {sessionStorage.getItem("id") ? (
          <>
            <div className="row mt-2">
              <div className="col-md-12 col-12 px-0 mx-0">
                <div className=" Project pb-5 bg-white">
                  <h6 className="pl-3 Project_title"> Interest</h6>
                  <h5 className="text-center mt-3">
                    Add your Interest to show your recuriters.
                  </h5>

                  <div className="form_section p-3">
                    <form id="InterestForm" onSubmit={this.submit}>
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group className="row">
                            <div className="col-sm-12">
                              <Form.Control
                                type="text"
                                id="interest"
                                name="interest"
                                onChange={this.handle}
                                placeholder="Interest"
                                className="form-control-sm"
                              />
                            </div>
                          </Form.Group>
                        </div>

                        <div className="col-md-12 mb-1">
                          <button
                            class="post_jp_btn"
                            name="submit"
                            value="Submit"
                            type="submit"
                            style={{ float: "left" }}
                            id="interestSaveButton"
                          >
                            Add Interest <i class="fa fa-spin"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="col-md-12 grid-margin stretch-card"
                style={{ marginTop: "10px" }}
              >
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Interest Details</h4>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>SN.</th>
                            <th>Interest</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.length === 0 ? (
                            <tr>
                              <td colSpan="3" className="text-center">
                                No Records Found
                              </td>
                            </tr>
                          ) : (
                            this.state.data.map((ele, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{ele.interest}</td>
                                  <td>
                                    <button
                                      className="btn btn-danger m-1 badge badge-danger"
                                      onClick={this.Delete}
                                      value={ele.id}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : false}
      </>
    );
  }
}
export default Interest;
