import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './job.css';
import Axios from 'axios';
import Spinner from '../shared/Spinner';

class AppliedJob extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/user/",
            dataStatus: "false",
            data: ''
        };
    }

    componentDidMount() {
        var id = sessionStorage.getItem("id");
        let formData = new FormData();
        formData.append("id", id);
        var url = this.state.url + "appliedJobData.php";
        Axios.post(url, formData)
            .then((res) => {
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    cancel = (e) => {
        e.preventDefault();
        // alert(e.target.id);
        var r = window.confirm("Are you sure, you want to delete?");
        if (r === true) {
            this.setState({
                dataStatus: "false"
            });
            var jobId = e.target.id;
            var userId = sessionStorage.getItem("id");
            let formData = new FormData();
            formData.append("userId", userId);
            formData.append("jobId", jobId);
            var url = this.state.url + "cancelJob.php";
            Axios.post(url, formData)
                .then((res) => {
                    // alert(res.data);
                    if (res.data === 1) {
                        document.getElementById("jobMessage").innerHTML = "Successfully Canceled";
                        document.getElementById("jobAlert").style.display = "block";
                        document.getElementById("jobMessage").classList.add("messageSuccess");
                        setTimeout(function () {
                            document.getElementById("jobAlert").style.display = "none";
                            document.getElementById("jobMessage").classList.remove("messageSuccess");
                        }, 3000);

                        var id = sessionStorage.getItem("id");
                        let formData = new FormData();
                        formData.append("id", id);
                        var url = this.state.url + "appliedJobData.php";
                        Axios.post(url, formData)
                            .then((res) => {
                                this.setState({
                                    data: res.data,
                                    dataStatus: "true"
                                });
                                console.log(res.data);
                            })
                            .catch(function (error) {
                                console.log(error);
                            });

                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }        
    }

    render() {
        return (
            <>
                {sessionStorage.getItem("id") ?
                    <>
                        <div id="jobAlert">
                            <p id="jobMessage"></p>
                        </div>
                        <div className="page-header">
                            <h3 className="page-title">Applied Jobs</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink exact to="/trending-job"><button className="btn btn-sm btn-success">Apply Job</button></NavLink></li>
                                </ol>
                            </nav>
                        </div>
                        {this.state.dataStatus === "false" ? (
                            <Spinner />
                        ) : (
                            <div className="row jobViewSection">
                                {this.state.data.length === 0 ? (
                                    <div className="col-md-12 mt-3">
                                        <p className="text-center">
                                            No jobs found. Currently there is no trending jobs available!
                                        </p>
                                    </div>
                                ) : (
                                    this.state.data.map((ele, i) => {
                                        return (

                                            <div className="col-md-10 mx-auto mt-4">
                                                <div className="card">
                                                    <div className="card-header bg-white">
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <h5 className="pt-2 text-danger"><b>{ele.jobTitle}</b></h5>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <button className="btn btn-sm btn-danger float-right" onClick={this.cancel} id={ele.jobId}>Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <h5><b>Company :- {ele.companyName}</b></h5>
                                                                <label>Location :- {ele.location}</label><br />
                                                                <label>Salary :- {ele.minSalary} - {ele.maxSalary}</label><br />
                                                                <label>Job Posting Date :- {ele.date}</label><br />
                                                                <label>Job Apply Date :- {ele.appliedDate}</label><br />
                                                                <label>Required Experience :- {ele.exp}</label><br />
                                                                <label>Skills :- {ele.skills}</label><br />
                                                                <label>Job Description :- {ele.jobDescription}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        );
                                    })
                                )}
                            </div>
                        )}
                    </> : false
                }
            </>
        )
    }
}
export default AppliedJob;