import React, { Component } from 'react';
import "./card.css";

import CV1 from "./cardImage/resume1.png";
import CV2 from "./cardImage/resume2.png";
import CV3 from "./cardImage/resume3.png";
import CV4 from "./cardImage/resume4.png";
import CV5 from "./cardImage/resume5.png";
import CV6 from "./cardImage/resume6.png";
import CV7 from "./cardImage/resume7.png";
import CV8 from "./cardImage/resume8.png";
import CV9 from "./cardImage/resume9.png";
import CV10 from "./cardImage/resume10.png";
import CV11 from "./cardImage/resume11.png";
import CV12 from "./cardImage/resume12.png";
import CV13 from "./cardImage/resume13.png";
import CV14 from "./cardImage/resume14.png";
import CV15 from "./cardImage/resume15.png";


class Card extends Component {
    setId = (e) =>{
        document.cookie = "id="+sessionStorage.getItem("id");
    }
    render(props) {
        return (
            <div className="col-md-4 mt-3 resume_card">
                <div class="profile">
                    <img src={this.props.imageSrc} alt="Avatar" class="image img-fluid" />
                    <div class="middle">
                        <a target="blank" href={this.props.url}>
                            <div class="text" onClick={this.setId}>
                                Generate Resume
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

class ResumeCard extends Component {
    render() {
        return (
            <div>
                {sessionStorage.getItem("id") ? (
                    <div className="row">
                         <div class="accordion" id="accordionExample">
                            <div className="row">
                                <div class="accordion-item col-md-11 shadow mx-0 px-0 mx-auto">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Download PDF
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse px-3 pb-4 show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="row">
                                            <Card imageSrc={CV1} url="/CV/CV_1" />
                                            <Card imageSrc={CV2} url="/CV/CV_2" />
                                            <Card imageSrc={CV3} url="/CV/CV_3" />
                                            <Card imageSrc={CV4} url="/CV/CV_4" />
                                            <Card imageSrc={CV5} url="/CV/CV_5" />
                                            <Card imageSrc={CV6} url="/CV/CV_6" />
                                            <Card imageSrc={CV7} url="/CV/CV_7" />
                                            <Card imageSrc={CV8} url="/CV/CV_8" />
                                            <Card imageSrc={CV9} url="/CV/CV_9" />
                                            <Card imageSrc={CV10} url="/CV/CV_10" />
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item col-md-11 shadow mx-0 px-0 mt-4 mx-auto">
                                    <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                       Printable CV
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse px-3 pb-4 collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="row">
                                            <Card imageSrc={CV11} url="/CV/CV_11" />
                                            <Card imageSrc={CV12} url="/CV/CV_12" />
                                            <Card imageSrc={CV13} url="/CV/CV_13" />
                                            <Card imageSrc={CV14} url="/CV/CV_14" />
                                            <Card imageSrc={CV15} url="/CV/CV_15" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> ) : (false)
                } 
            </div>       
        )
    }
}
export default ResumeCard;