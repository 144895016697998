import React, { Component } from "react";
import "./conversation.css";
import companyLogo from "../company.jpeg";

import { Link } from "react-router-dom";
import Axios from "axios";

class Conversation extends Component {
  constructor() {
    super();
    this.state = {
      url: "https://www.digitizedresume.com/api/chat/",
      data: [],
      dataStatus: "false",
      msg: "",
      time: 3000,
    };
    this.updateChat();
  }

  updateChat = () => {
    setInterval(() => {
      this.showChat();
    }, this.state.time);
  };

  componentDidMount() {
    if (!sessionStorage.getItem("incoming_uniqueId")) {
      window.location.href = "/chat";
    }
    this.showChat();
  }

  handleChat = (e) => {
    let formData = new FormData();
    formData.append("incID", sessionStorage.getItem("incoming_uniqueId"));
    formData.append("outID", sessionStorage.getItem("outgoing_uniqueId"));
    formData.append("chat_between", "company_user");
    formData.append("msg", this.state.msg);
    var dateFormat = require("dateformat");
    var now = new Date();
    formData.append(
      "timestamp",
      dateFormat(now, "h:MM TT, dddd, mmmm dS, yyyy")
    );

    var url = this.state.url + "chat_insert.php";
    Axios.post(url, formData)
      .then((res) => {
        //console.log(res.data);
        this.showChat();
        this.setState({
          msg: "",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  showChat = () => {
    let formData = new FormData();
    formData.append("incID", sessionStorage.getItem("incoming_uniqueId"));
    formData.append("outID", sessionStorage.getItem("outgoing_uniqueId"));
    var url = this.state.url + "showChat.php";
    Axios.post(url, formData)
      .then((res) => {
        if (res.data != "No Chat Found!") {
          this.setState({
            data: res.data,
            dataStatus: "true",
          });
        }
        this.scrollToBottom();
        //console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // deleteChat = () => {
  //   var con = window.confirm("Do you want to Delete Chat?");
  //   if (con != null) {
  //     let formData = new FormData();
  //     formData.append("incID", sessionStorage.getItem("incoming_uniqueId"));
  //     formData.append("outID", sessionStorage.getItem("outgoing_uniqueId"));
  //     var url = this.state.url + "deleteChat.php";
  //     Axios.post(url, formData)
  //       .then((res) => {
  //         this.setState({
  //           data: [],
  //         });
  //         this.showChat();
  //         console.log(res.data);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   }
  // };

  handle = (e) => {
    //console.log(e.target.value);
    this.setState({
      msg: e.target.value,
    });
  };

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    return (
      <div className="row">
        <div className="col-md-12 chat_section">
          <div className="row">
            {/* profile heading start */}

            <div className="col-md-12 heading-avtar border-bottom">
              <div className="row">
                <div className="col-md-1 col-1 stepback">
                  <Link to="/chat">
                    {" "}
                    <i className="mdi mdi-chevron-left"></i>
                  </Link>
                </div>
                <div className="col-md-1 col-3 text-center">
                  <div className="profile-avatar-icon">
                    <img
                      src={
                        sessionStorage.getItem("inc_logo") != null && sessionStorage.getItem("inc_logo") != "null"
                          ? "https://www.digitizedresume.com/api/company/assets/profile/" +
                            sessionStorage.getItem("inc_logo")
                          : companyLogo
                      }
                      alt="pic"
                    />
                  </div>
                </div>
                <div className="col-md-9 col-8">
                  <div className="row">
                    <div className="col-sm-8 col-8">
                      <span className="name-meta pt-1 text-dark">
                        {sessionStorage.getItem("inc_name")}

                        {sessionStorage.getItem("inc_status") === "Online" ? (
                          <span
                          className="text-success"
                            style={{
                              fontWeight: "400",
                              fontSize: "10px",
                              marginLeft: "8px",
                            }}
                          >
                            Online
                          </span>
                        ) : (
                          <span
                          className="text-dark"
                            style={{
                              fontWeight: "400",
                              fontSize: "10px",
                              marginLeft: "8px",
                            }}
                          >
                            Offline
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div
                  onClick={this.deleteChat}
                  className="col-md-1 col-1 stepback"
                  style={{ cursor: "pointer", fontSize: "22px" }}
                >
                  <i className="mdi mdi-delete"></i>
                </div> */}
              </div>
            </div>

            {/* profile heading end */}

            {/* conversation section */}
            <div className="col-md-12 message" id="conversation">
              {this.state.data.length > 0
                ? this.state.data.map((ele, i) => {
                    if (
                      ele.incoming_uniqueId ==
                      sessionStorage.getItem("uniqueId")
                    ) {
                      return (
                        <div class="row message-body">
                          <div class="col-sm-12 message-main-receiver">
                            <div class="receiver">
                              <div class="message-text">{ele.msg}</div>
                              <span
                                class="message-time pull-right"
                                style={{ float: "right", fontSize: "10px" }}
                              >
                                {ele.timestamp}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div class="row message-body">
                          <div class="col-sm-12 message-main-sender">
                            <div class="sender">
                              <div class="message-text">{ele.msg}</div>
                              <span
                                class="message-time pull-right"
                                style={{ float: "right", fontSize: "10px" }}
                              >
                                {ele.timestamp}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                : ""}
              <div
                style={{ float: "left", clear: "both" }}
                ref={(el) => {
                  this.messagesEnd = el;
                }}
              ></div>
            </div>

            {/* conversation section */}

            <div className="col-md-12 border-top send_msg">
              <div className="row">
                <div className="col-md-0"></div>
                <div className="col-md-11 col-9">
                  <textarea
                    value={this.state.msg}
                    className="form-control"
                    onChange={this.handle}
                    rows="1"
                  ></textarea>
                </div>
                <div
                  onClick={this.handleChat}
                  className="col-md-1 col-3 send_icon"
                  style={{ cursor: "pointer" }}
                >
                  <i className="mdi mdi-send"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Conversation;
