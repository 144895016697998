import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import forgot from "./forgot.jpg";
import "./forgot.css";
import Axios from 'axios';
import swal from 'sweetalert';


export class Forgot extends Component {
    constructor() {
        super();
        this.state = {
            otp: 0,
            url: "https://www.digitizedresume.com/api/user/",
            step: 0,
            email: ''
        }
    }

    change = () => {
        if (document.getElementById("forgot").style.display === "block") {
            document.getElementById("forgot").style.display = "none";
        }
        else {
            document.getElementById("varification").style.display = "block";
            document.getElementById("forgot").style.display = "none";
        }

    }

    sendOTP = (e) => {
        e.preventDefault();
        var email = document.getElementById("email").value;
        if (email !== "") {
            var otp = this.state.otp;
            while (1) {
                var min = 100000;
                var max = 999999;
                otp = Math.floor(Math.random() * (max - min + 1)) + min;
                this.setState({
                    otp: otp,
                })
                break;
            }
            // alert(otp)
            // alert(this.state.otp);
            let formData = new FormData();
            formData.append("OTP", otp);
            formData.append("email", email);
            var url = this.state.url + "forgetPassword.php";
            Axios.post(url, formData).then((res) => {
                if (res.data === 1) {
                    this.setState({
                        step: 1,
                        email: email
                    })
                    swal({
                        title: "Sent!",
                        text: "OTP sent your email address!",
                        button: "Close",
                        icon: "success",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                }
                else {
                    swal({
                        title: "Error!",
                        text: "User doesn't exists!",
                        button: "Close",
                        icon: "error",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                }
            })

        }
        else{
            swal({
                title: "Error!",
                text: "Enter valid email to proceed further!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }

    resetState = (e) => {
        e.preventDefault();
        this.setState({
            step: 0,
            otp: 0,
            email: ''
        })
    }

    verifyOtp = (e) => {
        e.preventDefault();
        console.log(this.state.otp);
        var currentOTP = document.getElementById("otpVerification").value;
        console.log(currentOTP);
        if (currentOTP == this.state.otp) {
            // alert("OTP Verified");
            this.setState({
                step: 2
            })
            swal({
                title: "Verified!",
                text: "OTP verified successfully!",
                button: "Close",
                icon: "success",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
        else {
            swal({
                title: "Invalid OTP!",
                text: "Please enter correct OTP!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }

    changePassword = (e) => {
        e.preventDefault();
        // alert("password changed");
        var password = document.getElementById("newPassword").value;
        if(password !== ""){
            let formData = new FormData();
        formData.append("password", password);
        formData.append("email", this.state.email);
        var url = this.state.url + "changePassword.php";
        Axios.post(url, formData).then((res) => {
            if (res.data === 1) {
                // alert(res.data);
                this.setState({
                    step: 0,
                    email: '',
                    otp: 0
                })
                swal({
                    title: "Password Changed!",
                    text: "Password changed successfully!",
                    button: "Close",
                    icon: "success",
                    timer: 3000,
                    button: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    }
                });
                setTimeout(function () {
                    window.location.href = "/";
                  }, 3000);
            }
        })
        }
        else{
            swal({
                title: "Error!",
                text: "Please enter password to proceed further!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }

    render() {
        return (
            <div className="col-md-10 mt-5 mx-auto">
                <div className="row bg-white">
                    <div className="col-md-6">
                        <img src={forgot} alt="" className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                        {this.state.step === 0 ? (
                            <div className="row forgot mt-2 mb-3" id="forgot">
                                <div className="col-md-10">
                                    <h2>Forgot Password?</h2>
                                    <p>Enter the email address associated with your account</p><br></br>
                                    <input type="email" className="custom-form" id="email" placeholder="Enter Email" />
                                    <button type="submit" className="send_btn" onClick={this.sendOTP}> <i className="mdi mdi-arrow-right"></i> </button>
                                </div>
                            </div>
                        ) : false}

                        {this.state.step === 1 ? (
                            <div className="row varification mt-2 mb-3" id="varification">
                                <div className="col-md-10 mb-4">
                                    <h2> Verification </h2>
                                    <p>Enter the varification code we just send you on your email address</p>
                                    <h6>Verify code</h6>
                                    <div id="otp" class="mt-2">
                                        <input class="m-2 form-control" id="otpVerification" type="number" />
                                    </div>
                                    <Link onClick={this.resetState} exact to="/forget-password" className="resend_OTP">Change Email</Link>
                                    <button type="submit" className="otp_btn mb-4" onClick={this.verifyOtp}>Verify OTP <i className="mdi mdi-arrow-right"></i> </button>

                                </div>
                            </div>
                        ) : false}

                        {this.state.step === 2 ? (
                            <div className="row varification mt-2 mb-3" id="varification">
                                <div className="col-md-10 mb-4">
                                    <h2> OTP Verified! </h2>
                                    <p>To login again into your account, enter new password.</p>
                                    <h6>Enter New Password</h6>
                                    <div id="otp" class="mt-2">
                                        <input class="m-2 form-control" id="newPassword" type="text" />
                                    </div>
                                    <button type="submit" className="otp_btn mb-4" onClick={this.changePassword}>Change Password <i className="mdi mdi-arrow-right"></i> </button>

                                </div>
                            </div>
                        ) : false}

                    </div>
                </div>
            </div>
        )
    }
}