import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({
        [menuState]: false
      });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({
        [menuState]: true
      });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({
          [i]: false
        });
      });
      this.setState({
        [menuState]: true
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({
        [i]: false
      });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({
          [obj.state]: true
        });
      }
    });
  }
  render() {
    return (
     
      <nav className="sidebar sidebar-offcanvas" id="sidebar" >
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center" >
          <Link className="sidebar-brand brand-logo" to="/dashboard" style={{ fontSize: "25px", color: "#fff", textDecoration: "none" }} >
            Dashboard </Link>
          <Link className="sidebar-brand brand-logo-mini pt-3" to="dashboard" >
            <img src={require("../../assets/images/logo-mini.svg")} alt="logo" /> </Link> </div>
        <ul className="nav" >

          <li className={this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"} >
            <NavLink className="nav-link" to="/dashboard" >
              <i className="mdi mdi-television menu-icon" > </i>
              <span className="menu-title" >
                Home
              </span>
            </NavLink >
          </li>

          <li className={
            this.isPathActive("/resume") ? "nav-item active" : "nav-item"
          } >
            <NavLink className="nav-link" to="/resume" >
              <i className="mdi mdi-receipt menu-icon" > </i> <span className="menu-title" >
                Resume </span> </NavLink > </li>

          <li className={
            this.isPathActive("/explore_resume") ? "nav-item active" : "nav-item"
          } >
            <NavLink className="nav-link" to="/explore_resume" >
              <i className="mdi mdi-application menu-icon" > </i> <span className="menu-title" >
                Generate CV </span> </NavLink > </li>

          < li className={
            this.isPathActive("/trending-job") || this.isPathActive("/applied-job") ? "nav-item active" : "nav-item"
          } >
            <NavLink className="nav-link"
              to="/trending-job" >
              <i className="mdi mdi-access-point menu-icon" > </i> <span className="menu-title" >
                Trending Job </span> </NavLink > </li>

          <li className={
            this.isPathActive("/interview") || this.isPathActive("/interview-details") ? "nav-item active" : "nav-item"
          } >
            <NavLink className="nav-link"
              to="/interview" >
              <i className="mdi mdi-account-plus menu-icon" > </i> <span className="menu-title" >
                Interview </span> </NavLink > </li>

          <li className={
            this.isPathActive("/chat") ? "nav-item active" : "nav-item"
          } >
            <NavLink className="nav-link"
              to="/chat" >
              <i className="mdi mdi-wechat menu-icon" > </i> <span className="menu-title" >
                Chat </span> </NavLink > </li>

          <li className={
            this.isPathActive("/shortlist") ? "nav-item active" : "nav-item"
          } >
            <NavLink className="nav-link"
              to="/shortlist" >
              <i className="mdi mdi-sort menu-icon" > </i> <span className="menu-title" >
                Shortlisted </span> </NavLink > </li>

          <li className={
            this.isPathActive("/learning") ? "nav-item active" : "nav-item"
          } >
            <NavLink className="nav-link"
              to="/learning" >
              <i className="mdi mdi-play-circle menu-icon" > </i> <span className="menu-title" >
                Career Guidelines </span> </NavLink > </li>

        </ul> 
      </nav >
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);