import React, { Component } from 'react';
import './resumesec.css';
import Axios from "axios";
import swal from 'sweetalert';

class ResumeSec extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/user/",
            id: "",
            cv: '',
            dataStatus: "false",
            data: [],
        };
    }
    componentDidMount() {
        var id = sessionStorage.getItem("id");

        let formData = new FormData();
        formData.append("id", id);
        var url = this.state.url + "getData.php";
        Axios.post(url, formData)
            .then((res) => {
                this.setState({ cv: res.data[0].cv, });
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    submit = (e) => {
        e.preventDefault();
        document.getElementsByClassName("fa-spin")[9].classList.add("fa-refresh");
        document.getElementById("addCV").disabled = true;
        var id = sessionStorage.getItem("id");
        // alert(id);
        let formData = new FormData();
        formData.append("id", id);
        formData.append("cv", document.getElementById("uploadCV").files[0]);

        var url = this.state.url + "uploadResume.php";

        Axios.post(url, formData)
            .then((res) => {
                this.setState({ data: res.data });
                // alert(res.data);
                if (res.data === 1) {
                    document.getElementById("cvForm").reset();

                    document.getElementsByClassName("fa-spin")[9].classList.remove("fa-refresh");
                    document.getElementById("addCV").disabled = false;
                    swal({
                        title: "Added!",
                        text: "Data added successfully!",
                        button: "Close",
                        icon: "success",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                    var id = sessionStorage.getItem("id");

                    let formData = new FormData();
                    formData.append("id", id);
                    var url = this.state.url + "getData.php";
                    Axios.post(url, formData)
                        .then((res) => {
                            this.setState({ cv: res.data[0].cv, });
                            // console.log(res.data);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
                else {
                    document.getElementsByClassName("fa-spin")[9].classList.remove("fa-refresh");
                    document.getElementById("addCV").disabled = false;
                    swal({
                        title: "Error!",
                        text: "Please delete already uploaded CV!",
                        button: "Close",
                        icon: "error",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                }
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    Delete = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append("id", sessionStorage.getItem("id"));
        var id = sessionStorage.getItem("id");
        var url = this.state.url + "deleteResume.php";

        Axios.post(url, formData)
            .then((res) => {
                swal({
                    title: "Are you sure?",
                    text: "Once removed, you will have to add again!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            let formData = new FormData();
                            formData.append("id", id);
                            var url = this.state.url + "deleteVideoData.php";

                            Axios.post(url, formData)
                                .then((res) => {
                                    var id = sessionStorage.getItem("id");

                                    let formData = new FormData();
                                    formData.append("id", id);
                                    var url = this.state.url + "getData.php";
                                    Axios.post(url, formData)
                                        .then((res) => {
                                            this.setState({ cv: res.data[0].cv, });
                                            // console.log(res.data);
                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                        });
                                    swal({
                                        title: "Deleted!",
                                        text: "Data deleted successfully!",
                                        button: "Close",
                                        icon: "error",
                                        timer: 3000,
                                        button: {
                                            confirm: {
                                                text: "OK",
                                                value: true,
                                                visible: true,
                                                className: "",
                                                closeModal: true
                                            }
                                        }
                                    });
                                })
                                .catch((err) => {
                                    // console.log(err);
                                    this.setState({
                                        res: err,
                                    });
                                });
                        }
                    });
            })
    };

    render() {
        return (
            <div>
                {sessionStorage.getItem("id") ? (
                    <>
                        <div className="row mt-2">
                            <div className="col-md-12 col-12 px-0 mx-0">
                                <div className="resumesec bg-white">
                                    <h6 className="pl-3 resumesec_title"> My Resume</h6>

                                    <div className="upload_section pb-5 text-center">
                                        <form onSubmit={this.submit} id="cvForm">
                                            {/* <i className="mdi mdi-file-chart file" aria-hidden="true"></i>
                                        <a href="">Remove</a> */}

                                            <input required type="file" id="uploadCV" name="uploadCV" className="form-control-file mx-auto pl-3" accept=".pdf" />
                                            <div className="mb-3 mt-3">
                                                <button class="post_jp_btn" name="submit" id="addCV" value="Submit" type="submit">UPLOAD RESUME <i class="fa fa-spin"></i></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            {this.state.cv ? (
                                <div className="col-md-12 mt-2">
                                    <iframe style={{ width: '100%', height: '400px' }} src={"https://www.digitizedresume.com/api/user/assets/cv/" + this.state.cv} title="description"></iframe>

                                    <button className="btn btn-danger mt-2 p-2 badge badge-danger" onClick={this.Delete} >
                                        Delete <i class="fa fa-spin"></i>
                                    </button>
                                </div>
                            ) : (false)}
                        </div>
                    </>
                ) : false}
            </div>
        )
    }
}
export default ResumeSec