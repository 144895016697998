import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import SettingsPanel from "./shared/SettingsPanel";
import Footer from "./shared/Footer";

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

class App extends Component {
  
  state = {};
  constructor(props) {
    super(props);
    var str = (window.location.href).split("/");
    var value = str[str.length - 1];
    if (value !== "login") {
      if (!sessionStorage.getItem("id")) {
        window.location.href = "/login";
      }
    }
  }

  componentDidMount() {
    AOS.init ();
  }
  
  componentDidMount() {
    this.onRouteChanged();
  }
  
  render() {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : "";
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : "";
    let SettingsPanelComponent = !this.state.isFullPageLayout ? (
      <SettingsPanel />
    ) : (
      ""
    );
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";
    return (
      <div className="container-scroller">
        {sessionStorage.getItem("id") ? (
          <>
            {navbarComponent}
          </>
        ) : false}
        <div className="container-fluid page-body-wrapper">
          {sessionStorage.getItem("id") ? (
            <>
              {sidebarComponent}
            </>
          ) : false}
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes />
              {SettingsPanelComponent}
            </div>
            {footerComponent}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const body = document.querySelector("body");
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ["/login", "/dark-theme", "/light-theme","/preview-cv", "/forgot-password"];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.add("full-page-wrapper");
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");
      }
    }
  }
}

export default withRouter(App);
