import React, { Component } from 'react';
import './learning.css';
import ReactPlayer from 'react-player';
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';
import jogpic from "./learn.jpg";
import NotFound from "./not-found.svg";




class Learning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/admin/",
            url2: "https://www.digitizedresume.com/api/user/",
            data: [],
            category: [],
            subCategory: [],
            dataStatus: "false",
            videoDataStatus: "false",
            page: 1,
            totalCol: 0,
            showHide: false,
            currentCategory: '',
            currentSubCategory: '',
        };
    }
    componentDidMount() {
        var categoryURL = this.state.url + "getCategoryData.php";
        Axios.get(categoryURL)
            .then((res) => {
                // console.log(res.data);
                this.setState({
                    category: res.data,
                });
            })
            .catch(function (error) {
                alert(error);
                console.log(error);
            });

        var subCategoryURL = this.state.url + "getSubCategoryData.php";
        Axios.get(subCategoryURL)
            .then((res) => {
                // console.log(res.data);
                // alert(res.data);
                this.setState({
                    subCategory: res.data,
                    dataStatus: "true",
                });
            })
            .catch(function (error) {
                // alert(error);
                console.log(error);
            });
    }
    handleModalShowHide = (e) => {
        e.preventDefault();
        this.setState({
            showHide: !this.state.showHide
        });
    }

    resetFilter = (e) => {
        this.setState({
            dataStatus: 'false'
        })
        var url = this.state.url + "getVideoData.php";
        Axios.get(url)
            .then((res) => {
                // console.log(res.data);
                this.setState({
                    data: res.data,
                    dataStatus: "true",
                });
            })
            .catch(function (error) {
                alert(error);
                console.log(error);
            });
    }

    filterSearchData = (e) => {
        e.preventDefault();
        var categorySearchVideo = document.getElementById("categorySearchVideo").value;
        var titleSearchVideo = document.getElementById("titleSearchVideo").value;

        if (categorySearchVideo !== "" || titleSearchVideo !== "") {
            var id = sessionStorage.getItem("aId");
            this.setState({
                dataStatus: "false",
                showHide: !this.state.showHide
            });

            var SQL = "";
            var query = 0;

            SQL = "SELECT * FROM learning WHERE ";
            if (titleSearchVideo !== "") {
                if (query > 0) {
                    SQL += " AND title LIKE '%" + titleSearchVideo + "%'";
                    query += 1;
                }
                else {
                    SQL += " title LIKE '%" + titleSearchVideo + "%'";
                    query += 1;
                }
            }
            if (categorySearchVideo !== "") {
                if (query > 0) {
                    SQL += " AND category LIKE '%" + categorySearchVideo + "%'";
                    query += 1;
                }
                else {
                    SQL += " category LIKE '%" + categorySearchVideo + "%'";
                    query += 1;
                }
            }

            SQL += ";";

            let formData = new FormData();
            formData.append("sql_Query", SQL);

            console.log(SQL);

            var searchURL = this.state.url + "filterData/filterData.php";

            Axios.post(searchURL, formData).then((res) => {
                // alert(res.data);
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
            })

        }
        else {
            swal({
                title: "Error!",
                text: "Please fill at least any one field to filter!",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
    }


    back = () => {
        this.setState({
            // subCategoryDataStatus: "false",
            currentCategory: "",
        });
        if (document.getElementById("category").style.display === "none") {
            document.getElementById("category").style.display = "block";
            document.getElementById("learning").style.display = "none";
            document.getElementById("topicImage").style.display = "block";
        }
    }

    back1 = () => {
        this.setState({
            videoDataStatus: 'false'
        })
        if (document.getElementById("learning").style.display === "none") {
            document.getElementById("learning").style.display = "block";
            document.getElementById("category").style.display = "none";
            document.getElementById("learningvideo").style.display = "none";
            document.getElementById("topicImage").style.display = "none";
        }
    }

    category = (e) => {
        // alert(e.target.value);
        var currentCategory = e.target.value;
        this.setState({
            currentCategory: currentCategory
        });

        if (document.getElementById("learning").style.display === "none") {
            document.getElementById("learning").style.display = "block";
            document.getElementById("learning").style.transition = "ease";
            document.getElementById("learning").style.transitionDelay = "4s";
            document.getElementById("category").style.display = "none";
            document.getElementById("learningvideo").style.display = "none";
        }
        else {
            document.getElementById("category").style.display = "none";
        }
    }

    learningvideo = (e) => {
        var currentSubCategory = e.target.value;
        // alert(currentSubCategory);
        this.setState({
            videoDataStatus: "false",
            currentSubCategory: currentSubCategory
        });

        var videoUrl = this.state.url2 + "getVideoDataBySubCategory.php";
        let formData = new FormData();
        formData.append("category", this.state.currentCategory);
        formData.append("subCategory", currentSubCategory);
        Axios.post(videoUrl, formData)
            .then((res) => {
                // console.log(res.data);
                this.setState({
                    data: res.data,
                    videoDataStatus: "true",
                });
            })
            .catch(function (error) {
                alert(error);
                console.log(error);
            });

        if (document.getElementById("learningvideo").style.display === "none") {
            document.getElementById("learningvideo").style.display = "block";
            document.getElementById("learningvideo").style.transitionDelay = "4s";
            document.getElementById("category").style.display = "none";
            document.getElementById("topicImage").style.display = "none";

        }
    }



    render() {
        var found = false;
        return (
            <div>
                {sessionStorage.getItem("id") ? (
                    <>
                        <div className="page-header">
                            <h3 className="page-title">Career Guidelines</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Career Guidelines</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Video</li>
                                </ol>
                            </nav>
                        </div>
                        {/* <div className="row my-4">
                            <div className="col-md-8"></div>
                            <div className="col-md-4 text-right sticky">
                                <button className="btn btn-sm btn-info" onClick={this.handleModalShowHide}>Filter <i className="fa fa-filter"></i></button>
                                &nbsp;
                                <button className="btn btn-sm btn-danger" onClick={this.resetFilter}>Rest Filter <i className="fa fa-close"></i></button>
                            </div>
                        </div> */}
                        <Modal show={this.state.showHide}>
                            <Modal.Header>
                                <Modal.Title>Filter Video</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <div className="row">
                                        <div className=" mt-2 col-md-6">
                                            <label className="form-label" for="titleSearchVideo">Enter Video Title</label>
                                            <input type="text" className="form-control" name="titleSearchVideo" id="titleSearchVideo" placeholder="Enter Video Title" ></input>
                                        </div>
                                        <div className="mt-2 col-md-6">
                                            <label className="form-label" for="categorySearchVideo">Select Video Category</label>
                                            <select id="categorySearchVideo" className="form-control" name="categorySearchVideo">
                                                {this.state.category.length === 0 ? (
                                                    <option value="" disabled selected>Add Category First - No Category Found</option>
                                                ) : (
                                                    this.state.category.map((ele, i) => {
                                                        // console.log(i);
                                                        if (i === 0) {
                                                            return (
                                                                <>
                                                                    <option value="" disabled selected>Select Any</option>
                                                                    <option value={ele.category}>{ele.category}</option>
                                                                </>
                                                            );
                                                        }

                                                        return (
                                                            <option value={ele.category}>{ele.category}</option>
                                                        );

                                                    })
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" id="modalSkip" onClick={this.handleModalShowHide}>
                                    Cancel
                                </Button>
                                <Button type="submit" onClick={this.filterSearchData} id="modalValidate" variant="success">
                                    Filter
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        {this.state.dataStatus === "false" ? (<Spinner />) : (
                            <div class="">

                                <div className="row bg-white" id="category" >
                                    <div className="col-md-12 shadow">
                                        <div className="row">
                                            <div className="col-md-4 category">
                                                <h6 className="bg-white p-2 my-2">Category</h6>
                                                <ul>
                                                    {this.state.category.length === 0 ? (
                                                        <h4 className="text-center">
                                                            No Categories Found
                                                        </h4>
                                                    ) : (
                                                        this.state.category.map((ele, i) => {
                                                            return (
                                                                <>
                                                                    <li><button className="m-0 p-0 learningCategoryButton" value={ele.category} onClick={this.category}>{ele.category} <i class="fas fa-chevron-right text-dark"></i> </button></li>
                                                                </>
                                                            )
                                                        })
                                                    )}
                                                </ul>
                                            </div>
                                            <div className="col-md-8 text-center">
                                                <img src={jogpic} className="img-fluid" alt="pic" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row bg-white" id="learning" style={{ display: 'none' }}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4 category">
                                                <div className="d-flex py-3">
                                                    <button className="back_btn" onClick={this.back}><i class="fas fa-chevron-left"></i></button>
                                                    <h6 className="bg-white pl-4 pt-1">Topics</h6>
                                                </div>
                                                <ul>
                                                    {this.state.subCategory.length === 0 ? (
                                                        <img src={NotFound} height="200" style={{margin:'auto', display:'grid',alignSelf: 'center'}} className="img-fluid" alt="pic" />
                                                    ) : (
                                                        this.state.subCategory.map((ele, i) => {
                                                            if (i >= 0) {
                                                                var subCategoryDataStatus = false;
                                                                if (ele.category === this.state.currentCategory) {
                                                                    found = true;
                                                                    subCategoryDataStatus = true;
                                                                    return (
                                                                        <>
                                                                            <li><button className="m-0 p-0 learningCategoryButton" value={ele.subCategory} onClick={this.learningvideo}>{ele.subCategory} <i class="fas fa-chevron-right text-dark"></i> </button></li>
                                                                        </>
                                                                    )
                                                                }
                                                                
                                                            }
                                                        })
                                                    )}
                                                    {
                                                        (found === false) ? <img src={NotFound} height="200" style={{margin:'auto', display:'grid',alignSelf: 'center'}} className="img-fluid" alt="Data Not Found Pic" /> : ''
                                                    }
                                                </ul>

                                            </div>
                                            <div className="col-md-8 text-center">
                                                <img src={jogpic} className="img-fluid" id="topicImage" alt="pic" />
                                                <div class="row learnvideo" id="learningvideo" style={{ display: 'none' }}>
                                                    {this.state.videoDataStatus === "false" ? (<Spinner />) : (
                                                        <div className="col-md-12">
                                                            {/* <button className="back_btn" onClick={this.back1}><i class="fas fa-chevron-left"></i></button> */}
                                                            <div className="row">
                                                                {this.state.data.length === 0 ? (
                                                                    <div style={{height:'300',marginTop:'100px'}}><img src={NotFound} style={{margin:'auto', display:'grid',alignSelf: 'center'}} className="img-fluid" alt="pic" /></div>
                                                                ) : (
                                                                    this.state.data.map((ele, i) => {
                                                                        return (
                                                                            <div class="col-md-12 mt-4">
                                                                                <div class="card" style={{ boxShadow: '0px 0px 8px 6px rgb(0 0 0 / 5%)' }}>
                                                                                    <ReactPlayer
                                                                                        width="100%"
                                                                                        height="180px"
                                                                                        controls
                                                                                        allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
                                                                                        allowfullscreen
                                                                                        url={ele.url}
                                                                                    />
                                                                                    <div class="video_title">
                                                                                        <h5 class="video-title text-left">{ele.title}</h5>
                                                                                        <h6 class="text-left">Category: {ele.category}<span className="text-info">&nbsp;&nbsp; | &nbsp;&nbsp;</span>Published Date: {ele.date}</h6>
                                                                                        <p class="video-text text-left">{ele.description}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* video section */}


                                {/* <div class="row" id="learningvideo" style={{ display: 'none' }}>
                                    {this.state.videoDataStatus === "false" ? (<Spinner />) : (
                                        <div className="col-md-12">
                                            <button className="back_btn" onClick={this.back1}><i class="fas fa-chevron-left"></i></button>
                                            <div className="row">
                                                {this.state.data.length === 0 ? (
                                                    <h4 className="text-center">
                                                        No Videos Found
                                                    </h4>
                                                ) : (
                                                    this.state.data.map((ele, i) => {
                                                        return (
                                                            <div class="col-md-6 mt-3">
                                                                <div class="card">
                                                                    <ReactPlayer
                                                                        width="100%"
                                                                        height="180px"
                                                                        controls
                                                                        allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
                                                                        allowfullscreen
                                                                        url={ele.url}
                                                                    />
                                                                    <div class="video_title">
                                                                        <h5 class="video-title">{ele.title}</h5>
                                                                        <h6 class="">Category: {ele.category}<span className="text-info">&nbsp;&nbsp; | &nbsp;&nbsp;</span>Published Date: {ele.date}</h6>
                                                                        <p class="video-text">{ele.description}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div> */}



                            </div>
                        )}
                    </>
                ) : false}
            </div>
        )
    }
}
export default Learning