import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";
import Chat from './chat/chat';
import Conversation from './chat/conversation/conversation';
import Learning from "./learning/learning";
import Resume from "./resume/resume";
import ResumeCard from "./ResumeCard/ResumeCard";
import ShortList from "./shortlisted/shortlist";
import TrendingJob from './job/Job';
import AppliedJob from './job/AppliedJob';
import Interview from './interview/Interview';
import InterviewDetails from './interview/InterviewDetails';
import { Forgot } from "./user-pages/forgot";
import { ChangePassword } from "./change-password/changepassword";
import { DarkThemeCV } from "./Preview-CV/DarkThemeCV";
import { LightThemeCV } from "./Preview-CV/LightThemeCV";
import { PreviewCV } from "./Preview-CV/PreviewCV";
const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Error404 = lazy(() => import("./error-pages/Error404"));

const Login = lazy(() => import('./user-pages/Login'));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/dashboard" component={Dashboard} />

          <Route path="/resume" component={Resume} />
          <Route path="/explore_resume" component={ResumeCard} />

          <Route exact path="/chat" component={ Chat } />
          <Route exact path="/conversation" component={ Conversation } />

          <Route path="/shortlist" component={ShortList} />
          <Route path="/learning" component={Learning} />

          <Route path="/interview" component={Interview} />
          <Route path="/interview-details" component={InterviewDetails} />

          <Route path="/trending-job" component={TrendingJob} />
          <Route path="/applied-job" component={AppliedJob} />

          <Route path="/login" component={ Login } />
          <Route path="/forgot-password" component={ Forgot } />


          <Route path="/dark-theme" component={ DarkThemeCV } />
          <Route path="/light-theme" component={ LightThemeCV } />
          <Route path="/preview-cv" component={ PreviewCV } />
          
          {/* <Route path="/change-password" component={ ChangePassword } /> */}

          {/* <Route path="/icons/mdi" component={ Mdi } /> */}

          {/* <Route path="/icons/mdi" component={ Mdi } />
          <Route path="/form-Elements/basic-elements" component={ BasicElements } />

          <Route path="/basic-ui/buttons" component={ Buttons } />
          <Route path="/basic-ui/dropdowns" component={ Dropdowns } />

          <Route path="/tables/basic-table" component={ BasicTable } />

          <Route path="/charts/chart-js" component={ ChartJs } />*/}

          {/*<Route path="/user-pages/register-1" component={ Register1 } />
          
          <Route path="/error-pages/error-500" component={ Error500 } /> */}
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
