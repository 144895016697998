import React, { Component, PropTypes } from 'react';
import './workexprience.css';
import { Form } from 'react-bootstrap';
import Axios from 'axios';
import swal from 'sweetalert';

class Exprience extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: "https://www.digitizedresume.com/api/user/",
            totalExperience: '',
            expYear: '',
            expMonth: '',
            currentDesignation: '',
            currentCompany: '',
            startDate: '',
            currentWorkingStatus: false,
            endDate: '',
            annualSalary: '',
            currentJobStatus: '',
            noticePeriod: '',
            workingLocation: '',
            role: '',
            pastDesignation: '',
            pastCompany: '',
            pastStartDate: '',
            pastEndDate: '',
            pastRole: '',
            data: []
        }
    }

    handle = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === "currentWorkingStatus") {
            if (document.getElementById("currentWorkingStatus").checked === true) {
                document.getElementById("endDate").disabled = true;
                this.setState({
                    endDate: "",
                    currentWorkingStatus: true
                });
            }
            if (document.getElementById("currentWorkingStatus").checked === false) {
                document.getElementById("endDate").disabled = false;
                this.setState({
                    currentWorkingStatus: false
                });
            }
        }
    }

    componentDidMount() {
        var id = sessionStorage.getItem("id");
        let formData = new FormData();
        formData.append('id', id);
        // console.log(obj)
        var url = this.state.url + 'getData.php';
        Axios.post(url, formData)
            .then(res => {
                // console.log(res.data);
                this.setState({
                    totalExperience: res.data[0].totalExperience,
                    currentDesignation: res.data[0].currentDesignation,
                    currentCompany: res.data[0].currentCompany,
                    startDate: res.data[0].startDate,
                    endDate: res.data[0].endDate,
                    annualSalary: res.data[0].annualSalary,
                    currentJobStatus: res.data[0].currentJobStatus,
                    noticePeriod: res.data[0].noticePeriod,
                    workingLocation: res.data[0].workingLocation,
                    role: res.data[0].role,
                });
                if (res.data[0].endDate === "" || res.data[0].endDate === "null" || res.data[0].endDate === null) {
                    // document.getElementById("currentWorkingStatus").checked = true;
                    this.setState({currentWorkingStatus : true})
                    document.getElementById("endDate").disabled = true;
                }
                if(res.data[0].totalExperience > 12){
                  var totalExp = res.data[0].totalExperience;
                  var month = totalExp%12;
                  var year = Math.trunc(totalExp/12);
                  this.setState({
                    expYear: year,
                    expMonth: month,
                  })
                }
                else{
                  this.setState({
                    expYear: 0,
                    expMonth: res.data[0].totalExperience,
                  })
                }
            });


        let pastExpFormData = new FormData();
        var id = sessionStorage.getItem("id");
        pastExpFormData.append('id', id);
        // console.log(obj)
        var url = this.state.url + 'getPastWorkExperienceData.php';
        Axios.post(url, pastExpFormData)
            .then(res => {
                // console.log(res.data);
                this.setState({
                    data: res.data
                });
            })
    }

    submit = (e) => {

        e.preventDefault();
        var id = sessionStorage.getItem("id");
        document.getElementsByClassName("fa-spin")[4].classList.add("fa-refresh");
        document.getElementById("workSaveButton").disabled = true;

        var totalExperience = 0;
        if(this.state.expYear !== ""){
          totalExperience = totalExperience + parseInt(this.state.expYear)*12;
        }
        if(this.state.expMonth !== ""){
          totalExperience = totalExperience + parseInt(this.state.expMonth);
        }

        let formData = new FormData();
        formData.append('id', id);
        formData.append('totalExperience', totalExperience);
        formData.append('currentDesignation', this.state.currentDesignation);
        formData.append('currentCompany', this.state.currentCompany);
        formData.append('startDate', this.state.startDate);
        formData.append('endDate', this.state.endDate);
        formData.append('annualSalary', this.state.annualSalary);
        formData.append('currentJobStatus', this.state.currentJobStatus);
        formData.append('noticePeriod', this.state.noticePeriod);
        formData.append('workingLocation', this.state.workingLocation);
        formData.append('role', this.state.role);

        // console.log(obj)
        var url = this.state.url + 'updateWorkExperience.php';
        Axios.post(url, formData)
            .then((res) => {
                // alert(res.data);
                document.getElementsByClassName("fa-spin")[4].classList.remove("fa-refresh");
                document.getElementById("workSaveButton").disabled = false;
                swal({
                    title: "Updated!",
                    text: "Data updated successfully!",
                    button: "Close",
                    icon: "success",
                    timer: 3000,
                    button: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    }
                });
            });
    }

    submitPastExp = (e) => {

        e.preventDefault();
        var id = sessionStorage.getItem("id");
        document.getElementsByClassName("fa-spin")[5].classList.add("fa-refresh");
        document.getElementById("pastWorkSaveButton").disabled = true;

        let formData = new FormData();
        formData.append('id', id);
        formData.append('pastDesignation', this.state.pastDesignation);
        formData.append('pastCompany', this.state.pastCompany);
        formData.append('pastStartDate', this.state.pastStartDate);
        formData.append('pastEndDate', this.state.pastEndDate);
        formData.append('pastRole', this.state.pastRole);

        if (this.state.pastDesignation === "" || this.state.pastCompany === "" || this.state.pastStartDate === "" || this.state.pastEndDate === "" || this.state.pastRole === "") {
            // alert("Please fill all the data before submitting this form");
            document.getElementsByClassName("fa-spin")[5].classList.remove("fa-refresh");
            document.getElementById("pastWorkSaveButton").disabled = false;
            swal({
                title: "Error!",
                text: "Please fill all the data before submitting this form",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
        else {
            var url = this.state.url + 'updatePastWorkExperience.php';
            Axios.post(url, formData)
                .then((res) => {
                    // alert(res.data);
                    document.getElementsByClassName("fa-spin")[5].classList.remove("fa-refresh");
                    document.getElementById("pastWorkSaveButton").disabled = false;
                    swal({
                        title: "Added!",
                        text: "Data added successfully!",
                        button: "Close",
                        icon: "success",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                    this.setState({
                        pastDesignation: '',
                        pastCompany: '',
                        pastStartDate: '',
                        pastEndDate: '',
                        pastRole: '',
                    })
                    document.getElementById("pastExpCompanyForm").reset();
                    var id = sessionStorage.getItem("id");
                    let pastExpFormData = new FormData();
                    pastExpFormData.append('id', id);
                    // console.log(obj)
                    var url = this.state.url + 'getPastWorkExperienceData.php';
                    Axios.post(url, pastExpFormData)
                        .then(res => {
                            // console.log(res.data);
                            this.setState({
                                data: res.data
                            });
                        })
                });
        }

        // console.log(obj)
    }
    Delete = (e) => {
        e.preventDefault();
        // alert(e.target.value);
        var id = e.target.value;

        swal({
            title: "Are you sure?",
            text: "Once removed, you will have to add again!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let formData = new FormData();
                    formData.append('id', id);
                    var url = this.state.url + 'deletePastWorkExperienceData.php';

                    Axios.post(url, formData).then((res) => {
                        swal({
                            title: "Deleted!",
                            text: "Data deleted successfully!",
                            button: "Close",
                            icon: "success",
                            timer: 3000,
                            button: {
                                confirm: {
                                    text: "OK",
                                    value: true,
                                    visible: true,
                                    className: "",
                                    closeModal: true
                                }
                            }
                        });
                        var id = sessionStorage.getItem("id");
                        let formData = new FormData();
                        formData.append('id', id);
                        var url = this.state.url + 'getPastWorkExperienceData.php';
                        Axios.post(url, formData)
                            .then(res => {
                                this.setState({ data: res.data });
                                // console.log(res.data);
                            })
                            .catch(function (error) {
                                console.log(error);
                            })
                    }
                    ).catch((err) => {
                        // console.log(err);
                        this.setState({
                            res: err
                        });
                    }
                    );

                }
            });
    }

    render() {

        return (
            <>
                {sessionStorage.getItem("id") ? (
                    <>
                        <div className="row mt-2">
                            <div className="col-md-12 col-12 px-0 mx-0">
                                <div className="Exprience bg-white">
                                    <h4 className="pl-3 Exprience_title">Work Experience</h4>
                                    <div className="col-md-12 mt-2 mb-2"><h6 className="text-danger">Current Work Experience</h6></div>

                                    <div className="form_section p-3">
                                        <form onSubmit={this.submit}>
                                            <div className="row">

                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="totalExperience">Total Experience in Years / Months:</label>
                                                        {/* {(() => {
                                                            if (this.state.totalExperience === null || this.state.totalExperience === "null" || this.state.totalExperience === "") {
                                                                return (
                                                                    <Form.Control type="number" placeholder="Total Experience" onChange={this.handle} name="totalExperience" id="totalExperience" className="form-control-sm" />
                                                                );
                                                            }
                                                            else {
                                                                return (
                                                                    <Form.Control type="number" value={this.state.totalExperience} placeholder="Total Experience" onChange={this.handle} name="totalExperience" id="totalExperience" className="form-control-sm" />
                                                                );
                                                            }
                                                        })()} */}
                                                         <div className="input-group-workExp">
                                                            <input type="number" onChange={this.handle} name="expYear" value={this.state.expYear} placeholder="Enter Year" />
                                                            <input type="number" onChange={this.handle} name="expMonth" value={this.state.expMonth} placeholder="Enter Month" />
                                                        </div>
                                                    </Form.Group>

                                                </div>

                                                <div className="col-md-6">
                                                    <Form.Group className="row">
                                                        <label className="pl-3">Current Designation:</label>
                                                        <div className="col-sm-12">
                                                            {(() => {
                                                                if (this.state.currentDesignation === null || this.state.currentDesignation === "null" || this.state.currentDesignation === "") {
                                                                    return (
                                                                        <Form.Control type="text" placeholder="Current Designation" onChange={this.handle} name="currentDesignation" id="currentDesignation" className="form-control-sm" />
                                                                    );
                                                                }
                                                                else {
                                                                    return (
                                                                        <Form.Control type="text" value={this.state.currentDesignation} placeholder="Current Designation" onChange={this.handle} name="currentDesignation" id="currentDesignation" className="form-control-sm" />
                                                                    );
                                                                }
                                                            })()}
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-12">
                                                    <Form.Group className="row">
                                                        <label className="pl-3">Current Company:</label>
                                                        <div className="col-sm-12">
                                                            {(() => {
                                                                if (this.state.currentCompany === null || this.state.currentCompany === "null" || this.state.currentCompany === "") {
                                                                    return (
                                                                        <Form.Control type="text" placeholder="Current Company" onChange={this.handle} name="currentCompany" id="currentCompany" className="form-control-sm" />
                                                                    );
                                                                }
                                                                else {
                                                                    return (
                                                                        <Form.Control type="text" value={this.state.currentCompany} placeholder="Current Company" onChange={this.handle} name="currentCompany" id="currentCompany" className="form-control-sm" />
                                                                    );
                                                                }
                                                            })()}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="row">
                                                        <label className="pl-3">Start Date:</label>
                                                        <div className="col-sm-12">
                                                            {(() => {
                                                                if (this.state.startDate === null || this.state.startDate === "null" || this.state.startDate === "") {
                                                                    return (
                                                                        <Form.Control type="date" onChange={this.handle} name="startDate" id="startDate" className="form-control-sm" />
                                                                    );
                                                                }
                                                                else {
                                                                    return (
                                                                        <Form.Control type="date" value={this.state.startDate} onChange={this.handle} name="startDate" id="startDate" className="form-control-sm" />
                                                                    );
                                                                }
                                                            })()}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="row">
                                                        <label className="pl-3">Currently Working<br /><input checked={this.state.currentWorkingStatus} type="checkbox" onChange={this.handle} style={{ height: '25px', width: '30px' }} name="currentWorkingStatus" id="currentWorkingStatus" className="form-control-sm mt-2" /></label>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group className="row">
                                                        <label className="pl-3">End Date:</label>
                                                        <div className="col-sm-12">
                                                            {(() => {
                                                                if (this.state.currentWorkingStatus === false) {
                                                                    if (this.state.endDate === null || this.state.endDate === "null" || this.state.endDate === "") {
                                                                        return (
                                                                            <Form.Control type="date" onChange={this.handle} name="endDate" id="endDate" className="form-control-sm" />
                                                                        );
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <Form.Control type="date" value={this.state.endDate} onChange={this.handle} name="endDate" id="endDate" className="form-control-sm" />
                                                                        );
                                                                    }
                                                                }
                                                                else {
                                                                    return (
                                                                        <Form.Control type="date" value={this.state.endDate} disabled name="endDate" id="endDate" className="form-control-sm" />
                                                                    );
                                                                }
                                                            })()}
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-6">
                                                    <Form.Group className="row">
                                                        <label className="pl-3">Annual Salary:</label>
                                                        <div className="col-sm-12">
                                                            {(() => {
                                                                if (this.state.annualSalary === null || this.state.annualSalary === "null" || this.state.annualSalary === "") {
                                                                    return (
                                                                        <Form.Control type="number" required placeholder="Annual Salary" onChange={this.handle} name="annualSalary" id="annualSalary" className="form-control-sm" value={this.state.annualSalary} />
                                                                    );
                                                                }
                                                                else {
                                                                    return (
                                                                        <Form.Control type="number" required value={this.state.annualSalary} placeholder="Annual Salary" onChange={this.handle} name="annualSalary" id="annualSalary" className="form-control-sm" />
                                                                    );
                                                                }
                                                            })()}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="currentJobStatus">Current Job Status:</label>
                                                        {(() => {
                                                            if (this.state.currentJobStatus === null || this.state.currentJobStatus === "null" || this.state.currentJobStatus === "") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} name="currentJobStatus" id="currentJobStatus" >
                                                                        <option value="" selected>Select Any</option>
                                                                        <option value="Full Time">Full Time</option>
                                                                        <option value="Part Time">Part Time</option>
                                                                        <option value="Not Available">Not Available</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.currentJobStatus === "Full Time") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} name="currentJobStatus" id="currentJobStatus" >
                                                                        <option value="">Select Any</option>
                                                                        <option value="Full Time" selected>Full Time</option>
                                                                        <option value="Part Time">Part Time</option>
                                                                        <option value="Not Available">Not Available</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.currentJobStatus === "Part Time") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} name="currentJobStatus" id="currentJobStatus" >
                                                                        <option value="">Select Any</option>
                                                                        <option value="Full Time">Full Time</option>
                                                                        <option value="Part Time" selected>Part Time</option>
                                                                        <option value="Not Available">Not Available</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.currentJobStatus === "Not Available") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} name="currentJobStatus" id="currentJobStatus" >
                                                                        <option value="">Select Any</option>
                                                                        <option value="Full Time">Full Time</option>
                                                                        <option value="Part Time">Part Time</option>
                                                                        <option value="Not Available" selected>Not Available</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="noticePeriod">Notice Period:</label>
                                                        {(() => {
                                                            if (this.state.noticePeriod === null || this.state.noticePeriod === "null" || this.state.noticePeriod === "") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} name="noticePeriod" id="noticePeriod">
                                                                        <option value="" selected>Select Any</option>
                                                                        <option value="Yes">Yes</option>
                                                                        <option value="No">No</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.noticePeriod === "Yes") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} name="noticePeriod" id="noticePeriod">
                                                                        <option value="">Select Any</option>
                                                                        <option value="Yes" selected>Yes</option>
                                                                        <option value="No">No</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.noticePeriod === "No") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} name="noticePeriod" id="noticePeriod">
                                                                        <option value="">Select Any</option>
                                                                        <option value="Yes">Yes</option>
                                                                        <option value="No" selected>No</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="workingLocation">Current Working Location:</label>
                                                        {(() => {
                                                            if (this.state.workingLocation === null || this.state.workingLocation === "null" || this.state.workingLocation === "") {
                                                                return (
                                                                    <Form.Control type="text" placeholder="Current Working Location" onChange={this.handle} name="workingLocation" id="workingLocation" className="form-control-sm" />
                                                                );
                                                            }
                                                            else {
                                                                return (
                                                                    <Form.Control type="text" value={this.state.workingLocation} placeholder="Current Working Location" onChange={this.handle} name="workingLocation" id="workingLocation" className="form-control-sm" />
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form.Group>
                                                        <label htmlFor="role">Role and Responsibility:*</label>
                                                        {(() => {
                                                            if (this.state.role === null || this.state.role === "null" || this.state.role === "") {
                                                                return (
                                                                    <textarea className="form-control form-control-sm" placeholder="Enter Role And Responsibility" style={{ height: '100px' }} onChange={this.handle} name="role" id="role"></textarea>
                                                                );
                                                            }
                                                            else {
                                                                return (
                                                                    <textarea className="form-control form-control-sm" value={this.state.role} placeholder="Enter Role And Responsibility" style={{ height: '100px' }} onChange={this.handle} name="role" id="role"></textarea>
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <button class="post_jp_btn save_change" id="workSaveButton" name="submit" value="Submit" type="submit" style={{ marginLeft: '20px' }}>SAVE CHANGES <i class="fa fa-spin"></i></button>

                                            </div>
                                        </form>

                                        <form onSubmit={this.submitPastExp} id="pastExpCompanyForm">
                                            <div className="row mt-5">

                                                <div className="col-md-12" id="add_exp">
                                                    <div className="row p-2">
                                                        <div className="col-md-12 mt-1 mb-2"><h6 className="text-danger">Add Past Experiences</h6></div>
                                                        <div className="col-md-6">
                                                            <Form.Group className="row">
                                                                <label className="pl-3">Designation:</label>
                                                                <div className="col-sm-12">
                                                                    <Form.Control type="text" placeholder="Designation" onChange={this.handle} name="pastDesignation" id="pastDesignation" className="form-control-sm" />

                                                                </div>
                                                            </Form.Group>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <Form.Group className="row">
                                                                <label className="pl-3">Company:</label>
                                                                <div className="col-sm-12">
                                                                    <Form.Control type="text" placeholder="Past Company" onChange={this.handle} name="pastCompany" id="pastCompany" className="form-control-sm" />

                                                                </div>
                                                            </Form.Group>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <Form.Group className="row">
                                                                <label className="pl-3">Start Date:</label>
                                                                <div className="col-sm-12">
                                                                    <Form.Control type="date" onChange={this.handle} name="pastStartDate" id="pastStartDate" className="form-control-sm" />

                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Form.Group className="row">
                                                                <label className="pl-3">End Date:</label>
                                                                <div className="col-sm-12">
                                                                    <Form.Control type="date" onChange={this.handle} name="pastEndDate" id="pastEndDate" className="form-control-sm" />

                                                                </div>
                                                            </Form.Group>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <Form.Group>
                                                                <label htmlFor="pastRole">Role and Responsibility:*</label>
                                                                <textarea className="form-control form-control-sm" placeholder="Enter Role And Responsibility" style={{ height: '100px' }} onChange={this.handle} name="pastRole" id="pastRole"></textarea>

                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-12 mb-3">
                                                            <button class="post_jp_btn" name="submit" id="pastWorkSaveButton" value="Submit" type="submit">ADD PAST EXPERIENCE <i class="fa fa-spin"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="row mt-5">
                                            <div className="col-md-12 grid-margin stretch-card" style={{ marginTop: '10px' }}>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="card-title">Work Experience Details</h4>
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>SN.</th>
                                                                        <th>Company Name</th>
                                                                        <th>Designation</th>
                                                                        <th>Start Date</th>
                                                                        <th>End Date</th>
                                                                        <th>Delete</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {this.state.data.length === 0 ? (
                                                                        <tr>
                                                                            <td colSpan="6" className="text-center">
                                                                                No Records Found
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        this.state.data.map((ele, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{i + 1}</td>
                                                                                    <td>{ele.pastCompany}</td>
                                                                                    <td>{ele.pastDesignation}</td>
                                                                                    <td>{ele.pastStartDate}</td>
                                                                                    <td>{ele.pastEndDate}</td>
                                                                                    <td><button className="btn btn-danger m-1 badge badge-danger" onClick={this.Delete} value={ele.id}>Delete</button></td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    )}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </>
                ) : false}
            </>
        )
    }
}
export default Exprience
