import React from "react";
import Axios from 'axios';
import Spinner from '../shared/Spinner';
import ReactPlayer from 'react-player';
import OwlCarousel from 'react-owl-carousel';

const options = {
    margin: 15,
    nav: true,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        700: {
            items: 1
        },
        1000: {
            items: 1
        }
    }
}

class Progress extends React.Component {
    render(props) {
        return (
            <span role="progressbar" aria-valuenow={this.props.rating} aria-valuemin="0" aria-valuemax="100" style={{ width: this.props.rating + '%', zIndex: '2' }}></span>
        )
    }
}

export class DarkThemeCV extends React.Component {

    constructor(props) {
        super(props);
        console.log(props);
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#121212";
        document.getElementsByClassName("content-wrapper")[0].style.backgroundColor = "#121212";
    }

    about = () => {
        if (document.getElementById("aboutpage").style.display === "none") {
            document.getElementById("aboutpage").style.display = "block";
            document.getElementById("resumepage").style.display = "none";
            document.getElementById("portfoliopage").style.display = "none";
            document.getElementById("contactpage").style.display = "none";
            document.getElementById("videopage").style.display = "none";
            document.getElementById("certificatepage").style.display = "none";
            document.getElementById("projectpage").style.display = "none";


            document.getElementById("aboutMenu").style.color = "#d9ba5f";
            document.getElementById("resume").style.color = "#d0cfc0";
            document.getElementById("portfolio").style.color = "#d0cfc0";
            document.getElementById("contactMenu").style.color = "#d0cfc0";
            document.getElementById("certificateMenu").style.color = "#d0cfc0";
            document.getElementById("videoMenu").style.color = "#d0cfc0";
            document.getElementById("ProjectMenu").style.color = "#d0cfc0";

        }
    }
    resume = () => {
        if (document.getElementById("resumepage").style.display === "none") {
            document.getElementById("resumepage").style.display = "block";
            document.getElementById("aboutpage").style.display = "none";
            document.getElementById("portfoliopage").style.display = "none";
            document.getElementById("contactpage").style.display = "none";
            document.getElementById("videopage").style.display = "none";
            document.getElementById("certificatepage").style.display = "none";
            document.getElementById("projectpage").style.display = "none";


            document.getElementById("resume").style.color = "#d9ba5f";
            document.getElementById("aboutMenu").style.color = "#d0cfc0";
            document.getElementById("portfolio").style.color = "#d0cfc0";
            document.getElementById("contactMenu").style.color = "#d0cfc0";
            document.getElementById("certificateMenu").style.color = "#d0cfc0";
            document.getElementById("videoMenu").style.color = "#d0cfc0";
            document.getElementById("ProjectMenu").style.color = "#d0cfc0";

        }
    }
    portfolio = () => {

        if (document.getElementById("portfoliopage").style.display === "none") {
            document.getElementById("portfoliopage").style.display = "block";
            document.getElementById("aboutpage").style.display = "none";
            document.getElementById("resumepage").style.display = "none";
            document.getElementById("contactpage").style.display = "none";
            document.getElementById("videopage").style.display = "none";
            document.getElementById("certificatepage").style.display = "none";
            document.getElementById("projectpage").style.display = "none";


            document.getElementById("portfolio").style.color = "#d9ba5f";
            document.getElementById("aboutMenu").style.color = "#d0cfc0";
            document.getElementById("resume").style.color = "#d0cfc0";
            document.getElementById("contactMenu").style.color = "#d0cfc0";
            document.getElementById("certificateMenu").style.color = "#d0cfc0";
            document.getElementById("videoMenu").style.color = "#d0cfc0";
            document.getElementById("ProjectMenu").style.color = "#d0cfc0";

        }



    }
    contact = () => {
        if (document.getElementById("contactpage").style.display === "none") {
            document.getElementById("contactpage").style.display = "block";
            document.getElementById("resumepage").style.display = "none";
            document.getElementById("portfoliopage").style.display = "none";
            document.getElementById("aboutpage").style.display = "none";
            document.getElementById("videopage").style.display = "none";
            document.getElementById("certificatepage").style.display = "none";
            document.getElementById("projectpage").style.display = "none";

            document.getElementById("contactMenu").style.color = "#d9ba5f";
            document.getElementById("aboutMenu").style.color = "#d0cfc0";
            document.getElementById("resume").style.color = "#d0cfc0";
            document.getElementById("portfolio").style.color = "#d0cfc0";
            document.getElementById("certificateMenu").style.color = "#d0cfc0";
            document.getElementById("videoMenu").style.color = "#d0cfc0";
            document.getElementById("ProjectMenu").style.color = "#d0cfc0";

        }
    }
    video = () => {
        if (document.getElementById("videopage").style.display === "none") {
            document.getElementById("videopage").style.display = "block";
            document.getElementById("resumepage").style.display = "none";
            document.getElementById("portfoliopage").style.display = "none";
            document.getElementById("aboutpage").style.display = "none";
            document.getElementById("contactpage").style.display = "none";
            document.getElementById("certificatepage").style.display = "none";
            document.getElementById("projectpage").style.display = "none";

            document.getElementById("videoMenu").style.color = "#d9ba5f";
            document.getElementById("contactMenu").style.color = "#d0cfc0";
            document.getElementById("aboutMenu").style.color = "#d0cfc0";
            document.getElementById("resume").style.color = "#d0cfc0";
            document.getElementById("portfolio").style.color = "#d0cfc0";
            document.getElementById("ProjectMenu").style.color = "#d0cfc0";
            document.getElementById("certificateMenu").style.color = "#d0cfc0";

        }
    }

    project = () => {
        if (document.getElementById("projectpage").style.display === "none") {
            document.getElementById("projectpage").style.display = "block";
            document.getElementById("resumepage").style.display = "none";
            document.getElementById("portfoliopage").style.display = "none";
            document.getElementById("aboutpage").style.display = "none";
            document.getElementById("contactpage").style.display = "none";
            document.getElementById("videopage").style.display = "none";
            document.getElementById("certificatepage").style.display = "none";

            document.getElementById("ProjectMenu").style.color = "#d9ba5f";
            document.getElementById("contactMenu").style.color = "#d0cfc0";
            document.getElementById("aboutMenu").style.color = "#d0cfc0";
            document.getElementById("resume").style.color = "#d0cfc0";
            document.getElementById("portfolio").style.color = "#d0cfc0";
            document.getElementById("videoMenu").style.color = "#d0cfc0";
            document.getElementById("certificateMenu").style.color = "#d0cfc0";

        }
    }

    certificate = () => {
        if (document.getElementById("certificatepage").style.display === "none") {
            document.getElementById("certificatepage").style.display = "block";
            document.getElementById("resumepage").style.display = "none";
            document.getElementById("portfoliopage").style.display = "none";
            document.getElementById("aboutpage").style.display = "none";
            document.getElementById("contactpage").style.display = "none";
            document.getElementById("videopage").style.display = "none";
            document.getElementById("projectpage").style.display = "none";

            document.getElementById("certificateMenu").style.color = "#d9ba5f";
            document.getElementById("contactMenu").style.color = "#d0cfc0";
            document.getElementById("aboutMenu").style.color = "#d0cfc0";
            document.getElementById("resume").style.color = "#d0cfc0";
            document.getElementById("portfolio").style.color = "#d0cfc0";
            document.getElementById("ProjectMenu").style.color = "#d0cfc0";
            document.getElementById("videoMenu").style.color = "#d0cfc0";

        }
    }

    showContact = (e) => {
        e.preventDefault();
        var section = document.getElementsByClassName("sidebar__additional-info")[0];
        if (section.style.display === "block") {
            section.style.display = "none";
        }
        else {
            section.style.display = "block";
        }
    }

    contactFormSubmit = (e) => {
        e.preventDefault();
        var name = document.getElementById("nameContact").value;
        var email = document.getElementById("emailContact").value;
        var subject = document.getElementById("subjectContact").value;
        var message = document.getElementById("messageContact").value;

        let formData = new FormData();
        var info = this.props.dataState.url + "CVContactForm.php";
        var id = this.props.dataState.id;
        var userName = this.props.dataState.domain;
        formData.append('id', id);
        formData.append('userName', userName);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('subject', subject);
        formData.append('message', message);
        Axios.post(info, formData)
            .then((res) => {
                // alert(res.data);
                document.getElementById("contact-form").reset();
                document.getElementById("contactMessage").style.display = "block";
                setTimeout(function () {
                    document.getElementById("contactMessage").style.display = "none";
                }, 3000);
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    render() {
        return (
            <div className="dark_theme" id="dark_theme">
                <div id="contactMessage">
                    <p id="alertMessage">Successfully Submitted!</p>
                </div>
                <main className="main">
                    <div className="container gutter-top gutter-bottom">
                        <div className="row sticky-parent">
                            {/* <!-- Sidebar --> */}
                            <aside className="col-12 col-md-12 col-xl-3">
                                <div className="sidebar box-outer sticky-column">
                                    {/* <!-- My photo --> */}
                                    <div className="sidebar__base-info">
                                        <figure className="avatar-box">
                                            {this.props.dataState.profileImage ? (
                                                <img src={"https://www.digitizedresume.com/api/user/assets/profile/" + this.props.dataState.profileImage} alt={this.props.dataState.name} />
                                            ) : (
                                                <img src="assets/images/my-avatar.png" alt="Ricardo Black" />
                                            )}
                                        </figure>

                                        <div className="text-xl-center">
                                            <h3 className="title title--h3 sidebar__name">{this.props.dataState.name}</h3>
                                            <div className="badge">{this.props.dataState.designation}</div>
                                            {this.props.dataState.cv ? (<a target="_blank" id="downloadCV" href={"https://www.digitizedresume.com/api/user/assets/cv/" + this.props.dataState.cv} ><div id="cv-box"> Download CV </div></a>) : false}
                                        </div>

                                        <button className="btn btn--small btn--icon-right sidebar__btn js-btn-toggle" onClick={this.showContact}><span>Show Contacts</span><i className="feathericon-chevron-down"></i></button>
                                    </div>

                                    <div className="sidebar__additional-info js-show">
                                        <div className="separation"></div>
                                        <ul className="details-info">
                                            {/* <!-- Email --> */}
                                            <li className="details-info__item">
                                                <span className="box icon-box"><i className="font-icon icon-envelope"></i></span>
                                                <div className="contacts-block__info">
                                                    <span className="overhead">Email</span>
                                                    <a className="text-overflow" href={"mailto:" + this.props.dataState.email} title={this.props.dataState.email}>{this.props.dataState.email}</a>
                                                </div>
                                            </li>
                                            {/* <!-- Phone --> */}
                                            <li className="details-info__item">
                                                <span className="box icon-box"><i className="font-icon icon-phone"></i></span>
                                                <div className="contacts-block__info">
                                                    <span className="overhead">Phone</span>
                                                    <span className="text-overflow" title={this.props.dataState.phone}>{this.props.dataState.phone}</span>
                                                </div>
                                            </li>
                                            {/* <!-- Birthday --> */}
                                            <li className="details-info__item">
                                                <span className="box icon-box"><i className="font-icon icon-calendar"></i></span>
                                                <div className="contacts-block__info">
                                                    <span className="overhead">Birthday</span>
                                                    <span className="text-overflow" title={this.props.dataState.dob}>{this.props.dataState.dob}</span>
                                                </div>
                                            </li>
                                            {/* <!-- Location --> */}
                                            <li className="details-info__item">
                                                <span className="box icon-box"><i className="font-icon icon-location"></i></span>
                                                <div className="contacts-block__info">
                                                    <span className="overhead">Location</span>
                                                    <span className="text-overflow" title={this.props.dataState.address}>{this.props.dataState.address}</span>
                                                </div>
                                            </li>
                                        </ul>
                                        {/* <div className="separation d-xl-none"></div> */}

                                        {/* <!-- Social --> */}
                                        {/* <div className="social">
                                                <a className="social__link" href="https://www.facebook.com/"><i className="feathericon-facebook"></i></a>
                                                <a className="social__link" href="https://www.behance.com/"><i className="feathericon-twitter"></i></a>
                                                <a className="social__link" href="https://www.linkedin.com/"><i className="feathericon-instagram"></i></a>
                                            </div> */}
                                    </div>
                                </div>
                            </aside>

                            {/* <!-- Content --> */}
                            <div className="col-12 col-md-12 col-xl-9">
                                <div className="box-outer">
                                    {/* <!-- Menu --> */}
                                    <div className="nav-container">
                                        <ul className="nav">
                                            <li className="nav__item" onClick={this.about}><a href="javascript:void(0)" id="aboutMenu" style={{ color: 'rgb(217, 186, 95)' }}>About</a></li>
                                            <li className="nav__item" onClick={this.resume}><a href="javascript:void(0)" id="resume">Resume</a></li>
                                            <li className="nav__item" onClick={this.portfolio}><a href="javascript:void(0)" id="portfolio">Portfolio</a></li>
                                            <li className="nav__item" onClick={this.video}><a href="javascript:void(0)" id="videoMenu">Video</a></li>
                                            <li className="nav__item" onClick={this.certificate}><a href="javascript:void(0)" id="certificateMenu">Certificate</a></li>
                                            <li className="nav__item" onClick={this.project}><a href="javascript:void(0)" id="ProjectMenu">Project</a></li>
                                            <li className="nav__item" onClick={this.contact}><a href="javascript:void(0)" id="contactMenu">Contact</a></li>
                                        </ul>
                                    </div>





                                    <div id="aboutpage">
                                        {/* <!-- About --> */}
                                        <div className="pb-0 pb-sm-2">
                                            <h1 className="title title--h1 title__separate">About Me</h1>
                                            <p>{this.props.dataState.description !== "null" && this.props.dataState.description !== null ? (this.props.dataState.description) : (false)}</p>
                                        </div>

                                        {/* <!-- What --> */}

                                        {this.props.dataState.workInfo !== "null" && this.props.dataState.workInfo !== null ? (
                                            <>
                                                <h2 className="title title--h2 mt-3">What I'm Doing</h2>
                                                <div className="row">
                                                    {/* <!-- Case Item --> */}
                                                    <p>{this.props.dataState.workInfo}</p>
                                                </div>
                                            </>
                                        ) : (false)}

                                        {/* <!-- skills --> */}
                                        <div className="testimonials">
                                            <h2 className="title title--h2 mt-3">My Skills</h2>
                                            <div className="box box-inner mb-0">
                                                {/* <!-- Web Design --> */}
                                                {this.props.dataState.skills.length === 0 ? (
                                                    false
                                                ) : (
                                                    this.props.dataState.skills.map((ele, i) => {
                                                        return (
                                                            <div className="progress">
                                                                <div className="progress-text text-capitalize"><span>{ele.name}</span> {ele.rating + "%"}</div>
                                                                <div className="progress-bar"><Progress rating={ele.rating} /></div>
                                                            </div>
                                                        );
                                                    })
                                                )}

                                            </div>
                                        </div>

                                        {/* <!-- Testimonials --> */}
                                        <div class="testimonials">
                                            {this.props.dataState.internship.length > 0 ? (
                                                <h2 class="title title--h2 mt-3">Testimonial</h2>) : false}

                                            <div class="">
                                                <div class="">
                                                    <OwlCarousel className="owl-theme" loop nav {...options}>

                                                        {this.props.dataState.testimonial.length === 0 ? (
                                                            false
                                                        ) : (
                                                            this.props.dataState.testimonial.map((ele, i) => {
                                                                return (
                                                                    <div class="review-item box box-inner" data-mfp-src="#review-1" data-effect="mfp-zoom-out">
                                                                        {/* <figure class="box box-avatar">
                                                                    <img src="assets/images/avatar-1.png" alt="Daniel Lewis" />
                                                                </figure> */}
                                                                        <h5 class="">{ele.name}</h5>
                                                                        <p class="review-item__caption">{ele.description}</p>
                                                                    </div>
                                                                );

                                                            })
                                                        )}


                                                    </OwlCarousel>

                                                </div>


                                                <div class="swiper-pagination"></div>
                                            </div>
                                        </div>

                                        {/* about page start */}
                                        <div class="testimonials">

                                            {this.props.dataState.internship.length > 0 ? (
                                                <h2 class="title title--h2 mt-3">Internship</h2>) : false}

                                            <div class="">
                                                <div class="">
                                                    <OwlCarousel className="owl-theme" loop nav {...options}>



                                                        {this.props.dataState.internship.length === 0 ? (
                                                            false
                                                        ) : (
                                                            this.props.dataState.internship.map((ele, i) => {
                                                                return (
                                                                    <div class="col-12 col-lg-12">
                                                                        <div class="case-item box box-inner">
                                                                            <div>
                                                                                <h3 class="title title--h3">{ele.companyName}</h3>
                                                                                <h4 class="sub-title" style={{ fontSize: '14px' }}>{"Designation: " + ele.department}</h4>
                                                                                <p class="case-item__caption">{ele.description}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );

                                                            })
                                                        )}


                                                    </OwlCarousel>

                                                </div>


                                                <div class="swiper-pagination"></div>
                                            </div>
                                        </div>
                                        {/* about page end */}


                                    </div>




                                    <div id="resumepage" style={{ display: 'none' }}>
                                        {/* <!-- Resume section --> */}
                                        <div className="pb-3">
                                            <h1 className="title title--h1 title__separate">Resume</h1>
                                        </div>
                                        <h2 className="title title--h2"><span className="box icon-box"><i className="font-icon feathericon-book-open"></i></span>Education</h2>

                                        <div className="timeline">
                                            {this.props.dataState.highestQualification !== null && this.props.dataState.highestQualification !== "null" ? (
                                                <article className="timeline__item">
                                                    <h5 className="title title--h4 timeline__title">{this.props.dataState.highestEducation}</h5>
                                                    <h5 className="title title--h4 mt-2 timeline__title">{"Qualification — " + this.props.dataState.highestQualification}</h5>
                                                    <span className="timeline__period">{this.props.dataState.universityCollege1}</span>
                                                    <p className="timeline__description">Passing Year — {this.props.dataState.passingYear1}<br />
                                                        Course Type — {this.props.dataState.courseType1}<br />
                                                        Specialization — {this.props.dataState.specialization1}</p>
                                                </article>
                                            ) : false}
                                            {this.props.dataState.qualification2 !== null && this.props.dataState.qualification2 !== "null" ? (
                                                <article className="timeline__item">
                                                    <h5 className="title title--h4 timeline__title">{this.props.dataState.course2}</h5>
                                                    <h5 className="title title--h4 mt-2 timeline__title">{"Qualification — " + this.props.dataState.qualification2}</h5>
                                                    <span className="timeline__period">{this.props.dataState.universityCollege2}</span>
                                                    <p className="timeline__description">Passing Year — {this.props.dataState.passingYear2}<br />
                                                        Course Type — {this.props.dataState.courseType2}<br />
                                                        Specialization — {this.props.dataState.specialization2}</p>
                                                </article>
                                            ) : false}
                                            {this.props.dataState.qualification3 !== null && this.props.dataState.qualification3 !== "null" ? (
                                                <article className="timeline__item">
                                                    <h5 className="title title--h4 timeline__title">{this.props.dataState.qualification3}</h5>
                                                    <span className="timeline__period">{this.props.dataState.universityCollege3}</span>
                                                    <p className="timeline__description">Passing Year — {this.props.dataState.passingYear3}<br />
                                                        Course Type — {this.props.dataState.courseType3}</p>
                                                </article>
                                            ) : false}
                                        </div>



                                        <h2 className="title title--h2"><span className="box icon-box"><i className="font-icon feathericon-briefcase"></i></span>Experience</h2>
                                        <div className="timeline">
                                            {/* <!-- Item --> */}
                                            {this.props.dataState.currentCompany !== null && this.props.dataState.currentCompany !== "null" ? (
                                                <article className="timeline__item">
                                                    <h5 className="title title--h4 timeline__title">{this.props.dataState.currentDesignation} — {this.props.dataState.currentCompany}</h5>
                                                    {this.props.dataState.endDate ? (
                                                        <span className="timeline__period">{this.props.dataState.startDate} — {this.props.dataState.endDate}</span>
                                                    ) : (
                                                        <span className="timeline__period">{this.props.dataState.startDate} — Present</span>
                                                    )}
                                                    {this.props.dataState.role !== "null" && this.props.dataState.role !== null ? (
                                                        <p className="timeline__description">
                                                            {this.props.dataState.role}
                                                        </p>
                                                    ) : false}
                                                </article>
                                            ) : false}

                                            {this.props.dataState.pastWorkExp.length === 0 ? (
                                                false
                                            ) : (
                                                this.props.dataState.pastWorkExp.map((ele, i) => {
                                                    return (
                                                        <article className="timeline__item">
                                                            <h5 className="title title--h4 timeline__title">{ele.pastDesignation} — {ele.pastCompany}</h5>
                                                            <span className="timeline__period">{ele.pastStartDate} — {ele.pastEndDate}</span>
                                                            {ele.pastRole !== "null" && ele.pastRole !== null ? (
                                                                <p className="timeline__description">
                                                                    {ele.pastRole}
                                                                </p>
                                                            ) : false}
                                                        </article>
                                                    );
                                                })
                                            )}

                                        </div>

                                        {/* <!-- Skills --> */}
                                        <h2 className="title title--h2 mt-3">My Skills</h2>
                                        <div className="box box-inner mb-0">
                                            {/* <!-- Web Design --> */}
                                            {this.props.dataState.skills.length === 0 ? (
                                                false
                                            ) : (
                                                this.props.dataState.skills.map((ele, i) => {
                                                    return (
                                                        <div className="progress">
                                                            <div className="progress-text text-capitalize"><span>{ele.name}</span> {ele.rating + "%"}</div>
                                                            <div className="progress-bar"><Progress rating={ele.rating} /></div>
                                                        </div>
                                                    );
                                                })
                                            )}
                                        </div>
                                    </div>


                                    {/* Portfolio start  */}
                                    <div id="portfoliopage" style={{ display: 'none' }}>

                                        <div className="pb-3">
                                            <h1 className="title title--h1 title__separate">Portfolio</h1>
                                        </div>
                                        <div className="grid row">

                                            {this.props.dataState.portfolio.length === 0 ? (
                                                <h5 className="text-center text-dark">Portfolio is not uploaded yet!</h5>
                                            ) : (
                                                this.props.dataState.portfolio.map((ele, i) => {
                                                    return (
                                                        <div class="protfolio_grid col-md-4 category-web-development">
                                                            <div class="img-grid">
                                                                <a target="_blank" href={"https://www.digitizedresume.com/api/user/assets/portfolio/" + ele.file}><img class="lazyload" src={"https://www.digitizedresume.com/api/user/assets/portfolio/" + ele.file} alt={ele.title} /></a>
                                                            </div>
                                                            <div class="gallery-grid__caption">
                                                                <h3 class="title gallery-grid__title">{ele.title}</h3>
                                                                <p class="title gallery-grid__title">{ele.description}</p>
                                                            </div>
                                                        </div>
                                                    );

                                                })
                                            )}

                                        </div>
                                    </div>
                                    {/* <!-- Portfolio End --> */}

                                    {/* video start  */}
                                    <div id="videopage" style={{ display: 'none' }}>

                                        <div className="pb-3">
                                            <h1 className="title title--h1 title__separate">Video</h1>
                                        </div>
                                        <div className="grid row">

                                            {this.props.dataState.video.length === 0 ? (
                                                <h5 className="text-center text-dark">Video is not uploaded yet!</h5>
                                            ) : (
                                                this.props.dataState.video.map((ele, i) => {
                                                    return (
                                                        <div class="protfolio_grid col-md-4 category-web-development">
                                                            {ele.fileType === "URL" ? (
                                                                <>
                                                                    <div class="img-grid">
                                                                        <ReactPlayer
                                                                            width="100%"
                                                                            height="100%"
                                                                            controls
                                                                            allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
                                                                            allowfullscreen
                                                                            url={ele.file}
                                                                        />
                                                                    </div>
                                                                    <div class="gallery-grid__caption">
                                                                        <h3 class="title gallery-grid__title">{ele.title}</h3>
                                                                        <p class="title gallery-grid__title">{ele.description}</p>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div class="img-grid">
                                                                        <ReactPlayer
                                                                            width="100%"
                                                                            height="100%"
                                                                            controls
                                                                            allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
                                                                            allowfullscreen
                                                                            url={"https://www.digitizedresume.com/api/user/assets/video/" + ele.file}
                                                                        />
                                                                    </div>
                                                                    <div class="gallery-grid__caption">
                                                                        <h3 class="title gallery-grid__title">{ele.title}</h3>
                                                                        <p class="title gallery-grid__title">{ele.description}</p>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    );

                                                })
                                            )}
                                        </div>
                                    </div>
                                    {/* <!-- video End --> */}

                                    {/* certificate start  */}
                                    <div id="certificatepage" style={{ display: 'none' }}>

                                        <div className="pb-3">
                                            <h1 className="title title--h1 title__separate">Certificate</h1>
                                        </div>
                                        <div className="py-3">
                                            <div className="grid row">

                                                {this.props.dataState.certificate.length === 0 ? (
                                                    <h5 className="text-center text-dark">Certificate is not uploaded yet!</h5>
                                                ) : (
                                                    this.props.dataState.certificate.map((ele, i) => {
                                                        return (
                                                            <div class="protfolio_grid col-md-4 category-web-development">
                                                                <div class="img-grid">
                                                                    <a target="_blank" href={"https://www.digitizedresume.com/api/user/assets/certificate/" + ele.file}><img class="lazyload" src={"https://www.digitizedresume.com/api/user/assets/certificate/" + ele.file} alt={ele.certificateName} /></a>
                                                                </div>
                                                                <div class="gallery-grid__caption">
                                                                    <h3 class="title gallery-grid__title">{ele.certificateName}</h3>
                                                                    <p class="title gallery-grid__title">{ele.description}</p>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- certificate End --> */}

                                    {/* Project start  */}
                                    <div id="projectpage" style={{ display: 'none' }}>

                                        <div className="pb-3">
                                            <h1 className="title title--h1 title__separate">Project</h1>
                                        </div>
                                        <div className="py-3">
                                            <div className="grid row">

                                                {/* <!-- What --> */}
                                                <h2 class="title title--h2 mt-3">Project</h2>
                                                <div class="row">

                                                    {this.props.dataState.project.length === 0 ? (
                                                        false
                                                    ) : (
                                                        this.props.dataState.project.map((ele, i) => {
                                                            return (
                                                                <div class="col-12 col-lg-6">
                                                                    <div class="case-item box box-inner">
                                                                        <div>
                                                                            <h3 class="title title--h3">{ele.name}</h3>
                                                                            <h4 class="sub-title" style={{ fontSize: '14px' }}>{ele.technologyUsed}</h4>
                                                                            <p class="case-item__caption">{ele.description}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );

                                                        })
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Project End --> */}

                                    {/* contactpage */}

                                    <div id="contactpage" style={{ display: 'none' }}>

                                        <h2 className="title title--h2">Contact Form</h2>

                                        <form id="contact-form" className="contact-form" data-toggle="validator" onSubmit={this.contactFormSubmit}>
                                            <div className="row">
                                                <div className="form-group col-12 col-md-6 mt-1">
                                                    <input type="text" className="input form-control" id="nameContact" name="nameContact" placeholder="Full Name" required="required" autocomplete="off" />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                                <div className="form-group col-12 col-md-6">
                                                    <input type="email" className="input form-control" id="emailContact" name="emailContact" placeholder="Email Address" required="required" autocomplete="off" />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                                <div className="form-group col-12 col-md-12">
                                                    <input type="text" className="input form-control" id="subjectContact" name="subjectContact" placeholder="Enter Subject" required="required" autocomplete="off" />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                                <div className="form-group col-12 col-md-12">
                                                    <textarea className="textarea form-control" id="messageContact" name="messageContact" placeholder="Your Message" rows="4" required="required" ></textarea>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-6 order-2 order-md-1 text-center text-md-start">
                                                    <div id="validator-contact" className="hidden"></div>
                                                </div>
                                                <div className="col-12 col-md-6 order-1 order-md-2 text-end">
                                                    <button type="submit" className="btn"><i className="font-icon icon-send"></i> Send Message</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </div>
        )
    }
}

// export default DarkThemeCV
