import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import Axios from "axios";
import swal from 'sweetalert'

class Internship extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/user/",
            id: "",
            companyName: "",
            department: "",
            description: "",
            data: []
        };
    }

    componentDidMount() {
        var id = sessionStorage.getItem("id");
        let formData = new FormData();
        formData.append("id", id);
        var url = this.state.url + "getInternshipData.php";
        Axios.post(url, formData)
            .then((res) => {
                this.setState({ data: res.data });
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handle = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    submit = (e) => {
        e.preventDefault();
        var id = sessionStorage.getItem("id");
        document.getElementsByClassName("fa-spin")[13].classList.add("fa-refresh");
        document.getElementById("internshipSaveButton").disabled = true;

        let formData = new FormData();
        formData.append("id", id);
        formData.append("companyName", this.state.companyName);
        formData.append("department", this.state.department);
        formData.append("description", this.state.description);

        var url = this.state.url + "updateInternship.php";
        if (this.state.companyName === "" || this.state.department === "" || this.state.description === "") {
            document.getElementsByClassName("fa-spin")[13].classList.remove("fa-refresh");
            document.getElementById("internshipSaveButton").disabled = false;

            swal({
                title: "Error!",
                text: "Please fill all the data before submitting this form",
                button: "Close",
                icon: "error",
                timer: 3000,
                button: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                }
            });
        }
        else {
            Axios.post(url, formData).then((res) => {
                // alert(res.data);
                if (res.data === 1) {
                    document.getElementsByClassName("fa-spin")[13].classList.remove("fa-refresh");
                    document.getElementById("internshipSaveButton").disabled = false;

                    swal({
                        title: "Added!",
                        text: "Added successfully!",
                        button: "Close",
                        icon: "success",
                        timer: 3000,
                        button: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    });
                    document.getElementById("internshipAddForm").reset();
                    var id = sessionStorage.getItem("id");
                    let formData = new FormData();
                    formData.append("id", id);
                    var url = this.state.url + "getInternshipData.php";
                    Axios.post(url, formData)
                        .then((res) => {
                            this.setState({ data: res.data });
                            // console.log(res.data);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            });
        }
    };

    Delete = (e) => {
        e.preventDefault();
        var id = e.target.value;

        swal({
            title: "Are you sure?",
            text: "Once removed, you will have to add again!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let formData = new FormData();
                    formData.append("id", id);
                    var url = this.state.url + "deleteInternshipData.php";

                    Axios.post(url, formData)
                        .then((res) => {
                            swal({
                                title: "Deleted!",
                                text: "Deleted successfully!",
                                button: "Close",
                                icon: "success",
                                timer: 3000,
                                button: {
                                    confirm: {
                                        text: "OK",
                                        value: true,
                                        visible: true,
                                        className: "",
                                        closeModal: true
                                    }
                                }
                            });
                            var id = sessionStorage.getItem("id");
                            let formData = new FormData();
                            formData.append("id", id);
                            var url = this.state.url + "getInternshipData.php";
                            Axios.post(url, formData)
                                .then((res) => {
                                    this.setState({ data: res.data });
                                    // console.log(res.data);
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                        })
                        .catch((err) => {
                            // console.log(err);
                            this.setState({
                                res: err,
                            });
                        });
                }
            });
    };

    render() {

        return (
            <>
                {sessionStorage.getItem("id") ? (
                    <>
                        <div className="row mt-2">
                            <div className="col-md-12 col-12 px-0 mx-0">
                                <div className=" Project pb-5 bg-white">
                                    <h6 className="pl-3 Project_title"> Internship</h6>
                                    <h5 className="text-center mt-3">
                                        Simple & Best Way To Showcase Your Internships
                                    </h5>

                                    <h5 className="text-center">Add Internships</h5>
                                    <div className="form_section p-3">
                                        <form id="internshipAddForm" onSubmit={this.submit}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Form.Group className="row">
                                                        <div className="col-sm-12">
                                                            <Form.Control
                                                                type="text"
                                                                id="companyName"
                                                                name="companyName"
                                                                onChange={this.handle}
                                                                placeholder="Company Name"
                                                                className="form-control-sm"
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form.Group className="row">
                                                        <div className="col-sm-12">
                                                            <Form.Control
                                                                type="text"
                                                                id="department"
                                                                name="department"
                                                                onChange={this.handle}
                                                                placeholder="Department / Designation"
                                                                className="form-control-sm"
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12">
                                                    <Form.Group className="row">
                                                        <div className="col-sm-12">
                                                            <textarea className="form-control form-control-sm" placeholder="Roles And Responsibilities" style={{ height: '100px' }} name="description" id="description" onChange={this.handle}></textarea>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12 mb-1">
                                                    <button
                                                        class="post_jp_btn"
                                                        name="submit"
                                                        value="Submit"
                                                        type="submit"
                                                        style={{ float: "left" }}
                                                        id="internshipSaveButton"
                                                    >
                                                        Add Internship <i class="fa fa-spin"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-md-12 grid-margin stretch-card"
                                style={{ marginTop: "10px" }}
                            >
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Internship Details</h4>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>SN.</th>
                                                        <th>Company Name</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.data.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="3" className="text-center">
                                                                No Records Found
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        this.state.data.map((ele, i) => {
                                                            return (
                                                                <tr>
                                                                    <td>{i + 1}</td>
                                                                    <td>{ele.companyName}</td>
                                                                    <td>
                                                                        <button
                                                                            className="btn btn-danger m-1 badge badge-danger"
                                                                            onClick={this.Delete}
                                                                            value={ele.id}
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : false}
            </>
        );
    }
}

export default Internship;