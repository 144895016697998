import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './interview.css';
import Axios from 'axios';
import Spinner from '../shared/Spinner';

class ViewSingleInterviewDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/user/",
            dataStatus: "false",
            interviewDescription: '',
            startTime: '',
            duration: '',
            interviewLink: '',
            interviewDate: '',
            companyName: '',

        };
    }

    componentDidMount() {
        var id = sessionStorage.getItem("interviewId");
        let formData = new FormData();
        formData.append("id", id);
        var url = this.state.url + "singleInterviewData.php";
        Axios.post(url, formData)
            .then((res) => {
                this.setState({
                    // data: res.data,
                    interviewDescription: res.data[0].interviewDescription,
                    startTime: res.data[0].startTime,
                    duration: res.data[0].duration,
                    interviewLink: res.data[0].interviewLink,
                    interviewDate: res.data[0].interviewDate,
                    companyName: res.data[0].companyName,
                    dataStatus: "true"
                });
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    removeInterviewScheduleStatus = (e) => {
        sessionStorage.removeItem("viewInterviewInfo");
    }

    render() {
        return (
            <>
                <div id="scheduleInterviewAlert">
                    <p id="scheduleInterviewMessage"></p>
                </div>
                <div className="page-header">
                    <h3 className="page-title">Scheduled Interview Details</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Scheduled Interview</a></li>

                        </ol>
                    </nav>
                </div>

                {sessionStorage.getItem("viewInterviewInfo") ?
                    <>
                        {this.state.dataStatus === "true" ?
                            (<div className="row">
                                <form onSubmit={this.submit} id="scheduleInterviewForm">
                                    <div className="col-md-10 mt-3 mx-auto">

                                        <div className="card">
                                            <div className="card-header bg-white">
                                                <h5 className="pt-2"><b>Scheduled Interview Details</b></h5>
                                            </div>
                                            <div className="card-body m-0 pt-1">
                                                <div className="row">
                                                    <div className="col-md-12 mt-3">
                                                        <label>Comapny Name</label>
                                                        <input type="text" className="form-control bg-white" value={this.state.companyName} readOnly />
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label>Interview Description</label>
                                                        <textarea
                                                            className="form-control form-control-sm bg-white"
                                                            placeholder="Enter Interview Description"
                                                            style={{ height: "80px" }}
                                                            value={this.state.interviewDescription}
                                                            readOnly
                                                        ></textarea>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label>Start Time</label>
                                                        <input type="time" className="form-control bg-white" value={this.state.startTime} readOnly />
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label>Duration</label>
                                                        <input type="text" className="form-control bg-white" value={this.state.duration} readOnly />
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label>Interview Link</label>
                                                        <input type="text" className="form-control bg-white" value={this.state.interviewLink} readOnly />
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <label>Interview Date</label>
                                                        <input type="date" className="form-control bg-white" value={this.state.interviewDate} readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer bg-white text-right">
                                                <NavLink className="" to="/interview"><button onClick={this.removeInterviewScheduleStatus} className="btn btn-sm btn-danger"><b>Back</ b></button></ NavLink>
                                            </div>

                                        </div>

                                    </div>
                                </form>
                            </div>) :
                            (<Spinner />)
                        }
                    </>
                    : <NavLink className="text-center mx-auto" to="/interview"><button className="btn btn-sm btn-danger"><b>Go Back</b></button></ NavLink>}
            </>
        )
    }
}
export default ViewSingleInterviewDetails;