import React, { Component } from "react";
import "./chat.css";
import companyLogo from "./company.jpeg";
import { Link } from "react-router-dom";
import Axios from "axios";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/chat/user/",
      data: [],
      dataStatus: "false",
    };
  }

  componentDidMount() {
    if (!sessionStorage.getItem("uniqueId")) {
      window.location.href = "/login";
    }
    var id = sessionStorage.getItem("uniqueId");
    let formData = new FormData();
    formData.append("uniqueId", id);
    var url = this.state.url + "activeChat.php";
    Axios.post(url, formData)
      .then((res) => {
        if (res.data != "No Chat Found!") {
          this.setState({
            data: res.data,
            dataStatus: "true",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  startChat = (val, name, logo, status) => {
    var id = sessionStorage.getItem("uniqueId"); //company unique id from sessionStorage
    sessionStorage.setItem("outgoing_uniqueId", id); //setting company unique id as outgoing
    sessionStorage.setItem("incoming_uniqueId", val); //setting user unique id as incoming
    sessionStorage.setItem("inc_name", name);
    sessionStorage.setItem("inc_logo", logo);
    sessionStorage.setItem("inc_status", status);
  };

  render() {
    return (
      <div className="row border">
        <div className="col-md-4 chat_profile border-right">
          <div className="row">
            <div className="col-md-7 p-2 chat_heading">
              <h4>Chats</h4>
            </div>
            <div className="col-md-3 p-2 chat_heading">
              {/* <button
                    onClick={this.changeUserType}
                    className="btn btn-outline-dark"
                    style={{ fontSize: "14px", borderRadius: "5px" }}
                  >
                    Inbox
                  </button> */}
            </div>
            <div className="col-md-12 profiles">
              {/* start new chat */}
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "10px",
                  color: "#2A71D7",
                }}
              >
                Conversation ({this.state.data.length})
              </p>
              {this.state.data.length > 0 ? (
                this.state.data.map((ele, i) => {
                  return (
                    <Link
                      onClick={() =>
                        this.startChat(
                          ele.uniqueId,
                          ele.name,
                          ele.logo,
                          ele.onlineStatus
                        )
                      }
                      to="/conversation"
                      className="link"
                    >
                      <div className="row sideBar-body mt-3" id="active">
                        <div className="col-sm-3 col-3 text-center sideBar-avatar">
                          <div className="avatar-icon">
                            <img
                              src={
                                ele.logo != null
                                  ? "https://www.digitizedresume.com/api/company/assets/profile/" +
                                    ele.logo
                                  : companyLogo
                              }
                              alt="pic"
                            />
                          </div>
                        </div>
                        <div className="col-sm-9 col-9 sideBar-main">
                          <div className="row">
                            <div className="col-sm-8 col-8 sideBar-name">
                              <span className="name-meta text-dark">{ele.name}</span>
                            </div>
                            <div className="col-sm-4 col-4 pull-right sideBar-time">
                              <span className="time-meta pull-right">
                                {ele.onlineStatus == "Online" ? (
                                  <span
                                  className="text-success"
                                    style={{
                                      fontWeight: "500",
                                    }}
                                  >
                                    Online
                                  </span>
                                ) : (
                                  <span
                                  className="text-dark"
                                    style={{
                                      fontWeight: "500",
                                    }}
                                  >
                                    Offline
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })
              ) : (
                <p className="text-center">No chat available!</p>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-8 bg-white main_banner_heading">
          <div className="row">
            <div className="col-md-12 p-3 main_banner_heading border-bottom">
              <h6>Chat</h6>
            </div>

            <div className="col-md-12 main_banner">
              {/* <p> Tap on profile to chat conversation </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Chat;
