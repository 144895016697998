import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './job.css';
import Axios from 'axios';
import Spinner from '../shared/Spinner';

class TrendingJob extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/user/",
            dataStatus: "false",
            data: ''
        };
    }

    componentDidMount() {
        var url = this.state.url + "jobData.php";
        Axios.get(url)
            .then((res) => {
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    apply = (e) => {
        e.preventDefault();
        // alert(e.target.id);

        var jobId = e.target.id;
        var userId = sessionStorage.getItem("id");
        let formData = new FormData();
        formData.append("userId", userId);
        formData.append("jobId", jobId);
        var url = this.state.url + "applyJob.php";
        Axios.post(url, formData)
            .then((res) => {
                // alert(res.data);
                if (res.data === 1) {
                    document.getElementById("jobMessage").innerHTML = "Job Applied";
                    document.getElementById("jobAlert").style.display = "block";
                    document.getElementById("jobMessage").classList.add("messageSuccess");
                    setTimeout(function () {
                        document.getElementById("jobAlert").style.display = "none";
                        document.getElementById("jobMessage").classList.remove("messageSuccess");
                    }, 3000);
                }
                else {
                    document.getElementById("jobMessage").innerHTML = "You have already applied for this job!";
                    document.getElementById("jobAlert").style.display = "block";
                    document.getElementById("jobMessage").classList.add("messageSuccess");
                    setTimeout(function () {
                        document.getElementById("jobAlert").style.display = "none";
                        document.getElementById("jobMessage").classList.remove("messageSuccess");
                    }, 3000);
                }
                this.setState({
                    dataStatus: "true"
                });
                // console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <>
                {sessionStorage.getItem("id") ?
                    <>
                        <div id="jobAlert">
                            <p id="jobMessage"></p>
                        </div>
                        <div className="page-header">
                            <h3 className="page-title">Trending Jobs</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink exact to="/applied-job"><button className="btn btn-sm btn-success">View Applied Job</button></NavLink></li>
                                </ol>
                            </nav>
                        </div>
                        {this.state.dataStatus === "false" ? (
                            <Spinner />
                        ) : (
                            <div className="row jobViewSection">
                                {this.state.data.length === 0 ? (
                                    <div className="col-md-12 mt-3">
                                        <p className="text-center">
                                            No jobs found. Currently there is no trending jobs available!
                                        </p>
                                    </div>
                                ) : (
                                    this.state.data.map((ele, i) => {
                                        return (

                                            <div className="col-md-10 mx-auto mt-4">
                                                <div className="card">
                                                    <div className="card-header bg-white">
                                                        <div className="row">
                                                            <div className="col-md-10">
                                                                <h5 className="pt-2 text-danger"><b>{ele.jobTitle}</b></h5>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <button className="job_apply float-right" onClick={this.apply} id={ele.id}>Apply</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body pt-2">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <h5><b>{ele.companyName} - {ele.location} {ele.remoteJob === "Yes" ? "(Remote Job)" : (false)}</b></h5>
                                                                <label className="desc"><b className="text-primary">Job Description:</b><br />{ele.jobDescription}</label>
                                                                <div className="row job_description">
                                                                    <div className="col-md-3">
                                                                        <label><i className="mdi mdi-briefcase"></i> {ele.exp}</label>
                                                                    </div>

                                                                    <div className="col-md-3">
                                                                        <label> <span className="rupee_sign"> ₹ </span> {ele.minSalary} - {ele.maxSalary}</label>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <label><i className="mdi mdi-calendar"></i> {ele.date}</label>
                                                                    </div>

                                                                    <div className="col-md-12">
                                                                        <label> <i className="mdi mdi-file"></i> {ele.skills}</label>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        );
                                    })
                                )}
                            </div>
                        )}
                    </> : false
                }
            </>
        )
    }
}
export default TrendingJob;