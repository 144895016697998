import React, { Component } from 'react';
import './education.css';
import { Form } from 'react-bootstrap';
import Axios from 'axios';
import swal from 'sweetalert';


class Education extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/user/",

            universityCollege1: '',
            highestEducation: '',
            highestQualification: '',
            passingYear1: '',
            course1: '',
            courseType1: '',
            specialization1: '',

            universityCollege2: '',
            qualification2: '',
            passingYear2: '',
            course2: '',
            courseType2: '',
            specialization2: '',

            universityCollege3: '',
            qualification3: '',
            passingYear3: '',
            course3: '',
            courseType3: '',
            specialization3: '',

        }
    }

    componentDidMount() {
        var id = sessionStorage.getItem("id");
        let formData = new FormData();
        formData.append('id', id);
        // console.log(obj)
        var url = this.state.url + 'getData.php';
        Axios.post(url, formData)
            .then(res => {
                // console.log(res.data);
                this.setState({
                    universityCollege1: res.data[0].universityCollege1,
                    highestEducation: res.data[0].highestEducation,
                    highestQualification: res.data[0].highestQualification,
                    passingYear1: res.data[0].passingYear1,
                    course1: res.data[0].course1,
                    courseType1: res.data[0].courseType1,
                    specialization1: res.data[0].specialization1,

                    universityCollege2: res.data[0].universityCollege2,
                    qualification2: res.data[0].qualification2,
                    passingYear2: res.data[0].passingYear2,
                    course2: res.data[0].course2,
                    courseType2: res.data[0].courseType2,
                    specialization2: res.data[0].specialization2,

                    universityCollege3: res.data[0].universityCollege3,
                    qualification3: res.data[0].qualification3,
                    passingYear3: res.data[0].passingYear3,
                    course3: res.data[0].course3,
                    courseType3: res.data[0].courseType3,
                    specialization3: res.data[0].specialization3,
                });
            })

    }

    handle = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    submit = (e) => {
        e.preventDefault();
        
        document.getElementsByClassName("fa-spin")[3].classList.add("fa-refresh");
        document.getElementById("educationSaveButton").disabled = true;

        let formData = new FormData();
        var id = sessionStorage.getItem("id");

        formData.append('id', id);

        formData.append('universityCollege1', this.state.universityCollege1);
        formData.append('highestEducation', this.state.highestEducation);
        formData.append('highestQualification', this.state.highestQualification);
        formData.append('passingYear1', this.state.passingYear1);
        formData.append('course1', this.state.course1);
        formData.append('courseType1', this.state.courseType1);
        formData.append('specialization1', this.state.specialization1);

        formData.append('universityCollege2', this.state.universityCollege2);
        formData.append('qualification2', this.state.qualification2);
        formData.append('passingYear2', this.state.passingYear2);
        formData.append('course2', this.state.course2);
        formData.append('courseType2', this.state.courseType2);
        formData.append('specialization2', this.state.specialization2);

        formData.append('universityCollege3', this.state.universityCollege3);
        formData.append('qualification3', this.state.qualification3);
        formData.append('passingYear3', this.state.passingYear3);
        formData.append('course3', this.state.course3);
        formData.append('courseType3', this.state.courseType3);
        formData.append('specialization3', this.state.specialization3);

        // console.log(obj)
        var url = this.state.url + 'updateEducation.php';
        Axios.post(url, formData)
            .then((res) => {
                document.getElementsByClassName("fa-spin")[3].classList.remove("fa-refresh");
                document.getElementById("educationSaveButton").disabled = false;
                swal({
                    title: "Updated!",
                    text: "Data updated successfully!",
                    button: "Close",
                    icon: "success",
                    timer: 3000,
                    button: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    }
                });
            });
    }

    render() {
        return (
            <>
                {sessionStorage.getItem("id") ? (
                    <>
                        <div className="row">
                            <div className="col-md-12 col-12 px-0 mx-0">
                                <div className="education bg-white">
                                    <h6 className="pl-3 education_title">Education</h6>
                                    <form onSubmit={this.submit}>

                                        <div className="form-section p-3">
                                            <div className="row">
                                                <div className="col-md-12 mt-1 mb-2"><h5 className="text-danger">Highest Qualification</h5></div>
                                                <div className="col-md-12">
                                                    <Form.Group className="row">
                                                        <label className="pl-3">University/College: *</label>
                                                        <div className="col-sm-12">
                                                            {(() => {
                                                                if (this.state.universityCollege1 === "null" || this.state.universityCollege1 === null) {
                                                                    return (
                                                                        <Form.Control type="text" id="universityCollege1" onChange={this.handle} name="universityCollege1" className="form-control-sm" placeholder="Enter University/College Name" />
                                                                    );
                                                                }
                                                                else {
                                                                    return (
                                                                        <Form.Control type="text" value={this.state.universityCollege1} id="universityCollege1" onChange={this.handle} name="universityCollege1" className="form-control-sm" />
                                                                    );
                                                                }
                                                            })()}
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-6">
                                                    <Form.Group className="row">
                                                        <label className="pl-3">Highest Education *</label>
                                                        <div className="col-sm-12">
                                                            {(() => {
                                                                if (this.state.highestEducation === "null" || this.state.highestEducation === null || this.state.highestEducation === "") {
                                                                    return (
                                                                        <Form.Control type="text" onChange={this.handle} id="highestEducation" name="highestEducation" className="form-control-sm" placeholder="Enter Highest Education" />
                                                                    );
                                                                }
                                                                else {
                                                                    return (
                                                                        <Form.Control type="text" onChange={this.handle} value={this.state.highestEducation} id="highestEducation" name="highestEducation" className="form-control-sm" />
                                                                    );
                                                                }
                                                            })()}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="highestQualification">Highest Qualification: *</label>
                                                        {(() => {
                                                            if (this.state.highestQualification === "null" || this.state.highestQualification === null || this.state.highestQualification === "") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="highestQualification" name="highestQualification">
                                                                        <option value="" disabled selected>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.highestQualification === "DoctoratePhd") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="highestQualification" name="highestQualification">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd" selected>Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.highestQualification === "Masters/Post-Graduation") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="highestQualification" name="highestQualification">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation" selected>Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.highestQualification === "Graduation") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="highestQualification" name="highestQualification">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation" selected>Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.highestQualification === "12th") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="highestQualification" name="highestQualification">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th" selected>12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.highestQualification === "10th") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="highestQualification" name="highestQualification">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th" selected>10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.highestQualification === "PUC") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="highestQualification" name="highestQualification">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th" >10th</option>
                                                                        <option label="PUC" value="PUC" selected>PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.highestQualification === "Diploma") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="highestQualification" name="highestQualification">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th" >10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" selected>Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.highestQualification === "ITI") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="highestQualification" name="highestQualification">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th" >10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" selected>ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="passingYear1">Passing Year: *</label>
                                                        {(() => {
                                                            if (this.state.passingYear1 === "null" || this.state.passingYear1 === null || this.state.passingYear1 === "") {
                                                                return (
                                                                    <Form.Control type="number" onChange={this.handle} id="passingYear1" name="passingYear1" className="form-control-sm" placeholder="Enter Passing Year" />
                                                                );
                                                            }
                                                            else {
                                                                return (
                                                                    <Form.Control type="number" value={this.state.passingYear1} onChange={this.handle} id="passingYear1" name="passingYear1" className="form-control-sm" />
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="course1">Course: *</label>
                                                        {(() => {
                                                            if (this.state.course1 === "null" || this.state.course1 === null || this.state.course1 === "") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled selected>Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course1 === "BSBSC") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC" selected>BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course1 === "BBA") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA" selected>BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course1 === "BCA") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA" selected>BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course1 === "BCOM") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM" selected>BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course1 === "BTech") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech" selected>B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course1 === "CS") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS" selected>CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course1 === "DM") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM" selected>DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course1 === "ICDA") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA" selected>ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course1 === "LLM") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM" selected>LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course1 === "BDes") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes" selected>B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course1 === "BEd") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd" selected>B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course1 === "BFA") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA" selected>BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course1 === "Medicals") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals" selected>Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course1 === "other") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course1" name="course1">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other" selected>other</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="courseType1">Course Type: *</label>
                                                        {(() => {
                                                            if (this.state.courseType1 === "null" || this.state.courseType1 === null || this.state.courseType1 === "") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="courseType1" name="courseType1">
                                                                        <option value="" disabled selected>Select Any</option>
                                                                        <option value="Full Time">Full Time</option>
                                                                        <option value="Part Time">Part Time</option>
                                                                        <option value="Correspondent">Correspondent</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.courseType1 === "Full Time") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="courseType1" name="courseType1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option value="Full Time" selected>Full Time</option>
                                                                        <option value="Part Time">Part Time</option>
                                                                        <option value="Correspondent">Correspondent</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.courseType1 === "Part Time") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="courseType1" name="courseType1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option value="Full Time">Full Time</option>
                                                                        <option value="Part Time" selected>Part Time</option>
                                                                        <option value="Correspondent">Correspondent</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.courseType1 === "Correspondent") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="courseType1" name="courseType1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option value="Full Time">Full Time</option>
                                                                        <option value="Part Time">Part Time</option>
                                                                        <option value="Correspondent" selected>Correspondent</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="specialization1">Specialization : *</label>
                                                        {(() => {
                                                            if (this.state.specialization1 === "null" || this.state.specialization1 === null || this.state.courseType1 === "") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled selected>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Marketing") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing" selected>Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Finance") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance" selected>Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Doctorate") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate" selected>Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Arts") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts" selected>Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Economy") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy" selected>Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "English") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English" selected>English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "PoliticalScience") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience" selected>Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Agriculture") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture" selected>Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Anthropology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology" selected>Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Astrophysics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics" selected>Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Automobile") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile" selected>Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Aviation") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation" selected>Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Biomedical") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical" selected>Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Biophysics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics" selected>Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Biotechnology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology" selected>Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Botany") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany" selected>Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Ceramics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics" selected>Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Chemical") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical" selected>Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Civil") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil" selected>Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Chemistry") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry" selected>Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Commerce") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce" selected>Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Communication") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication" selected>Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Computers") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers" selected>Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "DairyTechnology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology" selected>Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Dermatology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology" selected>Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Economics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics" selected>Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Education") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education" selected>Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Electrical") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical" selected>Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Energy") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy" selected>Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "ENT") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT" selected>ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Environmental") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental" selected>Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Firm") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm" selected>Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Genetic") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic" selected>Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "History") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History" selected>History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "HomeScience") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience" selected>Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Hotelmanagement") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement" selected>Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "nnothi") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi" selected>nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "HR") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR" selected>HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Immunology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology" selected>Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Instrumentation") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation" selected>Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Journalism") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism" selected>Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Law") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law" selected>Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Marine") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine" selected>Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Maths") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" selected>Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Mathematics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics" selected>Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Mechanical") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical" selected>Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Mineral") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral" selected>Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Mining") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining" selected>Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Music") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music" selected>Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Nuclear") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear" selected>Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Nursing") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing" selected>Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization1 === "Other") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization1" name="specialization1">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other" selected>Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                </div>

                                                <div className="col-md-12 mt-3 mb-2"><h5 className="text-danger">2<sup>nd</sup> Qualification</h5></div>

                                                <div className="col-md-12">
                                                    <Form.Group className="row">
                                                        <label className="pl-3">University/College: *</label>
                                                        <div className="col-sm-12">
                                                            {(() => {
                                                                if (this.state.universityCollege2 === "null" || this.state.universityCollege2 === null) {
                                                                    return (
                                                                        <Form.Control type="text" id="universityCollege2" onChange={this.handle} name="universityCollege2" className="form-control-sm" placeholder="Enter University/College Name" />
                                                                    );
                                                                }
                                                                else {
                                                                    return (
                                                                        <Form.Control type="text" value={this.state.universityCollege2} id="universityCollege2" onChange={this.handle} name="universityCollege2" className="form-control-sm" />
                                                                    );
                                                                }
                                                            })()}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="qualification2">Qualification: *</label>
                                                        {(() => {
                                                            if (this.state.qualification2 === "null" || this.state.qualification2 === null || this.state.qualification2 === "") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification2" name="qualification2">
                                                                        <option value="" disabled selected>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification2 === "DoctoratePhd") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification2" name="qualification2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd" selected>Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification2 === "Masters/Post-Graduation") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification2" name="qualification2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation" selected>Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification2 === "Graduation") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification2" name="qualification2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation" selected>Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification2 === "12th") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification2" name="qualification2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th" selected>12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification2 === "10th") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification2" name="qualification2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th" selected>10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification2 === "PUC") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification2" name="qualification2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th" >10th</option>
                                                                        <option label="PUC" value="PUC" selected>PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification2 === "Diploma") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification2" name="qualification2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th" >10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" selected>Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification2 === "ITI") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification2" name="qualification2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th" >10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" selected>ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="passingYear2">Passing Year: *</label>
                                                        {(() => {
                                                            if (this.state.passingYear2 === "null" || this.state.passingYear2 === null) {
                                                                return (
                                                                    <Form.Control type="number" onChange={this.handle} id="passingYear2" name="passingYear2" className="form-control-sm" placeholder="Enter Passing Year" />
                                                                );
                                                            }
                                                            else {
                                                                return (
                                                                    <Form.Control type="number" value={this.state.passingYear2} onChange={this.handle} id="passingYear2" name="passingYear2" className="form-control-sm" />
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="course2">Course: *</label>
                                                        {(() => {
                                                            if (this.state.course2 === "null" || this.state.course2 === null || this.state.course2 === "") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled selected>Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course2 === "BSBSC") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC" selected>BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course2 === "BBA") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA" selected>BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course2 === "BCA") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA" selected>BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course2 === "BCOM") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM" selected>BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course2 === "BTech") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech" selected>B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course2 === "CS") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS" selected>CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course2 === "DM") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM" selected>DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course2 === "ICDA") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA" selected>ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course2 === "LLM") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM" selected>LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course2 === "BDes") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes" selected>B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course2 === "BEd") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd" selected>B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course2 === "BFA") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA" selected>BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course2 === "Medicals") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals" selected>Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course2 === "other") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course2" name="course2">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other" selected>other</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="courseType2">Course Type: *</label>
                                                        {(() => {
                                                            if (this.state.courseType2 === "null" || this.state.courseType2 === null || this.state.courseType2 === "") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="courseType2" name="courseType2">
                                                                        <option value="" disabled selected>Select Any</option>
                                                                        <option value="Full Time">Full Time</option>
                                                                        <option value="Part Time">Part Time</option>
                                                                        <option value="Correspondent">Correspondent</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.courseType2 === "Full Time") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="courseType2" name="courseType2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option value="Full Time" selected>Full Time</option>
                                                                        <option value="Part Time">Part Time</option>
                                                                        <option value="Correspondent">Correspondent</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.courseType2 === "Part Time") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="courseType2" name="courseType2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option value="Full Time">Full Time</option>
                                                                        <option value="Part Time" selected>Part Time</option>
                                                                        <option value="Correspondent">Correspondent</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.courseType2 === "Correspondent") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="courseType2" name="courseType2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option value="Full Time">Full Time</option>
                                                                        <option value="Part Time">Part Time</option>
                                                                        <option value="Correspondent" selected>Correspondent</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="specialization2">Specialization : *</label>
                                                        {(() => {
                                                            if (this.state.specialization2 === "null" || this.state.specialization2 === null || this.state.specialization2 === "") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled selected>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Marketing") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing" selected>Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Finance") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance" selected>Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Doctorate") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate" selected>Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Arts") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts" selected>Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Economy") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy" selected>Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "English") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English" selected>English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "PoliticalScience") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience" selected>Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Agriculture") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture" selected>Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Anthropology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology" selected>Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Astrophysics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics" selected>Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Automobile") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile" selected>Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Aviation") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation" selected>Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Biomedical") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical" selected>Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Biophysics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics" selected>Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Biotechnology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology" selected>Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Botany") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany" selected>Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Ceramics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics" selected>Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Chemical") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical" selected>Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Civil") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil" selected>Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Chemistry") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry" selected>Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Commerce") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce" selected>Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Communication") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication" selected>Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Computers") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers" selected>Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "DairyTechnology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology" selected>Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Dermatology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology" selected>Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Economics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics" selected>Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Education") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education" selected>Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Electrical") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical" selected>Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Energy") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy" selected>Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "ENT") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT" selected>ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Environmental") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental" selected>Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Firm") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm" selected>Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Genetic") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic" selected>Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "History") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History" selected>History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "HomeScience") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience" selected>Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Hotelmanagement") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement" selected>Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "nnothi") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi" selected>nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "HR") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR" selected>HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Immunology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology" selected>Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Instrumentation") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation" selected>Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Journalism") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism" selected>Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Law") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law" selected>Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Marine") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine" selected>Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Maths") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" selected>Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Mathematics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics" selected>Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Mechanical") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical" selected>Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Mineral") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral" selected>Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Mining") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining" selected>Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Music") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music" selected>Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Nuclear") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear" selected>Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Nursing") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing" selected>Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization2 === "Other") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization2" name="specialization2">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other" selected>Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-12 mt-3 mb-2"><h5 className="text-danger">3<sup>rd</sup> Qualification</h5></div>


                                                <div className="col-md-12">
                                                    <Form.Group className="row">
                                                        <label className="pl-3">University/College: *</label>
                                                        <div className="col-sm-12">
                                                            {(() => {
                                                                if (this.state.universityCollege3 === "null" || this.state.universityCollege3 === null) {
                                                                    return (
                                                                        <Form.Control type="text" id="universityCollege3" onChange={this.handle} name="universityCollege3" className="form-control-sm" placeholder="Enter University/College Name" />
                                                                    );
                                                                }
                                                                else {
                                                                    return (
                                                                        <Form.Control type="text" value={this.state.universityCollege3} id="universityCollege3" onChange={this.handle} name="universityCollege3" className="form-control-sm" />
                                                                    );
                                                                }
                                                            })()}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="qualification3">Qualification: *</label>
                                                        {(() => {
                                                            if (this.state.qualification3 === "null" || this.state.qualification3 === null || this.state.specialization2 === "") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification3" name="qualification3">
                                                                        <option value="" disabled selected>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification3 === "DoctoratePhd") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification3" name="qualification3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd" selected>Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification3 === "Masters/Post-Graduation") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification3" name="qualification3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation" selected>Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification3 === "Graduation") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification3" name="qualification3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation" selected>Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification3 === "12th") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification3" name="qualification3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th" selected>12th</option>
                                                                        <option label="10th" value="10th">10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification3 === "10th") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification3" name="qualification3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th" selected>10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification3 === "PUC") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification3" name="qualification3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th" >10th</option>
                                                                        <option label="PUC" value="PUC" selected>PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification3 === "Diploma") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification3" name="qualification3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th" >10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" selected>Diploma</option>
                                                                        <option label="ITI" value="ITI" >ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.qualification3 === "ITI") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="qualification3" name="qualification3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Doctorate/Phd" value="DoctoratePhd">Doctorate/Phd</option>
                                                                        <option label="Masters/Post-Graduation" value="Masters/Post-Graduation">Masters/Post-Graduation</option>
                                                                        <option label="Graduation" value="Graduation">Graduation</option>
                                                                        <option label="12th" value="12th">12th</option>
                                                                        <option label="10th" value="10th" >10th</option>
                                                                        <option label="PUC" value="PUC" >PUC</option>
                                                                        <option label="Diploma" value="Diploma" >Diploma</option>
                                                                        <option label="ITI" value="ITI" selected>ITI</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="passingYear3">Passing Year: *</label>
                                                        {(() => {
                                                            if (this.state.passingYear3 === "null" || this.state.passingYear3 === null) {
                                                                return (
                                                                    <Form.Control type="number" onChange={this.handle} id="passingYear3" name="passingYear3" className="form-control-sm" placeholder="Enter Passing Year" />
                                                                );
                                                            }
                                                            else {
                                                                return (
                                                                    <Form.Control type="number" value={this.state.passingYear3} onChange={this.handle} id="passingYear3" name="passingYear3" className="form-control-sm" />
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="course3">Course: *</label>
                                                        {(() => {
                                                            if (this.state.course3 === "null" || this.state.course3 === null || this.state.course3 === "") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled selected>Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course3 === "BSBSC") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC" selected>BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course3 === "BBA") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA" selected>BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course3 === "BCA") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA" selected>BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course3 === "BCOM") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM" selected>BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course3 === "BTech") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech" selected>B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course3 === "CS") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS" selected>CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course3 === "DM") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM" selected>DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course3 === "ICDA") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA" selected>ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course3 === "LLM") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM" selected>LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course3 === "BDes") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes" selected>B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course3 === "BEd") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd" selected>B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course3 === "BFA") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA" selected>BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course3 === "Medicals") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals" selected>Medicals</option>
                                                                        <option value="other">other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.course3 === "other") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="course3" name="course3">
                                                                        <option value="" disabled >Select Any</option>
                                                                        <option value="BSBSC">BS/BSC</option>
                                                                        <option value="BBA">BBA</option>
                                                                        <option value="BCA">BCA</option>
                                                                        <option value="BCOM">BCOM</option>
                                                                        <option value="BTech">B Tech</option>
                                                                        <option value="CS">CS</option>
                                                                        <option value="DM">DM</option>
                                                                        <option value="ICDA">ICDA</option>
                                                                        <option value="LLM">LLM</option>
                                                                        <option value="BDes">B Des</option>
                                                                        <option value="BEd">B Ed</option>
                                                                        <option value="BFA">BFA</option>
                                                                        <option value="Medicals">Medicals</option>
                                                                        <option value="other" selected>other</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="courseType3">Course Type: *</label>
                                                        {(() => {
                                                            if (this.state.courseType3 === "null" || this.state.courseType3 === null || this.state.courseType3 === "") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="courseType3" name="courseType3">
                                                                        <option value="" disabled selected>Select Any</option>
                                                                        <option value="Full Time">Full Time</option>
                                                                        <option value="Part Time">Part Time</option>
                                                                        <option value="Correspondent">Correspondent</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.courseType3 === "Full Time") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="courseType3" name="courseType3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option value="Full Time" selected>Full Time</option>
                                                                        <option value="Part Time">Part Time</option>
                                                                        <option value="Correspondent">Correspondent</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.courseType3 === "Part Time") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="courseType3" name="courseType3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option value="Full Time">Full Time</option>
                                                                        <option value="Part Time" selected>Part Time</option>
                                                                        <option value="Correspondent">Correspondent</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.courseType3 === "Correspondent") {
                                                                return (
                                                                    <select className="form-control form-control-sm" onChange={this.handle} id="courseType3" name="courseType3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option value="Full Time">Full Time</option>
                                                                        <option value="Part Time">Part Time</option>
                                                                        <option value="Correspondent" selected>Correspondent</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Group>
                                                        <label htmlFor="specialization3">Specialization : *</label>
                                                        {(() => {
                                                            if (this.state.specialization3 === "null" || this.state.specialization3 === null || this.state.courseType3 === "") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled selected>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Marketing") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing" selected>Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Finance") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance" selected>Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Doctorate") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate" selected>Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Arts") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts" selected>Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Economy") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy" selected>Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "English") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English" selected>English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "PoliticalScience") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience" selected>Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Agriculture") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture" selected>Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Anthropology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology" selected>Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Astrophysics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics" selected>Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Automobile") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile" selected>Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Aviation") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation" selected>Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Biomedical") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical" selected>Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Biophysics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics" selected>Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Biotechnology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology" selected>Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Botany") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany" selected>Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Ceramics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics" selected>Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Chemical") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical" selected>Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Civil") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil" selected>Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Chemistry") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry" selected>Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Commerce") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce" selected>Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Communication") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication" selected>Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Computers") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers" selected>Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "DairyTechnology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology" selected>Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Dermatology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology" selected>Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Economics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics" selected>Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Education") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education" selected>Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Electrical") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical" selected>Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Energy") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy" selected>Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "ENT") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT" selected>ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Environmental") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental" selected>Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm </option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Firm") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm" selected>Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Genetic") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic" selected>Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "History") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History" selected>History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "HomeScience") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience" selected>Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Hotelmanagement") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement" selected>Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "nnothi") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi" selected>nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "HR") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR" selected>HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Immunology") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology" selected>Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Instrumentation") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation" selected>Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Journalism") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism" selected>Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Law") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law" selected>Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Marine") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine" selected>Marine</option>
                                                                        <option label="Maths" value="Maths">Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Maths") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" selected>Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Mathematics") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics" selected>Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Mechanical") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical" selected>Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Mineral") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral" selected>Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Mining") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining" selected>Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Music") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music" selected>Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Nuclear") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear" selected>Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Nursing") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing" selected>Nursing</option>
                                                                        <option label="Other" value="Other">Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                            else if (this.state.specialization3 === "Other") {
                                                                return (
                                                                    <select onChange={this.handle} className="form-control form-control-sm" id="specialization3" name="specialization3">
                                                                        <option value="" disabled>Select Any</option>
                                                                        <option label="Marketing" value="Marketing">Marketing</option>
                                                                        <option label="Finance" value="Finance">Finance</option>
                                                                        <option label="Doctorate" value="Doctorate">Doctorate</option>
                                                                        <option label="Arts" value="Arts">Arts</option>
                                                                        <option label="Economy" value="Economy">Economy</option>
                                                                        <option label="English" value="English">English</option>
                                                                        <option label="Political Science" value="PoliticalScience">Political Science</option>
                                                                        <option label="Agriculture" value="Agriculture">Agriculture</option>
                                                                        <option label="Anthropology" value="Anthropology">Anthropology</option>
                                                                        <option label="Astrophysics" value="Astrophysics">Astrophysics</option>
                                                                        <option label="Automobile" value="Automobile">Automobile</option>
                                                                        <option label="Aviation" value="Aviation">Aviation</option>
                                                                        <option label="Biomedical" value="Biomedical">Biomedical</option>
                                                                        <option label="Biophysics" value="Biophysics">Biophysics</option>
                                                                        <option label="Biotechnology" value="Biotechnology">Biotechnology</option>
                                                                        <option label="Botany" value="Botany">Botany</option>
                                                                        <option label="Ceramics" value="Ceramics">Ceramics</option>
                                                                        <option label="Chemical" value="Chemical">Chemical</option>
                                                                        <option label="Civil" value="Civil">Civil</option>
                                                                        <option label="Chemistry" value="Chemistry">Chemistry</option>
                                                                        <option label="Commerce" value="Commerce">Commerce</option>
                                                                        <option label="Communication" value="Communication">Communication</option>
                                                                        <option label="Computers" value="Computers">Computers</option>
                                                                        <option label="Dairy Technology" value="DairyTechnology">Dairy Technology</option>
                                                                        <option label="Dermatology" value="Dermatology">Dermatology</option>
                                                                        <option label="Economics" value="Economics">Economics</option>
                                                                        <option label="Education" value="Education">Education</option>
                                                                        <option label="Electrical" value="Electrical">Electrical</option>
                                                                        <option label="Energy" value="Energy">Energy</option>
                                                                        <option label="ENT" value="ENT">ENT</option>
                                                                        <option label="Environmental" value="Environmental">Environmental</option>
                                                                        <option label="Firm " value="Firm">Firm</option>
                                                                        <option label="Genetic" value="Genetic">Genetic</option>
                                                                        <option label="History" value="History">History</option>
                                                                        <option label="Home Science" value="HomeScience">Home Science</option>
                                                                        <option label="Hotel management " value="Hotelmanagement">Hotel management </option>
                                                                        <option label="nnothi " value="nnothi">nnothi </option>
                                                                        <option label="HR" value="HR">HR</option>
                                                                        <option label="Immunology" value="Immunology">Immunology</option>
                                                                        <option label="Instrumentation" value="Instrumentation">Instrumentation</option>
                                                                        <option label="Journalism" value="Journalism">Journalism</option>
                                                                        <option label="Law" value="Law">Law</option>
                                                                        <option label="Marine" value="Marine">Marine</option>
                                                                        <option label="Maths" value="Maths" >Maths</option>
                                                                        <option label="Mathematics" value="Mathematics">Mathematics</option>
                                                                        <option label="Mechanical" value="Mechanical">Mechanical</option>
                                                                        <option label="Mineral" value="Mineral">Mineral</option>
                                                                        <option label="Mining" value="Mining">Mining</option>
                                                                        <option label="Music" value="Music">Music</option>
                                                                        <option label="Nuclear" value="Nuclear">Nuclear</option>
                                                                        <option label="Nursing" value="Nursing">Nursing</option>
                                                                        <option label="Other" value="Other" selected>Other</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}

                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-12 mb-3">
                                                    <button class="post_jp_btn" id="educationSaveButton" name="submit" value="Submit" type="submit">SAVE CHANGES <i class="fa fa-spin"></i></button>
                                                </div>


                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>
                ) : false}
            </>
        )
    }
}
export default Education