import React, { Component, useState } from "react";
import { NavLink } from "react-router-dom";
import pic from "./pic.png";
import "./resume.css";
import Education from "./education/education";
import Exprience from "./workexprience/workexprience";
import Skill from "./skill/skill";
import UploadResume from "./resume-sec/resumesec";
import Interest from "./interest/interest";
import Language from "./language/language";
import Protofolio from "./protofolio/protofolio";
import Certificate from "./certificate/certificate";
import Axios from "axios";
import ProfilePic from "./pic.png";
import { Form } from "react-bootstrap";
import Spinner from '../../app/shared/Spinner';
import { Videos } from "./video/video";
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import Project from "./Project/Project";
import Testimonial from "./Testimonial/Testimonial";
import Internship from "./Internship/Internship";

class Resume extends Component {

  constructor(props) {
    super(props);

    this.state = {
      url: "https://www.digitizedresume.com/api/user/",
      url2: "https://www.digitizedresume.com/api/admin/",
      url3: "https://digitizedresume.com/dashboard/CV/updateProfileImage.php",
      domain: "",
      name: "",
      phone: "",
      email: "",
      password: "",
      description: "",
      designation: "",
      availability: "",
      location: "",
      workInfo: "",
      profileImage: "",
      currentProfileImage: "",
      facebook: "",
      instagram: "",
      twitter: "",
      linkedIn: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      dob: "",
      declaration: "",
      maritalStatus: "",
      objective: "",
      dataStatus: "false",
      address: "",
      receiveEmail: "",
      themeType: "",
      onlineCVStatus: "",
      paymentID: "",
      paymentDate: "",
      couponEligibility: 'false',
      showHide: false,
      price: 500,
    };
    this.setState({ currentProfileImage: ProfilePic });
  }

  componentDidMount() {
    if (!sessionStorage.getItem("id")) {
      window.location.href = "/login"
    }
    var id = sessionStorage.getItem("id");
    document.cookie = 'resumeId=' + id;
    let formData = new FormData();
    formData.append("id", id);
    // console.log(obj)
    var url = this.state.url + "getData.php";
    Axios.post(url, formData).then((res) => {
      // console.log(res.data);
      this.setState({
        name: res.data[0].name,
        phone: res.data[0].phone,
        email: res.data[0].email,
        domain: res.data[0].domain,
        designation: res.data[0].designation,
        location: res.data[0].location,
        availability: res.data[0].availability,
        profileImage: res.data[0].profileImage,
        facebook: res.data[0].facebook,
        instagram: res.data[0].instagram,
        twitter: res.data[0].twitter,
        linkedIn: res.data[0].linkedIn,
        description: res.data[0].description,
        workInfo: res.data[0].workInfo,
        dob: res.data[0].dob,
        maritalStatus: res.data[0].maritalStatus,
        objective: res.data[0].objective,
        declaration: res.data[0].declaration,
        themeType: res.data[0].themeType,
        address: res.data[0].address,
        receiveEmail: res.data[0].receiveEmail,
        onlineCVStatus: res.data[0].onlineCVStatus,
        dataStatus: "true",
      });
      if(res.data[0].address === "null" || res.data[0].address === null){
        this.setState({
          address: ''
        })
      }
      if(res.data[0].objective === "null" || res.data[0].objective === null){
        this.setState({
          objective: ''
        })
      }
      if(res.data[0].declaration === "null" || res.data[0].declaration === null){
        this.setState({
          declaration: ''
        })
      }
      if(res.data[0].availability === "null" || res.data[0].availability === null){
        this.setState({
          availability: ''
        })
      }
      if(res.data[0].maritalStatus === "null" || res.data[0].maritalStatus === null){
        this.setState({
          maritalStatus: ''
        })
      }
    });
  }

  profile = () => {
    if (document.getElementById("profilesection").style.display === "none") {
      document.getElementById("profilesection").style.display = "block";
      document.getElementById("socialaccount").style.display = "none";
      document.getElementById("password").style.display = "none";

      document
        .getElementById("profileborder")
        .classList.add("activeResumeLeftMenu");
      document
        .getElementById("socialborder")
        .classList.remove("activeResumeLeftMenu");
      document
        .getElementById("passwordborder")
        .classList.remove("activeResumeLeftMenu");
    }
  };
  socialaccount = () => {
    if (document.getElementById("socialaccount").style.display === "none") {
      document.getElementById("socialaccount").style.display = "block";
      document.getElementById("profilesection").style.display = "none";
      document.getElementById("password").style.display = "none";

      document
        .getElementById("profileborder")
        .classList.remove("activeResumeLeftMenu");
      document
        .getElementById("socialborder")
        .classList.add("activeResumeLeftMenu");
      document
        .getElementById("passwordborder")
        .classList.remove("activeResumeLeftMenu");
    }
  };
  password = () => {
    if (document.getElementById("password").style.display === "none") {
      document.getElementById("password").style.display = "block";
      document.getElementById("profilesection").style.display = "none";
      document.getElementById("socialaccount").style.display = "none";

      document
        .getElementById("profileborder")
        .classList.remove("activeResumeLeftMenu");
      document
        .getElementById("socialborder")
        .classList.remove("activeResumeLeftMenu");
      document
        .getElementById("passwordborder")
        .classList.add("activeResumeLeftMenu");
    }
  };

  handle = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value !== "") {
      document.getElementById("error" + e.target.name).innerHTML = "";
    }
  };

  submitProfile = (e) => {
    e.preventDefault();
    var check = 0;

    var normalTextRegEx = /^[a-zA-Z0-9]+([ a-zA-Z0-9])+/;
    var mobileNumberRegEx = /^[6789][0-9]{9}$/;
    var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      this.state.name !== null ||
      this.state.name !== "null" ||
      this.state.name !== ""
    ) {
      if (document.getElementById("name").value.match(normalTextRegEx)) {
        document.getElementById("errorname").innerHTML = "";
      } else {
        check = check + 1;
        document.getElementById("errorname").innerHTML =
          "Please enter a valid name";
      }
    } else {
      check = check + 1;
      document.getElementById("errorname").innerHTML =
        "This field is required. Please enter name";
    }

    if (
      this.state.dob !== null ||
      this.state.dob !== "null" ||
      this.state.dob !== ""
    ) {
      if (document.getElementById("dob").value.match(normalTextRegEx)) {
        document.getElementById("errordob").innerHTML = "";
      } else {
        check = check + 1;
        document.getElementById("errordob").innerHTML =
          "Please enter a valid date of birth";
      }
    } else {
      check = check + 1;
      document.getElementById("errorname").innerHTML =
        "This field is required. Please enter name";
    }

    if (
      this.state.phone !== null ||
      this.state.phone !== "null" ||
      this.state.phone !== ""
    ) {
      if (document.getElementById("phone").value.match(mobileNumberRegEx)) {
        document.getElementById("errorphone").innerHTML = "";
      } else {
        check = check + 1;
        document.getElementById("errorphone").innerHTML =
          "Please enter a valid 10 digit number";
      }
    } else {
      check = check + 1;
      document.getElementById("errorphone").innerHTML =
        "This field is required. Please enter number.";
    }

    if (
      this.state.email !== null ||
      this.state.email !== "null" ||
      this.state.email !== ""
    ) {
      if (document.getElementById("email").value.match(emailRegEx)) {
        document.getElementById("erroremail").innerHTML = "";
      } else {
        check = check + 1;
        document.getElementById("erroremail").innerHTML =
          "Please enter a valid email";
      }
    } else {
      check = check + 1;
      document.getElementById("erroremail").innerHTML =
        "This field is required. Please enter email.";
    }

    if (
      this.state.designation !== null ||
      this.state.designation !== "null" ||
      this.state.designation !== ""
    ) {
      if (document.getElementById("designation").value.match(normalTextRegEx)) {
        document.getElementById("errordesignation").innerHTML = "";
      } else {
        check = check + 1;
        document.getElementById("errordesignation").innerHTML =
          "Please enter a valid designation";
      }
    } else {
      check = check + 1;
      document.getElementById("errordesignation").innerHTML =
        "This field is required. Please enter designation";
    }

    if (
      this.state.location !== null ||
      this.state.location !== "null" ||
      this.state.location !== ""
    ) {
      if (document.getElementById("location").value.match(normalTextRegEx)) {
        document.getElementById("errorlocation").innerHTML = "";
      } else {
        check = check + 1;
        document.getElementById("errorlocation").innerHTML =
          "Please enter a valid location";
      }
    } else {
      check = check + 1;
      document.getElementById("errorlocation").innerHTML =
        "This field is required. Please enter location";
    }

    if (
      this.state.workInfo !== null ||
      this.state.workInfo !== "null" ||
      this.state.workInfo !== ""
    ) {
      document.getElementById("errorworkInfo").innerHTML = "";

    } else {
      check = check + 1;
      document.getElementById("errorworkInfo").innerHTML =
        "This field is required. Please enter work info";
    }

    if (
      this.state.description !== null ||
      this.state.description !== "null" ||
      this.state.description !== ""
    ) {
      document.getElementById("errordescription").innerHTML = "";

    } else {
      check = check + 1;
      document.getElementById("errordescription").innerHTML =
        "This field is required. Please enter description";
    }

    if (
      this.state.availability !== null ||
      this.state.availability !== "null" ||
      this.state.availability !== ""
    ) {
      document.getElementById("erroravailability").innerHTML = "";
    } else {
      check = check + 1;
      document.getElementById("erroravailability").innerHTML =
        "This field is required. Please select availability";
    }

    if (check === 0) {
      document.getElementsByClassName("fa-spin")[0].classList.add("fa-refresh");
      document.getElementById("dashboardSaveButton").disabled = true;
      var id = sessionStorage.getItem("id");
      let formData = new FormData();
      formData.append("id", id);
      formData.append("name", this.state.name);
      formData.append("email", this.state.email);
      formData.append("phone", this.state.phone);
      formData.append("designation", this.state.designation);
      formData.append("availability", this.state.availability);
      formData.append("location", this.state.location);
      formData.append("workInfo", this.state.workInfo);
      formData.append("description", this.state.description);
      formData.append("dob", this.state.dob);
      formData.append("maritalStatus", this.state.maritalStatus);
      formData.append("objective", this.state.objective);
      formData.append("declaration", this.state.declaration);
      formData.append("themeType", this.state.themeType);
      formData.append("address", this.state.address);
      formData.append("receiveEmail", this.state.receiveEmail);
      formData.append(
        "profileImage",
        document.getElementById("profileImage").files[0]
      );
      // console.log(obj)
      var url = this.state.url + "updateProfile.php";
      Axios.post(url, formData).then((res) => {
        // alert(res.data);
        document.getElementsByClassName("fa-spin")[0].classList.remove("fa-refresh");
        document.getElementById("dashboardSaveButton").disabled = false;
        swal({
          title: "Updated!",
          text: "Data updated successfully!",
          button: "Close",
          icon: "success",
          timer: 3000,
          button: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true
            }
          }
        });
      });

      var profileUpdateURL = this.state.url3;
      let profileData = new FormData();
      profileData.append("profileImage", document.getElementById("profileImage").files[0]);
      Axios.post(profileUpdateURL, profileData).then((res) => {
        console.log(res.data);
      })
    }
  };

  submitSocialMedia = (e) => {
    e.preventDefault();
    document.getElementsByClassName("fa-spin")[1].classList.add("fa-refresh");
    document.getElementById("socialAccountSaveButton").disabled = true;
    var id = sessionStorage.getItem("id");
    let formData = new FormData();
    formData.append("id", id);
    formData.append("facebook", this.state.facebook);
    formData.append("instagram", this.state.instagram);
    formData.append("twitter", this.state.twitter);
    formData.append("linkedIn", this.state.linkedIn);

    // console.log(obj)
    var url = this.state.url + "socialMedia.php";
    Axios.post(url, formData).then((res) => {
      // alert(res.data);
      document.getElementsByClassName("fa-spin")[1].classList.remove("fa-refresh");
      document.getElementById("socialAccountSaveButton").disabled = false;
      swal({
        title: "Updated!",
        text: "Data updated successfully!",
        button: "Close",
        icon: "success",
        timer: 3000,
        button: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      });
    });
  };

  submitPassword = (e) => {
    e.preventDefault();
    var id = sessionStorage.getItem("id");
    document.getElementsByClassName("fa-spin")[2].classList.add("fa-refresh");
    document.getElementById("socialAccountSaveButton").disabled = true;

    if (document.getElementById("oldPassword").value === "" || document.getElementById("newPassword").value === "" || document.getElementById("confirmPassword").value === "") {
      document.getElementsByClassName("fa-spin")[2].classList.remove("fa-refresh");
      document.getElementById("changePasswordSaveButton").disabled = false;
      swal({
        title: "Error!",
        text: "Please fill the form to proceed further!",
        button: "Close",
        icon: "error",
        timer: 3000,
        button: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      });
      return;
    }

    if (
      document.getElementById("newPassword").value !==
      document.getElementById("confirmPassword").value
    ) {
      document.getElementsByClassName("fa-spin")[2].classList.remove("fa-refresh");
      document.getElementById("socialAccountSaveButton").disabled = false;
      swal({
        title: "Error!",
        text: "Both passwords are not same!",
        button: "Close",
        icon: "error",
        timer: 3000,
        button: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      });
    } else if (
      document.getElementById("newPassword").value ===
      document.getElementById("confirmPassword").value
    ) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("password", this.state.oldPassword);
      formData.append("newPassword", this.state.newPassword);

      // console.log(obj)
      var url = this.state.url + "updatePassword.php";
      Axios.post(url, formData).then((res) => {
        // alert(res.data);
        if (res.data === 1) {
          document.getElementById("passwordForm").reset();
          document.getElementsByClassName("fa-spin")[2].classList.remove("fa-refresh");
          document.getElementById("socialAccountSaveButton").disabled = false;
          swal({
            title: "Updated!",
            text: "Password updated successfully!",
            button: "Close",
            icon: "success",
            timer: 3000,
            button: {
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "",
                closeModal: true
              }
            }
          });
        } else {
          document.getElementsByClassName("fa-spin")[2].classList.remove("fa-refresh");
          document.getElementById("socialAccountSaveButton").disabled = false;
          swal({
            title: "Error!",
            text: "Please enter correct current password!",
            button: "Close",
            icon: "error",
            timer: 3000,
            button: {
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "",
                closeModal: true
              }
            }
          });
        }
      });
    }
  };

  mainpage = () => {
    if (document.getElementById("mainpage").style.display === "none") {
      document.getElementById("mainpage").style.display = "block";
      document.getElementById("education").style.display = "none";
      document.getElementById("exprience").style.display = "none";
      document.getElementById("portfolio").style.display = "none";
      document.getElementById("Certificate").style.display = "none";
      document.getElementById("skill").style.display = "none";
      document.getElementById("language").style.display = "none";
      document.getElementById("interest").style.display = "none";
      document.getElementById("uploadResume").style.display = "none";
      document.getElementById("Videos").style.display = "none";
      document.getElementById("Project").style.display = "none";
      document.getElementById("Testimonial").style.display = "none";
      document.getElementById("Internship").style.display = "none";


      document.getElementById("tabNav1").classList.add("active");
      document.getElementById("tabNav2").classList.remove("active");
      document.getElementById("tabNav3").classList.remove("active");
      document.getElementById("tabNav4").classList.remove("active");
      document.getElementById("tabNav5").classList.remove("active");
      document.getElementById("tabNav6").classList.remove("active");
      document.getElementById("tabNav7").classList.remove("active");
      document.getElementById("tabNav8").classList.remove("active");
      document.getElementById("tabNav9").classList.remove("active");
      document.getElementById("tabNav10").classList.remove("active");
      document.getElementById("tabNav11").classList.remove("active");
      document.getElementById("tabNav12").classList.remove("active");
      document.getElementById("tabNav13").classList.remove("active");
    }
  }

  education = () => {
    if (document.getElementById("education").style.display === "none") {
      document.getElementById("education").style.display = "block";
      document.getElementById("mainpage").style.display = "none";
      document.getElementById("exprience").style.display = "none";
      document.getElementById("portfolio").style.display = "none";
      document.getElementById("Certificate").style.display = "none";
      document.getElementById("skill").style.display = "none";
      document.getElementById("language").style.display = "none";
      document.getElementById("interest").style.display = "none";
      document.getElementById("uploadResume").style.display = "none";
      document.getElementById("Videos").style.display = "none";
      document.getElementById("Project").style.display = "none";
      document.getElementById("Testimonial").style.display = "none";
      document.getElementById("Internship").style.display = "none";


      document.getElementById("tabNav1").classList.remove("active");
      document.getElementById("tabNav2").classList.add("active");
      document.getElementById("tabNav3").classList.remove("active");
      document.getElementById("tabNav4").classList.remove("active");
      document.getElementById("tabNav5").classList.remove("active");
      document.getElementById("tabNav6").classList.remove("active");
      document.getElementById("tabNav7").classList.remove("active");
      document.getElementById("tabNav8").classList.remove("active");
      document.getElementById("tabNav9").classList.remove("active");
      document.getElementById("tabNav10").classList.remove("active");
      document.getElementById("tabNav11").classList.remove("active");
      document.getElementById("tabNav12").classList.remove("active");
      document.getElementById("tabNav13").classList.remove("active");
    }
  }

  exprience = () => {
    if (document.getElementById("exprience").style.display === "none") {
      document.getElementById("exprience").style.display = "block";
      document.getElementById("mainpage").style.display = "none";
      document.getElementById("education").style.display = "none";
      document.getElementById("portfolio").style.display = "none";
      document.getElementById("Certificate").style.display = "none";
      document.getElementById("skill").style.display = "none";
      document.getElementById("language").style.display = "none";
      document.getElementById("interest").style.display = "none";
      document.getElementById("uploadResume").style.display = "none";
      document.getElementById("Videos").style.display = "none";
      document.getElementById("Project").style.display = "none";
      document.getElementById("Testimonial").style.display = "none";
      document.getElementById("Internship").style.display = "none";


      document.getElementById("tabNav1").classList.remove("active");
      document.getElementById("tabNav2").classList.remove("active");
      document.getElementById("tabNav3").classList.add("active");
      document.getElementById("tabNav4").classList.remove("active");
      document.getElementById("tabNav5").classList.remove("active");
      document.getElementById("tabNav6").classList.remove("active");
      document.getElementById("tabNav7").classList.remove("active");
      document.getElementById("tabNav8").classList.remove("active");
      document.getElementById("tabNav9").classList.remove("active");
      document.getElementById("tabNav10").classList.remove("active");
      document.getElementById("tabNav11").classList.remove("active");
      document.getElementById("tabNav12").classList.remove("active");
      document.getElementById("tabNav13").classList.remove("active");
    }
  }

  portfolio = () => {
    if (document.getElementById("portfolio").style.display === "none") {
      document.getElementById("portfolio").style.display = "block";
      document.getElementById("mainpage").style.display = "none";
      document.getElementById("education").style.display = "none";
      document.getElementById("exprience").style.display = "none";
      document.getElementById("Certificate").style.display = "none";
      document.getElementById("skill").style.display = "none";
      document.getElementById("language").style.display = "none";
      document.getElementById("interest").style.display = "none";
      document.getElementById("uploadResume").style.display = "none";
      document.getElementById("Videos").style.display = "none";
      document.getElementById("Project").style.display = "none";
      document.getElementById("Testimonial").style.display = "none";
      document.getElementById("Internship").style.display = "none";


      document.getElementById("tabNav1").classList.remove("active");
      document.getElementById("tabNav2").classList.remove("active");
      document.getElementById("tabNav3").classList.remove("active");
      document.getElementById("tabNav4").classList.add("active");
      document.getElementById("tabNav5").classList.remove("active");
      document.getElementById("tabNav6").classList.remove("active");
      document.getElementById("tabNav7").classList.remove("active");
      document.getElementById("tabNav8").classList.remove("active");
      document.getElementById("tabNav9").classList.remove("active");
      document.getElementById("tabNav10").classList.remove("active");
      document.getElementById("tabNav11").classList.remove("active");
      document.getElementById("tabNav12").classList.remove("active");
      document.getElementById("tabNav13").classList.remove("active");
    }
  }

  Certificate = () => {
    if (document.getElementById("Certificate").style.display === "none") {
      document.getElementById("Certificate").style.display = "block";
      document.getElementById("mainpage").style.display = "none";
      document.getElementById("education").style.display = "none";
      document.getElementById("exprience").style.display = "none";
      document.getElementById("portfolio").style.display = "none";
      document.getElementById("skill").style.display = "none";
      document.getElementById("language").style.display = "none";
      document.getElementById("interest").style.display = "none";
      document.getElementById("uploadResume").style.display = "none";
      document.getElementById("Videos").style.display = "none";
      document.getElementById("Project").style.display = "none";
      document.getElementById("Testimonial").style.display = "none";
      document.getElementById("Internship").style.display = "none";


      document.getElementById("tabNav1").classList.remove("active");
      document.getElementById("tabNav2").classList.remove("active");
      document.getElementById("tabNav3").classList.remove("active");
      document.getElementById("tabNav4").classList.remove("active");
      document.getElementById("tabNav5").classList.add("active");
      document.getElementById("tabNav6").classList.remove("active");
      document.getElementById("tabNav7").classList.remove("active");
      document.getElementById("tabNav8").classList.remove("active");
      document.getElementById("tabNav9").classList.remove("active");
      document.getElementById("tabNav10").classList.remove("active");
      document.getElementById("tabNav11").classList.remove("active");
      document.getElementById("tabNav12").classList.remove("active");
      document.getElementById("tabNav13").classList.remove("active");
    }
  }

  Video = () => {
    if (document.getElementById("Videos").style.display === "none") {
      document.getElementById("Videos").style.display = "block";
      document.getElementById("mainpage").style.display = "none";
      document.getElementById("education").style.display = "none";
      document.getElementById("exprience").style.display = "none";
      document.getElementById("portfolio").style.display = "none";
      document.getElementById("Certificate").style.display = "none";
      document.getElementById("skill").style.display = "none";
      document.getElementById("language").style.display = "none";
      document.getElementById("interest").style.display = "none";
      document.getElementById("uploadResume").style.display = "none";
      document.getElementById("Project").style.display = "none";
      document.getElementById("Testimonial").style.display = "none";
      document.getElementById("Internship").style.display = "none";


      document.getElementById("tabNav1").classList.remove("active");
      document.getElementById("tabNav2").classList.remove("active");
      document.getElementById("tabNav3").classList.remove("active");
      document.getElementById("tabNav4").classList.remove("active");
      document.getElementById("tabNav5").classList.remove("active");
      document.getElementById("tabNav6").classList.add("active");
      document.getElementById("tabNav7").classList.remove("active");
      document.getElementById("tabNav8").classList.remove("active");
      document.getElementById("tabNav9").classList.remove("active");
      document.getElementById("tabNav10").classList.remove("active");
      document.getElementById("tabNav11").classList.remove("active");
      document.getElementById("tabNav12").classList.remove("active");
      document.getElementById("tabNav13").classList.remove("active");
    }
  }

  skill = () => {
    if (document.getElementById("skill").style.display === "none") {
      document.getElementById("skill").style.display = "block";
      document.getElementById("mainpage").style.display = "none";
      document.getElementById("education").style.display = "none";
      document.getElementById("exprience").style.display = "none";
      document.getElementById("portfolio").style.display = "none";
      document.getElementById("Certificate").style.display = "none";
      document.getElementById("language").style.display = "none";
      document.getElementById("interest").style.display = "none";
      document.getElementById("uploadResume").style.display = "none";
      document.getElementById("Videos").style.display = "none";
      document.getElementById("Project").style.display = "none";
      document.getElementById("Testimonial").style.display = "none";
      document.getElementById("Internship").style.display = "none";

      document.getElementById("tabNav1").classList.remove("active");
      document.getElementById("tabNav2").classList.remove("active");
      document.getElementById("tabNav3").classList.remove("active");
      document.getElementById("tabNav4").classList.remove("active");
      document.getElementById("tabNav5").classList.remove("active");
      document.getElementById("tabNav6").classList.remove("active");
      document.getElementById("tabNav7").classList.add("active");
      document.getElementById("tabNav8").classList.remove("active");
      document.getElementById("tabNav9").classList.remove("active");
      document.getElementById("tabNav10").classList.remove("active");
      document.getElementById("tabNav11").classList.remove("active");
      document.getElementById("tabNav12").classList.remove("active");
      document.getElementById("tabNav13").classList.remove("active");
    }
  }

  language = () => {
    if (document.getElementById("language").style.display === "none") {
      document.getElementById("language").style.display = "block";
      document.getElementById("skill").style.display = "none";
      document.getElementById("mainpage").style.display = "none";
      document.getElementById("education").style.display = "none";
      document.getElementById("exprience").style.display = "none";
      document.getElementById("portfolio").style.display = "none";
      document.getElementById("Certificate").style.display = "none";
      document.getElementById("interest").style.display = "none";
      document.getElementById("uploadResume").style.display = "none";
      document.getElementById("Videos").style.display = "none";
      document.getElementById("Project").style.display = "none";
      document.getElementById("Testimonial").style.display = "none";
      document.getElementById("Internship").style.display = "none";

      document.getElementById("tabNav1").classList.remove("active");
      document.getElementById("tabNav2").classList.remove("active");
      document.getElementById("tabNav3").classList.remove("active");
      document.getElementById("tabNav4").classList.remove("active");
      document.getElementById("tabNav5").classList.remove("active");
      document.getElementById("tabNav6").classList.remove("active");
      document.getElementById("tabNav7").classList.remove("active");
      document.getElementById("tabNav8").classList.add("active");
      document.getElementById("tabNav9").classList.remove("active");
      document.getElementById("tabNav10").classList.remove("active");
      document.getElementById("tabNav11").classList.remove("active");
      document.getElementById("tabNav12").classList.remove("active");
      document.getElementById("tabNav13").classList.remove("active");
    }
  }

  interest = () => {
    if (document.getElementById("interest").style.display === "none") {
      document.getElementById("interest").style.display = "block";
      document.getElementById("language").style.display = "none";
      document.getElementById("skill").style.display = "none";
      document.getElementById("mainpage").style.display = "none";
      document.getElementById("education").style.display = "none";
      document.getElementById("exprience").style.display = "none";
      document.getElementById("portfolio").style.display = "none";
      document.getElementById("Certificate").style.display = "none";
      document.getElementById("uploadResume").style.display = "none";
      document.getElementById("Videos").style.display = "none";
      document.getElementById("Project").style.display = "none";
      document.getElementById("Testimonial").style.display = "none";
      document.getElementById("Internship").style.display = "none";

      document.getElementById("tabNav1").classList.remove("active");
      document.getElementById("tabNav2").classList.remove("active");
      document.getElementById("tabNav3").classList.remove("active");
      document.getElementById("tabNav4").classList.remove("active");
      document.getElementById("tabNav5").classList.remove("active");
      document.getElementById("tabNav6").classList.remove("active");
      document.getElementById("tabNav7").classList.remove("active");
      document.getElementById("tabNav8").classList.remove("active");
      document.getElementById("tabNav9").classList.add("active");
      document.getElementById("tabNav10").classList.remove("active");
      document.getElementById("tabNav11").classList.remove("active");
      document.getElementById("tabNav12").classList.remove("active");
      document.getElementById("tabNav13").classList.remove("active");
    }
  }

  uploadResume = () => {
    if (document.getElementById("uploadResume").style.display === "none") {
      document.getElementById("uploadResume").style.display = "block";
      document.getElementById("interest").style.display = "none";
      document.getElementById("language").style.display = "none";
      document.getElementById("skill").style.display = "none";
      document.getElementById("mainpage").style.display = "none";
      document.getElementById("education").style.display = "none";
      document.getElementById("exprience").style.display = "none";
      document.getElementById("portfolio").style.display = "none";
      document.getElementById("Certificate").style.display = "none";
      document.getElementById("Videos").style.display = "none";
      document.getElementById("Project").style.display = "none";
      document.getElementById("Testimonial").style.display = "none";
      document.getElementById("Internship").style.display = "none";

      document.getElementById("tabNav1").classList.remove("active");
      document.getElementById("tabNav2").classList.remove("active");
      document.getElementById("tabNav3").classList.remove("active");
      document.getElementById("tabNav4").classList.remove("active");
      document.getElementById("tabNav5").classList.remove("active");
      document.getElementById("tabNav6").classList.remove("active");
      document.getElementById("tabNav7").classList.remove("active");
      document.getElementById("tabNav8").classList.remove("active");
      document.getElementById("tabNav9").classList.remove("active");
      document.getElementById("tabNav10").classList.add("active");
      document.getElementById("tabNav11").classList.remove("active");
      document.getElementById("tabNav12").classList.remove("active");
      document.getElementById("tabNav13").classList.remove("active");
    }
  }

  internship = () => {
    if (document.getElementById("Internship").style.display === "none") {
      document.getElementById("Internship").style.display = "block";
      document.getElementById("uploadResume").style.display = "none";
      document.getElementById("interest").style.display = "none";
      document.getElementById("language").style.display = "none";
      document.getElementById("skill").style.display = "none";
      document.getElementById("mainpage").style.display = "none";
      document.getElementById("education").style.display = "none";
      document.getElementById("exprience").style.display = "none";
      document.getElementById("portfolio").style.display = "none";
      document.getElementById("Certificate").style.display = "none";
      document.getElementById("Videos").style.display = "none";
      document.getElementById("Testimonial").style.display = "none";
      document.getElementById("Project").style.display = "none";

      document.getElementById("tabNav1").classList.remove("active");
      document.getElementById("tabNav2").classList.remove("active");
      document.getElementById("tabNav3").classList.remove("active");
      document.getElementById("tabNav4").classList.remove("active");
      document.getElementById("tabNav5").classList.remove("active");
      document.getElementById("tabNav6").classList.remove("active");
      document.getElementById("tabNav7").classList.remove("active");
      document.getElementById("tabNav8").classList.remove("active");
      document.getElementById("tabNav9").classList.remove("active");
      document.getElementById("tabNav10").classList.remove("active");
      document.getElementById("tabNav11").classList.add("active");
      document.getElementById("tabNav12").classList.remove("active");
      document.getElementById("tabNav13").classList.remove("active");
    }
  }

  testimonial = () => {
    if (document.getElementById("Testimonial").style.display === "none") {
      document.getElementById("Testimonial").style.display = "block";
      document.getElementById("uploadResume").style.display = "none";
      document.getElementById("interest").style.display = "none";
      document.getElementById("language").style.display = "none";
      document.getElementById("skill").style.display = "none";
      document.getElementById("mainpage").style.display = "none";
      document.getElementById("education").style.display = "none";
      document.getElementById("exprience").style.display = "none";
      document.getElementById("portfolio").style.display = "none";
      document.getElementById("Certificate").style.display = "none";
      document.getElementById("Videos").style.display = "none";
      document.getElementById("Internship").style.display = "none";
      document.getElementById("Project").style.display = "none";

      document.getElementById("tabNav1").classList.remove("active");
      document.getElementById("tabNav2").classList.remove("active");
      document.getElementById("tabNav3").classList.remove("active");
      document.getElementById("tabNav4").classList.remove("active");
      document.getElementById("tabNav5").classList.remove("active");
      document.getElementById("tabNav6").classList.remove("active");
      document.getElementById("tabNav7").classList.remove("active");
      document.getElementById("tabNav8").classList.remove("active");
      document.getElementById("tabNav9").classList.remove("active");
      document.getElementById("tabNav10").classList.remove("active");
      document.getElementById("tabNav11").classList.remove("active");
      document.getElementById("tabNav12").classList.add("active");
      document.getElementById("tabNav13").classList.remove("active");
    }
  }

  project = () => {
    if (document.getElementById("Project").style.display === "none") {
      document.getElementById("Project").style.display = "block";
      document.getElementById("uploadResume").style.display = "none";
      document.getElementById("interest").style.display = "none";
      document.getElementById("language").style.display = "none";
      document.getElementById("skill").style.display = "none";
      document.getElementById("mainpage").style.display = "none";
      document.getElementById("education").style.display = "none";
      document.getElementById("exprience").style.display = "none";
      document.getElementById("portfolio").style.display = "none";
      document.getElementById("Certificate").style.display = "none";
      document.getElementById("Videos").style.display = "none";
      document.getElementById("Testimonial").style.display = "none";
      document.getElementById("Internship").style.display = "none";

      document.getElementById("tabNav1").classList.remove("active");
      document.getElementById("tabNav2").classList.remove("active");
      document.getElementById("tabNav3").classList.remove("active");
      document.getElementById("tabNav4").classList.remove("active");
      document.getElementById("tabNav5").classList.remove("active");
      document.getElementById("tabNav6").classList.remove("active");
      document.getElementById("tabNav7").classList.remove("active");
      document.getElementById("tabNav8").classList.remove("active");
      document.getElementById("tabNav9").classList.remove("active");
      document.getElementById("tabNav10").classList.remove("active");
      document.getElementById("tabNav11").classList.remove("active");
      document.getElementById("tabNav12").classList.remove("active");
      document.getElementById("tabNav13").classList.add("active");
    }
  }

  previewCV = (e) => {
    e.preventDefault();
    document.cookie = "availableResumeId=" + sessionStorage.getItem("id");
    window.open("https://dashboard.digitizedresume.com/preview_online_CV/", "_blank");
  }

  activateCV = (e) => {
    e.preventDefault();
    this.setState({
      showHide: !this.state.showHide
    })
    var price = this.state.price;
    console.log(price);
    if (this.state.couponEligibility === 'false') {
      var options = {
        "key": "rzp_live_xvK1A7IOgix2Hs", // Enter the Key ID generated from the Dashboard
        "amount": price * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": "DigitizedResume",
        "description": "Easy to Create Your Digitized Resume - Digitized Resume",
        "image": "https://digitizedresume.com/static/media/logo.42d37883.svg",
        // "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "handler": function (response) {
          // alert(response.razorpay_payment_id);
          // var paymentID = response.razorpay_payment_id;
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();

          today = dd + "-" + mm + "-" + yyyy;
          this.setState({
            paymentID: response.razorpay_payment_id,
            paymentDate: today,
            onlineCVStatus: "Active"
          })

          let formData = new FormData();
          formData.append("paymentID", response.razorpay_payment_id);
          formData.append("date", today);
          formData.append("onlineCVStatus", "Active");
          formData.append("amount", price);
          formData.append("userId", sessionStorage.getItem("id"));
          const url = this.state.url + "paymentStatus.php";
          Axios.post(url, formData)
            .then((res) => {
              // console.log(res.data);
              document.getElementById("messageAlert").innerHTML = "Payment Successfully Done";
              document.getElementById("messageAlert").style.display = "block";
              document.getElementById("messageAlert").classList.add("messageSuccess");
              setTimeout(function () {
                document.getElementById("messageAlert").style.display = "none";
              }, 1600);
            })
            .catch((err) => {
              console.log(err);
            });
          let couponData = new FormData();
          couponData.append("couponId", currentCouponNumber);
          couponData.append("userId", sessionStorage.getItem("id"));
          const couponUrl = this.state.url + "couponStatus.php";
          Axios.post(couponUrl, couponData)
            .then((res) => {
              // console.log(res.data);
              document.getElementById("messageAlert").innerHTML = "Your CV is Activated.";
              document.getElementById("messageAlert").style.display = "block";
              document.getElementById("messageAlert").classList.add("messageSuccess");
              setTimeout(function () {
                document.getElementById("messageAlert").style.display = "none";
              }, 1600);
            })
            .catch((err) => {
              console.log(err);
            });
        }.bind(this),
        "prefill": {
          "name": this.state.name,
          "email": this.state.email,
          "contact": this.state.phone
        },
        "notes": {
          "address": "Banglore, India"
        },
        "theme": {
          "color": "#3399cc"
        }
      };
      let rzp1 = new window.Razorpay(options);
      rzp1.on('payment.failed', function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
        alert(response.error.description);
      });

      rzp1.open();
      e.preventDefault();
    }
    else if (this.state.couponEligibility === 'true' && price === 0) {
      this.setState({
        onlineCVStatus: "Active"
      })
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = dd + "-" + mm + "-" + yyyy;
      var currentCouponNumber = document.getElementById("coupon").value;
      let payData = new FormData();
      payData.append("onlineCVStatus", "Active");
      payData.append("date", today);
      payData.append("amount", price);
      payData.append("userId", sessionStorage.getItem("id"));
      const url = this.state.url + "paymentStatus.php";
      Axios.post(url, payData)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      let couponData = new FormData();
      couponData.append("couponId", currentCouponNumber);
      couponData.append("userId", sessionStorage.getItem("id"));
      const couponUrl = this.state.url + "couponStatus.php";
      Axios.post(couponUrl, couponData)
        .then((res) => {
          // console.log(res.data);
          document.getElementById("messageAlert").innerHTML = "Your CV is Activated.";
          document.getElementById("messageAlert").style.display = "block";
          document.getElementById("messageAlert").classList.add("messageSuccess");
          setTimeout(function () {
            document.getElementById("messageAlert").style.display = "none";
          }, 1600);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else if (this.state.couponEligibility === 'true' && price !== 0) {
      var options = {
        "key": "rzp_live_xvK1A7IOgix2Hs", // Enter the Key ID generated from the Dashboard
        "amount": price * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": "DigitizedResume",
        "description": "Easy to Create Your Digitized Resume - Digitized Resume",
        "image": "https://digitizedresume.com/static/media/logo.42d37883.svg",
        // "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "handler": function (response) {
          // alert(response.razorpay_payment_id);
          // var paymentID = response.razorpay_payment_id;
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();

          today = dd + "-" + mm + "-" + yyyy;
          this.setState({
            paymentID: response.razorpay_payment_id,
            paymentDate: today,
            onlineCVStatus: "Active"
          });
          let formData = new FormData();
          formData.append("paymentID", response.razorpay_payment_id);
          formData.append("date", today);
          formData.append("onlineCVStatus", "Active");
          formData.append("amount", price);
          formData.append("userId", sessionStorage.getItem("id"));
          const url = this.state.url + "paymentStatus.php";
          Axios.post(url, formData)
            .then((res) => {
              // console.log(res.data);
              document.getElementById("messageAlert").innerHTML = "Payment Successfully Done";
              document.getElementById("messageAlert").style.display = "block";
              document.getElementById("messageAlert").classList.add("messageSuccess");
              setTimeout(function () {
                document.getElementById("messageAlert").style.display = "none";
              }, 1600);
            })
            .catch((err) => {
              console.log(err);
            });

          let couponData = new FormData();
          couponData.append("couponId", currentCouponNumber);
          couponData.append("userId", sessionStorage.getItem("id"));
          const couponUrl = this.state.url + "couponStatus.php";
          Axios.post(couponUrl, couponData)
            .then((res) => {
              // console.log(res.data);
              document.getElementById("messageAlert").innerHTML = "Your CV is Activated.";
              document.getElementById("messageAlert").style.display = "block";
              document.getElementById("messageAlert").classList.add("messageSuccess");
              setTimeout(function () {
                document.getElementById("messageAlert").style.display = "none";
              }, 1600);
            })
            .catch((err) => {
              console.log(err);
            });
        }.bind(this),
        "prefill": {
          "name": this.state.name,
          "email": this.state.email,
          "contact": this.state.phone
        },
        "notes": {
          "address": "Banglore, India"
        },
        "theme": {
          "color": "#3399cc"
        }
      };
      let rzp1 = new window.Razorpay(options);
      rzp1.on('payment.failed', function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
        alert(response.error.description);
      });

      rzp1.open();
      e.preventDefault();
    }

  }

  handleModalShowHide() {
    this.setState({
      showHide: !this.state.showHide,
      couponEligibility: 'false',
      price: 500
    });
  }

  validateCouponEligibility = (e) => {
    e.preventDefault();
    document.getElementById("modalValidate").disabled = true;
    let couponData = new FormData();
    var currentCouponNumber = document.getElementById("coupon").value;
    couponData.append("couponId", currentCouponNumber);
    const couponUrl = this.state.url + "validateCoupon.php";
    Axios.post(couponUrl, couponData)
      .then((res) => {
        console.log(res.data);
        if (res.data === 0) {
          document.getElementById("validateError").innerHTML = "Please enter valid coupon number";
          document.getElementById("validateSuccess").innerHTML = "";
          document.getElementById("modalValidate").disabled = false;
          document.getElementById("coupon").disabled = false;
          document.getElementById("modalSkip").disabled = false;
          document.getElementById("modalValidate").style.display = "block";
          document.getElementById("modalActivate").style.display = "none";
        }
        else {
          document.getElementById("validateError").innerHTML = "";
          document.getElementById("validateSuccess").innerHTML = "Coupon validated successfully, please activate CV by clicking below button!";
          document.getElementById("coupon").disabled = true;
          document.getElementById("modalSkip").disabled = true;
          document.getElementById("modalValidate").style.display = "none";
          document.getElementById("modalActivate").style.display = "block";
          var currentPrice = this.state.price - res.data[0].amount;
          this.setState({
            couponEligibility: 'true',
            price: currentPrice,
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  checkCouponEligibility = (e) => {
    e.preventDefault();
    this.setState({
      showHide: !this.state.showHide
    })
  }

  readCurrentImageURL = (e) => {
    var input = document.getElementById("profileImage");
    var file = input.files[0];

    if (file) {
      var URL = window.URL || window.webkitURL;
      var src = URL.createObjectURL(document.getElementById("profileImage").files[0]);
      var current1 = document.getElementById("currentProfileImage1");
      var current2 = document.getElementById("currentProfileImage2");
      current1.src = src;
      current2.src = src;
    }
  }

  render() {
    return (
      <div>
        {this.state.dataStatus === "false" ? (
          <Spinner />
        ) : (

          <div className="row page-title-header">
            <div className="col-12">
              <div className="page-header">
                <h4 className="resume-title">Manage Your Account</h4>
              </div>
            </div>

            <div className="col-md-4 account_detail">
              <div className="user_info bg-white">
                <div className="profile_image text-center pt-5">
                  {(() => {
                    if (this.state.profileImage === null) {
                      return <img src={pic} id="currentProfileImage1" alt="sample" />;
                    } else {
                      return (
                        <img
                        id="currentProfileImage1"
                          src={
                            "https://www.digitizedresume.com/api/user/assets/profile/" +
                            this.state.profileImage
                          }
                          alt={this.state.name}
                        />
                      );
                    }
                  })()}
                </div>
                <div className="profile_detail text-center">
                  <h4>{this.state.name}</h4>
                  <span className="text-dark">{this.state.designation}</span>
                  <br></br>
                  <div class="avialable">{this.state.availability}</div>
                </div>
                <hr />

                <div className="my_website">
                  <i className="mdi mdi mdi-email menu-icon pl-3"></i>
                  <a href={"mailto:" + this.state.email} className="pl-3">
                    {this.state.email}
                  </a>
                </div>

                <hr />

                <div className="address py-1">
                  <div className="row">
                    <div className="col-md-6 pl-5 col-6">
                      <span className="text-dark">City</span>
                    </div>
                    <div className="col-md-6 col-6">
                      <i className="mdi mdi-map-marker menu-icon text-danger"></i>
                      <span className="text-dark">{this.state.location}</span>
                    </div>
                  </div>
                </div>

                <hr />
                {this.state.onlineCVStatus === "Active" ?
                  <div className="my_website">
                    <i className="mdi mdi mdi-web menu-icon pl-3"></i>
                    <a target="_blank" href={"https://digitizedresume.com/" + this.state.domain} className="pl-3">
                      View CV
                    </a>
                  </div> :
                  <div className="my_website">
                    <i className="mdi mdi mdi-web menu-icon pl-3"></i>
                    <a target="blank" href="/preview-cv" className="pl-3">
                      Preview CV
                    </a><span className="px-2 text-center">|</span>
                    <a href="!#" onClick={this.checkCouponEligibility} className="">
                      Activate CV
                    </a>
                  </div>
                }
                <hr />
                <Modal show={this.state.showHide}>
                  <Modal.Header>
                    <Modal.Title>Validate Coupon</Modal.Title>
                    <span className="bg-danger closeBtn text-white px-3 py-2 cursor-pointer" onClick={() => this.handleModalShowHide()}><b>Close</b></span>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <label className="form-label">Enter Coupon:</label>
                        </div>
                        <div className="col-md-12">
                          <input type="text" className="form-control" name="coupon" id="coupon" placeholder="Enter Coupon" ></input>
                        </div>
                        <div className="col-md-12 mt-2">
                          <span className="text-dark" style={{ fontSize: '14px' }}>If you have coupon then please enter coupon number or else please skip this step.</span>
                        </div>
                        <div className="col-md-12 mt-2">
                          <span className="text-success" id="validateSuccess" style={{ fontSize: '14px' }}></span>
                          <span className="text-danger" id="validateError" style={{ fontSize: '14px' }}></span>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" id="modalSkip" onClick={this.activateCV}>
                      Skip
                    </Button>
                    <Button type="submit" onClick={this.validateCouponEligibility} id="modalValidate" variant="primary">
                      Validate
                    </Button>
                    <Button type="submit" style={{ display: "none" }} onClick={this.activateCV} id="modalActivate" variant="success">
                      Activate
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>



            <div className="col-md-8 resume__details">
              <div className="row p-2">
                <div className="account_tab">
                  <ul className="nav nav-tabs">
                    <li class="nav-item" onClick={this.mainpage}>
                      <navLink class="nav-link active" id="tabNav1">
                        Dashboard
                      </navLink>
                    </li>
                    <li class="nav-item" onClick={this.education}>
                      <navLink class="nav-link" id="tabNav2">
                        Education{" "}
                      </navLink>
                    </li>
                    <navLink>
                      {" "}
                      <li class="nav-item" onClick={this.exprience}>
                        <span class="nav-link text-dark" id="tabNav3">
                          Work Experience{" "}
                        </span>
                      </li>
                    </navLink>
                    <li class="nav-item" onClick={this.portfolio}>
                      <navLink class="nav-link" id="tabNav4">
                        Portfolio
                      </navLink>
                    </li>

                    <li class="nav-item" onClick={this.Certificate}>
                      <navLink class="nav-link" id="tabNav5">
                        Certificate &amp; Awards
                      </navLink>
                    </li>

                    <li class="nav-item" onClick={this.Video}>
                      <navLink class="nav-link" id="tabNav6">
                        Videos
                      </navLink>
                    </li>
                    <li class="nav-item" onClick={this.uploadResume}>
                      <navLink class="nav-link" id="tabNav10">
                        Resume
                      </navLink>
                    </li>
                    <li class="nav-item" onClick={this.skill}>
                      <navLink class="nav-link" id="tabNav7">
                        Skills
                      </navLink>
                    </li>
                    <li class="nav-item" onClick={this.language}>
                      <navLink class="nav-link" id="tabNav8">
                        Language
                      </navLink>
                    </li>
                    <li class="nav-item" onClick={this.interest}>
                      <navLink class="nav-link" id="tabNav9">
                        Interest
                      </navLink>
                    </li>
                    <li class="nav-item" onClick={this.internship}>
                      <navLink class="nav-link" id="tabNav11">
                        Internship
                      </navLink>
                    </li>
                    <li class="nav-item" onClick={this.testimonial}>
                      <navLink class="nav-link" id="tabNav12">
                        Testimonial/Endorsement
                      </navLink>
                    </li>
                    <li class="nav-item" onClick={this.project}>
                      <navLink class="nav-link" id="tabNav13">
                        Project
                      </navLink>
                    </li>
                  </ul>
                </div>

                <div className="col-md-12 mt-3" id="mainpage">
                  <>
                    <div className="row mt-2">
                      <div className="col-md-4 mb-4 col-12">
                        <div className="job_tab">
                          <ul class="nav job_nav">
                            <li
                              class="nav-item activeResumeLeftMenu"
                              id="profileborder"
                              onClick={this.profile}
                            >
                              <navLink class="nav-link text-dark">My Profile</navLink>
                            </li>
                            <li
                              class="nav-item"
                              id="socialborder"
                              onClick={this.socialaccount}
                            >
                              <navLink class="nav-link text-dark">Social Accounts</navLink>
                            </li>
                            <li
                              class="nav-item"
                              id="passwordborder"
                              onClick={this.password}
                            >
                              <navLink class="nav-link text-dark">Change Password</navLink>
                            </li>

                            <li
                              class="nav-item"
                            >
                              <NavLink class="nav-link text-dark" exact to="/explore_resume">Generate CV</NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-8 col-12 px-0 mx-0">
                        {/* profile form */}
                        <form onSubmit={this.submitProfile} id="profileForm">
                          <div className="profile bg-white" id="profilesection">
                            <h6 className="pl-3 profile_title">My Profile</h6>

                            <div className="profile1_image p-3">
                              <span className="text-dark">Profile Avatar</span>
                              <br></br>
                              {(() => {
                                if (this.state.profileImage === null) {
                                  return <img src={ProfilePic} id="currentProfileImage2" alt={this.state.name} />;
                                } else {
                                  return (
                                    <img
                                      id="currentProfileImage2"
                                      src={
                                        "https://www.digitizedresume.com/api/user/assets/profile/" +
                                        this.state.profileImage
                                      }
                                      alt={this.state.name}
                                    />
                                  );
                                }
                              })()}
                              <div class="form-group">
                                <input
                                  type="file"
                                  id="profileImage"
                                  className="form-control uplaod_image"
                                  onChange={this.readCurrentImageURL}
                                />
                              </div>
                            </div>

                            <div className="form-section p-3">
                              <div className="row">
                                <div className="col-md-12">
                                  <Form.Group className="row">
                                    <label className="pl-3"> Name</label>
                                    <div className="col-sm-12">
                                      <Form.Control
                                        type="text"
                                        id="name"
                                        onChange={this.handle}
                                        value={this.state.name}
                                        name="name"
                                        className="form-control-sm"
                                        placeholder="Your Name"
                                      />
                                      <p className="error" id="errorname"></p>
                                    </div>
                                  </Form.Group>
                                </div>
                                <div className="col-md-6">
                                  <Form.Group className="row">
                                    <label className="pl-3">Email Address</label>
                                    <div className="col-sm-12">
                                      <Form.Control
                                        type="email"
                                        disabled
                                        style={{ backgroundColor: "white" }}
                                        onChange={this.handle}
                                        id="email"
                                        value={this.state.email}
                                        name="email"
                                        className="form-control-sm"
                                        placeholder="Email Id"
                                      />
                                      <p className="error" id="erroremail"></p>
                                    </div>
                                  </Form.Group>
                                </div>
                                <div className="col-md-6">
                                  <Form.Group className="row">
                                    <label className="pl-3">Marital Status</label>
                                    <div className="col-sm-12">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handle}
                                        id="maritalStatus"
                                        value={this.state.maritalStatus}
                                        name="maritalStatus"
                                        className="form-control-sm"
                                        placeholder="Marital Status"
                                      />
                                      <p className="error" id="errormaritalStatus"></p>
                                    </div>
                                  </Form.Group>
                                </div>
                                <div className="col-md-6">
                                  <Form.Group className="row">
                                    <label className="pl-3">Phone</label>
                                    <div className="col-sm-12">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handle}
                                        id="phone"
                                        value={this.state.phone}
                                        name="phone"
                                        className="form-control-sm"
                                        placeholder="Your Phone"
                                      />
                                      <p className="error" id="errorphone"></p>
                                    </div>
                                  </Form.Group>
                                </div>
                                <div className="col-md-6">
                                  <Form.Group className="row">
                                    <label className="pl-3">D.O.B.</label>
                                    <div className="col-sm-12">
                                      <Form.Control
                                        type="date"
                                        onChange={this.handle}
                                        id="dob"
                                        value={this.state.dob}
                                        name="dob"
                                        className="form-control-sm"
                                        placeholder="D.O.B."
                                      />
                                      <p className="error" id="errordob"></p>
                                    </div>
                                  </Form.Group>
                                </div>

                                <div className="col-md-12">
                                  <label>Description (About)</label>
                                  {(() => {
                                    if (
                                      this.state.description === null ||
                                      this.state.description === "null" ||
                                      this.state.description === ""
                                    ) {
                                      return (
                                        <textarea
                                          className="form-control form-control-sm"
                                          placeholder="Tell us about your work. Example :- I am a full stack developer."
                                          style={{ height: "100px" }}
                                          onChange={this.handle}
                                          name="description"
                                          id="description"
                                        ></textarea>
                                      );
                                    } else {
                                      return (
                                        <textarea
                                          className="form-control form-control-sm"
                                          value={this.state.description}
                                          placeholder="Description"
                                          style={{ height: "100px" }}
                                          onChange={this.handle}
                                          name="description"
                                          id="description"
                                        ></textarea>
                                      );
                                    }
                                  })()}
                                  <p className="error" id="errordescription"></p>
                                </div>

                                <div className="col-md-12 mt-3">
                                  <Form.Group className="row">
                                    <label className="pl-3">Designation</label>
                                    {(() => {
                                      if (
                                        this.state.designation === null ||
                                        this.state.designation === "null" ||
                                        this.state.designation === ""
                                      ) {
                                        return (
                                          <div className="col-sm-12">
                                            <Form.Control
                                              type="text"
                                              onChange={this.handle}
                                              id="designation"
                                              name="designation"
                                              className="form-control-sm"
                                              placeholder="Designation"
                                            />
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div className="col-sm-12">
                                            <Form.Control
                                              type="text"
                                              onChange={this.handle}
                                              id="designation"
                                              value={this.state.designation}
                                              name="designation"
                                              className="form-control-sm"
                                              placeholder="Designation"
                                            />
                                          </div>
                                        );
                                      }
                                    })()}
                                    <p className="error" id="errordesignation"></p>
                                  </Form.Group>
                                </div>

                                <div className="col-md-6">
                                  <Form.Group>
                                    <label htmlFor="availability">Availability</label>

                                    {(() => {
                                      if (
                                        this.state.availability === "null" ||
                                        this.state.availability === null ||
                                        this.state.availability === ""
                                      ) {
                                        return (
                                          <select
                                            onChange={this.handle}
                                            className="form-control form-control-sm"
                                            id="availability"
                                            name="availability"
                                          >
                                            <option value="" disabled selected>
                                              Select Availability
                                            </option>
                                            <option value="Full Time">Full Time</option>
                                            <option value="Part Time">Part Time</option>
                                            <option value="Not Available">
                                              Not Available
                                            </option>
                                          </select>
                                        );
                                      } else if (this.state.availability === "Full Time") {
                                        return (
                                          <select
                                            onChange={this.handle}
                                            className="form-control form-control-sm"
                                            id="availability"
                                            name="availability"
                                          >
                                            <option value="" disabled>
                                              Select Availability
                                            </option>
                                            <option value="Full Time" selected>
                                              Full Time
                                            </option>
                                            <option value="Part Time">Part Time</option>
                                            <option value="Not Available">
                                              Not Available
                                            </option>
                                          </select>
                                        );
                                      } else if (this.state.availability === "Part Time") {
                                        return (
                                          <select
                                            onChange={this.handle}
                                            className="form-control form-control-sm"
                                            id="availability"
                                            name="availability"
                                          >
                                            <option value="" disabled>
                                              Select Availability
                                            </option>
                                            <option value="Full Time">Full Time</option>
                                            <option value="Part Time" selected>
                                              Part Time
                                            </option>
                                            <option value="Not Available">
                                              Not Available
                                            </option>
                                          </select>
                                        );
                                      } else if (
                                        this.state.availability === "Not Available"
                                      ) {
                                        return (
                                          <select
                                            onChange={this.handle}
                                            className="form-control form-control-sm"
                                            id="availability"
                                            name="availability"
                                          >
                                            <option value="" disabled>
                                              Select Availability
                                            </option>
                                            <option value="Full Time">Full Time</option>
                                            <option value="Part Time">Part Time</option>
                                            <option value="Not Available" selected>
                                              Not Available
                                            </option>
                                          </select>
                                        );
                                      }
                                    })()}

                                    <p className="error" id="erroravailability"></p>
                                  </Form.Group>
                                </div>
                                <div className="col-md-6">
                                  <Form.Group className="row">
                                    <label className="pl-3">City</label>
                                    {(() => {
                                      if (
                                        this.state.location === null ||
                                        this.state.location === "null" ||
                                        this.state.location === ""
                                      ) {
                                        return (
                                          <div className="col-sm-12">
                                            <Form.Control
                                              type="text"
                                              onChange={this.handle}
                                              className="form-control-sm"
                                              placeholder="Your Location"
                                              id="location"
                                              name="location"
                                            />
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div className="col-sm-12">
                                            <Form.Control
                                              type="text"
                                              onChange={this.handle}
                                              value={this.state.location}
                                              className="form-control-sm"
                                              placeholder="Your Location"
                                              id="location"
                                              name="location"
                                            />
                                          </div>
                                        );
                                      }
                                    })()}
                                    <p className="error" id="errorlocation"></p>
                                  </Form.Group>
                                </div>

                                <div className="col-md-12">
                                  <Form.Group>
                                    <label htmlFor="themeType">Theme Type</label>

                                    {(() => {
                                      if (this.state.themeType === "Light Theme") {
                                        return (
                                          <select onChange={this.handle} className="form-control form-control-sm" id="themeType" name="themeType">
                                            <option value="Light Theme" selected>Light Theme</option>
                                            <option value="Dark Theme">Dark Theme</option>
                                          </select>
                                        );
                                      } else if (this.state.themeType === "Dark Theme") {
                                        return (
                                          <select onChange={this.handle} className="form-control form-control-sm" id="themeType" name="themeType">
                                            <option value="Light Theme">Light Theme</option>
                                            <option value="Dark Theme" selected >Dark Theme</option>
                                          </select>
                                        );
                                      }
                                    })()}

                                    <p className="error" id="errorthemeType"></p>
                                  </Form.Group>
                                </div>

                                <div className="col-md-12">
                                  <Form.Group>
                                    <label htmlFor="receiveEmail">Want to Receive Email From Company & Admin</label>

                                    {(() => {
                                      if (this.state.receiveEmail === "Yes") {
                                        return (
                                          <select onChange={this.handle} className="form-control form-control-sm" id="receiveEmail" name="receiveEmail">
                                            <option value="Yes" selected>Yes</option>
                                            <option value="No">No</option>
                                          </select>
                                        );
                                      } else if (this.state.receiveEmail === "No") {
                                        return (
                                          <select onChange={this.handle} className="form-control form-control-sm" id="receiveEmail" name="receiveEmail">
                                            <option value="Yes">Yes</option>
                                            <option value="No" selected >No</option>
                                          </select>
                                        );
                                      }
                                    })()}

                                    <p className="error" id="errorreceiveEmail"></p>
                                  </Form.Group>
                                </div>

                                <div className="col-md-12">

                                  <label className="pl-3">Address</label>
                                  <textarea
                                    className="form-control form-control-sm"
                                    value={this.state.address}
                                    placeholder="Enter Your Address"
                                    style={{ height: "70px" }}
                                    onChange={this.handle}
                                    name="address"
                                    id="address"
                                  ></textarea>
                                  <p className="error" id="erroraddress"></p>

                                </div>

                                <div className="col-md-12">
                                  <label>What I Do</label>
                                  {(() => {
                                    if (
                                      this.state.workInfo === null ||
                                      this.state.workInfo === "null" ||
                                      this.state.workInfo === ""
                                    ) {
                                      return (
                                        <textarea
                                          className="form-control form-control-sm"
                                          placeholder="Enter Work Information"
                                          style={{ height: "100px" }}
                                          onChange={this.handle}
                                          name="workInfo"
                                          id="workInfo"
                                        ></textarea>
                                      );
                                    } else {
                                      return (
                                        <textarea
                                          className="form-control form-control-sm"
                                          value={this.state.workInfo}
                                          placeholder="Enter Work Information"
                                          style={{ height: "100px" }}
                                          onChange={this.handle}
                                          name="workInfo"
                                          id="workInfo"
                                        ></textarea>
                                      );
                                    }
                                  })()}
                                  <p className="errror" id="errorworkInfo"></p>
                                </div>

                                <div className="col-md-12">
                                  <label>Objective</label>
                                  <textarea
                                    className="form-control form-control-sm"
                                    value={this.state.objective}
                                    placeholder="Enter Objective For CV"
                                    style={{ height: "100px" }}
                                    onChange={this.handle}
                                    name="objective"
                                    id="objective"
                                  ></textarea>

                                  <p className="errror" id="errorobjective"></p>
                                </div>

                                <div className="col-md-12">
                                  <label>Declaration</label>
                                  <textarea
                                    className="form-control form-control-sm"
                                    value={this.state.declaration}
                                    placeholder="Enter Declaration For CV"
                                    style={{ height: "100px" }}
                                    onChange={this.handle}
                                    name="declaration"
                                    id="declaration"
                                  ></textarea>

                                  <p className="errror" id="errordeclaration"></p>
                                </div>

                                <div className="col-md-12 mt-3">
                                  <Form.Group>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          {" "}
                                          <i className="mdi mdi mdi-web menu-icon"></i>
                                        </span>
                                      </div>
                                      <Form.Control
                                        type="text"
                                        disabled
                                        style={{ backgroundColor: "white" }}
                                        value={"https://digitizedresume.com/" + this.state.domain}
                                        className="form-control"
                                        id="domain"
                                        name="domain"
                                        placeholder="http://example.com/"
                                      />
                                    </div>
                                    <p className="error" id="errordomain"></p>
                                  </Form.Group>
                                </div>

                                <div className="col-md-12">
                                  <button
                                    class="post_jp_btn"
                                    name="submit"
                                    value="Submit"
                                    type="submit"
                                    id="dashboardSaveButton"
                                  >
                                    SAVE CHANGES <i class="fa fa-spin"></i>
                                  </button>

                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                        {/* profile form */}

                        {/* social_account */}
                        <form onSubmit={this.submitSocialMedia}>
                          <div
                            className="social_account bg-white"
                            id="socialaccount"
                            style={{ display: "none" }}
                          >
                            <h6 className="social_title">Social Accounts</h6>

                            <div className="account_details p-3">
                              <div className="col-md-12 mt-2">
                                <div className="input-group">
                                  <div className="input-group-append">
                                    <button className="btn btn-sm btn-facebook">
                                      <i className="mdi mdi-facebook"></i>
                                    </button>
                                  </div>
                                  {(() => {
                                    if (this.state.facebook === "null") {
                                      return (
                                        <Form.Control
                                          type="text"
                                          onChange={this.handle}
                                          id="facebook"
                                          name="facebook"
                                          className="form-control"
                                          placeholder="https://www.facebook.com/"
                                          aria-label="Recipient's username"
                                          aria-describedby="basic-addon2"
                                        />
                                      );
                                    } else {
                                      return (
                                        <Form.Control
                                          type="text"
                                          onChange={this.handle}
                                          id="facebook"
                                          name="facebook"
                                          className="form-control"
                                          placeholder="https://www.facebook.com/"
                                          value={this.state.facebook}
                                          aria-label="Recipient's username"
                                          aria-describedby="basic-addon2"
                                        />
                                      );
                                    }
                                  })()}
                                  <p className="error" id="errorfacebook"></p>
                                </div>
                              </div>
                              <div className="col-md-12 mt-2">
                                <div className="input-group">
                                  <div className="input-group-append">
                                    <button className="btn btn-sm btn-facebook">
                                      <i className="mdi mdi-twitter"></i>
                                    </button>
                                  </div>
                                  {(() => {
                                    if (this.state.twitter === "null") {
                                      return (
                                        <Form.Control
                                          type="text"
                                          id="twitter"
                                          onChange={this.handle}
                                          name="twitter"
                                          className="form-control"
                                          placeholder="https://www.twitter.com/"
                                          aria-label="Recipient's username"
                                          aria-describedby="basic-addon2"
                                        />
                                      );
                                    } else {
                                      return (
                                        <Form.Control
                                          type="text"
                                          id="twitter"
                                          onChange={this.handle}
                                          value={this.state.twitter}
                                          name="twitter"
                                          className="form-control"
                                          placeholder="https://www.twitter.com/"
                                          aria-label="Recipient's username"
                                          aria-describedby="basic-addon2"
                                        />
                                      );
                                    }
                                  })()}
                                  <p className="error" id="errortwitter"></p>
                                </div>
                              </div>
                              <div className="col-md-12 mt-2">
                                <div className="input-group">
                                  <div className="input-group-append">
                                    <button className="btn btn-sm btn-facebook">
                                      <i className="mdi mdi-instagram"></i>
                                    </button>
                                  </div>
                                  {(() => {
                                    if (this.state.instagram === "null") {
                                      return (
                                        <Form.Control
                                          type="text"
                                          id="instagram"
                                          onChange={this.handle}
                                          name="instagram"
                                          className="form-control"
                                          placeholder="https://www.instagram.com/"
                                          aria-label="Recipient's username"
                                          aria-describedby="basic-addon2"
                                        />
                                      );
                                    } else {
                                      return (
                                        <Form.Control
                                          type="text"
                                          id="instagram"
                                          onChange={this.handle}
                                          value={this.state.instagram}
                                          name="instagram"
                                          className="form-control"
                                          placeholder="https://www.instagram.com/"
                                          aria-label="Recipient's username"
                                          aria-describedby="basic-addon2"
                                        />
                                      );
                                    }
                                  })()}
                                  <p className="error" id="errorinstagram"></p>
                                </div>
                              </div>

                              <div className="col-md-12 mt-2">
                                <div className="input-group">
                                  <div className="input-group-append">
                                    <button className="btn btn-sm btn-facebook">
                                      <i className="mdi mdi-linkedin"></i>
                                    </button>
                                  </div>
                                  {(() => {
                                    if (this.state.linkedIn === "null") {
                                      return (
                                        <Form.Control
                                          type="text"
                                          id="linkedIn"
                                          onChange={this.handle}
                                          name="linkedIn"
                                          className="form-control"
                                          placeholder="https://www.linkedin.com/"
                                          aria-label="Recipient's username"
                                          aria-describedby="basic-addon2"
                                        />
                                      );
                                    } else {
                                      return (
                                        <Form.Control
                                          type="text"
                                          id="linkedIn"
                                          onChange={this.handle}
                                          value={this.state.linkedIn}
                                          name="linkedIn"
                                          className="form-control"
                                          placeholder="https://www.linkedin.com/"
                                          aria-label="Recipient's username"
                                          aria-describedby="basic-addon2"
                                        />
                                      );
                                    }
                                  })()}
                                  <p className="error" id="errorlinkedIn"></p>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <button
                                  class="post_jp_btn"
                                  name="submit"
                                  value="Submit"
                                  type="submit"
                                  id="socialAccountSaveButton"
                                >
                                  SAVE CHANGES <i class="fa fa-spin"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>

                        {/* change password */}

                        <form onSubmit={this.submitPassword} id="passwordForm">
                          <div
                            className="change_password"
                            id="password"
                            style={{ display: "none" }}
                          >
                            <h6 className="password_title">Change Password</h6>
                            <div className="password_section mt-4 mb-4">
                              <div className="col-md-12">
                                <Form.Group className="row">
                                  <label className="pl-3">Old Password</label>
                                  <div className="col-sm-12">
                                    <Form.Control
                                      type="password"
                                      onChange={this.handle}
                                      id="oldPassword"
                                      name="oldPassword"
                                      className="form-control-sm"
                                      placeholder="*** Enter Old Password ***"
                                    />
                                    <p className="error" id="erroroldPassword"></p>
                                  </div>
                                </Form.Group>
                              </div>

                              <div className="col-md-12">
                                <Form.Group className="row">
                                  <label className="pl-3">New Password</label>
                                  <div className="col-sm-12">
                                    <Form.Control
                                      type="password"
                                      onChange={this.handle}
                                      id="newPassword"
                                      name="newPassword"
                                      className="form-control-sm"
                                      placeholder="*** Enter New Password ***"
                                    />
                                    <p className="error" id="errornewPassword"></p>
                                  </div>
                                </Form.Group>
                              </div>

                              <div className="col-md-12">
                                <Form.Group className="row">
                                  <label className="pl-3">Repeat New Password</label>
                                  <div className="col-sm-12">
                                    <Form.Control
                                      type="password"
                                      onChange={this.handle}
                                      id="confirmPassword"
                                      name="confirmPassword"
                                      className="form-control-sm"
                                      placeholder="*** Repeat New Password ***"
                                    />
                                    <p className="error" id="errorconfirmPassword"></p>
                                  </div>
                                </Form.Group>
                              </div>

                              <div className="col-md-12 mb-3">
                                <button
                                  class="post_jp_btn"
                                  name="submit"
                                  value="Submit"
                                  type="submit"
                                  id="changePasswordSaveButton"
                                >
                                  CHANGE PASSWORD <i class="fa fa-spin"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                </div>
                <div
                  className="col-md-12 mt-3"
                  id="education"
                  style={{ display: "none" }}
                >
                  <Education />
                </div>
                <div
                  className="col-md-12 mt-3"
                  id="exprience"
                  style={{ display: "none" }}
                >
                  <Exprience />
                </div>
                <div
                  className="col-md-12 mt-3"
                  id="portfolio"
                  style={{ display: "none" }}
                >
                  <Protofolio />
                </div>

                <div
                  className="col-md-12 mt-3"
                  id="Certificate"
                  style={{ display: "none" }}
                >
                  <Certificate />
                </div>

                <div
                  className="col-md-12 mt-3"
                  id="Internship"
                  style={{ display: "none" }}
                >
                  <Internship />
                </div>

                <div
                  className="col-md-12 mt-3"
                  id="Testimonial"
                  style={{ display: "none" }}
                >
                  <Testimonial />

                </div>

                <div
                  className="col-md-12 mt-3"
                  id="Project"
                  style={{ display: "none" }}
                >
                  <Project />
                </div>


                <div
                  className="col-md-12 mt-3"
                  id="Videos"
                  style={{ display: "none" }}
                >
                  <Videos />
                </div>


                <div
                  className="col-md-12 mt-3"
                  id="skill"
                  style={{ display: "none" }}
                >
                  <Skill />
                </div>


                <div
                  className="col-md-12 mt-3"
                  id="language"
                  style={{ display: "none" }}
                >
                  <Language />
                </div>
                <div
                  className="col-md-12 mt-3"
                  id="interest"
                  style={{ display: "none" }}
                >
                  <Interest />
                </div>
                <div
                  className="col-md-12 mt-3"
                  id="uploadResume"
                  style={{ display: "none" }}
                >
                  <UploadResume />
                  {/* <Interest /> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Resume;
