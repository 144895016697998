import React, { Component } from 'react';
import './interview.css';
import Axios from 'axios';
import Spinner from '../shared/Spinner';
import { NavLink } from 'react-router-dom';

class Interview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: "https://www.digitizedresume.com/api/user/",
            data: [],
            dataStatus: "false"
        };
    }

    setUserId = (e) => {
        e.preventDefault();
        // alert(e.target.value);
        sessionStorage.setItem("userId", e.target.value);
    }

    componentDidMount() {
        var id = sessionStorage.getItem("id");
        let formData = new FormData();
        formData.append("id", id);
        var url = this.state.url + "scheduleInterviewData.php";
        Axios.post(url, formData)
            .then((res) => {
                this.setState({
                    data: res.data,
                    dataStatus: "true"
                });
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    viewInterviewInfo = (e) => {
        e.preventDefault();
        // alert(e.target.id);

        sessionStorage.setItem("viewInterviewInfo", "true");
        sessionStorage.setItem("interviewId", e.target.id);
    }

    render() {
        return (
            <div>
                {sessionStorage.getItem("id") ? (
                    <>
                        <div id="messageAlert">
                            <p id="hiredMessage"></p>
                        </div>
                        <div className="page-header">
                            <h3 className="page-title">Scheduled Interview</h3>
                        </div>

                        {this.state.dataStatus === "false" ? (
                            <Spinner />
                        ) : (
                            <div className="row">
                                <div className="col-md-12 grid-margin stretch-card bg-white shadow-sm" style={{ marginTop: "10px" }}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">View Scheduled Interview Details</h4>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>SN.</th>
                                                            <th>Company Name</th>
                                                            <th>Interview Date</th>
                                                            <th>Interview Time</th>
                                                            <th>Status</th>
                                                            <th>Interview Information</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.data.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="6" className="text-center">
                                                                    No Records Found
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            this.state.data.map((ele, i) => {
                                                                return (
                                                                    <tr>                                                                      
                                                                        <td>{i + 1}</td>
                                                                        <td>{ele.companyName}</td>
                                                                        <td>{ele.interviewDate}</td>
                                                                        <td>
                                                                            <input type="time" value={ele.startTime}  disabled className="bg-white border-0" />
                                                                        </td>
                                                                        <td>{ele.interviewStatus}</td>
                                                                        <td>
                                                                            <button onClick={this.viewInterviewInfo} id={ele.id} className="btn btn-primary btn-sm m-0 p-0">
                                                                                <NavLink className="text-center" to="/interview-details">
                                                                                    <button id={ele.id} className="btn btn-primary btn-sm">View</button>
                                                                                </NavLink>
                                                                            </button>


                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ) : false}
            </div>
        )
    }
}
export default Interview;