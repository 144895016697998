import React, { Component } from 'react';
import "./changepassword.css";
import change  from "./change.jpg";

export class ChangePassword extends Component{

    verify = () =>{
        if(document.getElementById("conformpassword").style.display === "none"){
            document.getElementById("conformpassword").style.transition ="5s all";
            document.getElementById("conformpassword").style.display = "block";
         
        }
    }


    render(){
        return(
            <div className="col-md-10 bg-white mx-auto">
                <div className="row">
                    <div className="col-md-6 py-5">
                        <img src={change} className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-5 mx-auto change__password">
                        <h3>Change Password</h3>
                        <input type="password" className="px-2 mt-3" name="password" id="password" placeholder="Enter Password" required /><br></br>
                        <button type="submit" className="verify" onClick={this.verify}>Verify Password</button>

                        <div id="conformpassword" style={{display:'none'}}>
                            <input type="password" className="px-2 mt-3" name="password" id="password" placeholder="New Password" /><br></br>
                            <input type="password" className="px-2 mt-3" name="password" id="password" placeholder="Confirm Password" /><br></br>
                            <button type="submit" className="changes_btn">Save Changes</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}