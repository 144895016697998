import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Spinner from '../shared/Spinner';
import { DarkThemeCV } from './DarkThemeCV';
import { LightThemeCV } from './LightThemeCV';

export class PreviewCV extends Component {

  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/user/",
      id: '',
      domain: "",
      name: "",
      phone: "",
      email: "",
      password: "",
      description: "",
      designation: "",
      availability: "",
      location: "",
      workInfo: "",
      profileImage: "",
      currentProfileImage: "",
      facebook: "",
      instagram: "",
      twitter: "",
      linkedIn: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      dob: "",
      declaration: "",
      maritalStatus: "",
      objective: "",
      address: "",
      themeType: "",
      cv: "",

      universityCollege1: '',
      highestEducation: '',
      highestQualification: '',
      passingYear1: '',
      course1: '',
      courseType1: '',
      specialization1: '',

      universityCollege2: '',
      qualification2: '',
      passingYear2: '',
      course2: '',
      courseType2: '',
      specialization2: '',

      universityCollege3: '',
      qualification3: '',
      passingYear3: '',
      course3: '',
      courseType3: '',
      specialization3: '',

      totalExperience: '',
      currentDesignation: '',
      currentCompany: '',
      startDate: '',
      currentWorkingStatus: 'false',
      endDate: '',
      annualSalary: '',
      currentJobStatus: '',
      noticePeriod: '',
      workingLocation: '',
      role: '',
      pastDesignation: '',
      pastCompany: '',
      pastStartDate: '',
      pastEndDate: '',
      pastRole: '',

      pastWorkExp: [],
      skills: [],
      certificate: [],
      portfolio: [],
      video: [],
      project: [],
      testimonial: [],
      internship: [],

      available: 'false',
      themeStatus: 'false',
      loader: 'true',
    }
  }

  componentDidMount() {
    var currentDomain = localStorage.getItem("domain");
    let formData = new FormData();
    formData.append('domain', currentDomain);
    Axios.post("https://digitizedresume.com/api/user/check.php", formData).then((res) => {
      console.log(res.data);
      if (res.data === 0) {
        // alert("not available");
        this.setState({
          themeStatus: "false",
          available: "false",
          loader: 'false',
        });
      }
      else if (res.data[0].themeType === "Dark Theme") {
        this.setState({
          themeStatus: "Dark Theme",
          available: "true",
          loader: 'true',
          id: res.data[0].id,
        });
        document.getElementById('lightcv').style.display = "none";
      }
      else if (res.data[0].themeType === "Light Theme") {
        this.setState({
          themeStatus: "Light Theme",
          available: "true",
          loader: 'true',
          id: res.data[0].id,
        });
        document.getElementById('lightcv').style.visibility = "visible";
        // setTimeout(function(){ document.getElementById('lightcv').style.visibility = "visible"; }, 3000);
      }
      if (this.state.themeStatus !== "false" && this.state.available === "true") {
        let formData = new FormData();
        var info = this.state.url + "getData.php";
        var id = this.state.id;
        formData.append('id', id);
        Axios.post(info, formData).then((res) => {
          // alert(res.data);
          if (res.data !== 0) {
            this.setState({
              name: res.data[0].name,
              phone: res.data[0].phone,
              email: res.data[0].email,
              domain: res.data[0].domain,
              designation: res.data[0].designation,
              location: res.data[0].location,
              availability: res.data[0].availability,
              profileImage: res.data[0].profileImage,
              facebook: res.data[0].facebook,
              instagram: res.data[0].instagram,
              twitter: res.data[0].twitter,
              linkedIn: res.data[0].linkedIn,
              description: res.data[0].description,
              workInfo: res.data[0].workInfo,
              dob: res.data[0].dob,
              maritalStatus: res.data[0].maritalStatus,
              objective: res.data[0].objective,
              declaration: res.data[0].declaration,
              themeType: res.data[0].themeType,
              address: res.data[0].address,
              cv: res.data[0].cv,

              universityCollege1: res.data[0].universityCollege1,
              highestEducation: res.data[0].highestEducation,
              highestQualification: res.data[0].highestQualification,
              passingYear1: res.data[0].passingYear1,
              course1: res.data[0].course1,
              courseType1: res.data[0].courseType1,
              specialization1: res.data[0].specialization1,

              universityCollege2: res.data[0].universityCollege2,
              qualification2: res.data[0].qualification2,
              passingYear2: res.data[0].passingYear2,
              course2: res.data[0].course2,
              courseType2: res.data[0].courseType2,
              specialization2: res.data[0].specialization2,

              universityCollege3: res.data[0].universityCollege3,
              qualification3: res.data[0].qualification3,
              passingYear3: res.data[0].passingYear3,
              course3: res.data[0].course3,
              courseType3: res.data[0].courseType3,
              specialization3: res.data[0].specialization3,

              totalExperience: res.data[0].totalExperience,
              currentDesignation: res.data[0].currentDesignation,
              currentCompany: res.data[0].currentCompany,
              startDate: res.data[0].startDate,
              currentWorkingStatus: '',
              endDate: res.data[0].endDate,
              annualSalary: res.data[0].annualSalary,
              currentJobStatus: res.data[0].currentJobStatus,
              noticePeriod: res.data[0].noticePeriod,
              workingLocation: res.data[0].workingLocation,
              role: res.data[0].role,
              pastDesignation: res.data[0].pastDesignation,
              pastCompany: res.data[0].pastCompany,
              pastStartDate: res.data[0].pastStartDate,
              pastEndDate: res.data[0].pastEndDate,
              pastRole: res.data[0].pastRole,


            });
          }
        });

        var skills = this.state.url + "getSkillData.php";
        Axios.post(skills, formData)
          .then((res) => {
            this.setState({ skills: res.data });
            // console.log(res.data);
          })
          .catch(function (error) {
            console.log(error);
          });

        var pastWorkExp = this.state.url + 'getPastWorkExperienceData.php';
        Axios.post(pastWorkExp, formData)
          .then(res => {
            // console.log(res.data);
            this.setState({
              pastWorkExp: res.data
            });
          })

        var projectURL = this.state.url + 'getProjectData.php';
        Axios.post(projectURL, formData)
          .then(res => {
            // alert(res.data);
            // console.log(res.data);
            this.setState({
              project: res.data
            });
          })

        var testimonialURL = this.state.url + 'getTestimonialData.php';
        Axios.post(testimonialURL, formData)
          .then(res => {
            // alert(res.data);
            this.setState({
              testimonial: res.data
            });
          })
        var internshipURL = this.state.url + 'getInternshipData.php';
        Axios.post(internshipURL, formData)
          .then(res => {
            // alert(res.data);
            // console.log(res.data);
            this.setState({
              internship: res.data
            });
          })

        var certificateUrl = this.state.url + "getCertificateData.php";
        Axios.post(certificateUrl, formData)
          .then((res) => {
            this.setState({ certificate: res.data });
            // console.log(res.data);
          })
          .catch(function (error) {
            console.log(error);
          });

        var portfolioUrl = this.state.url + "getPortfolioData.php";
        Axios.post(portfolioUrl, formData)
          .then((res) => {
            this.setState({
              portfolio: res.data,
              loader: 'false',
            });
            // console.log(res.data);
          })
          .catch(function (error) {
            console.log(error);
          });

        var videoUrl = this.state.url + "getVideoData.php";
        Axios.post(videoUrl, formData)
          .then((res) => {
            this.setState({
              video: res.data,
              loader: 'false',
            });
            // console.log(res.data);
          })
          .catch(function (error) {
            console.log(error);
          });

      }
    })
  }


  render() {
    return (
      <>
        {(() => {
          if (this.state.loader === "true") {
            return (
              <Spinner />
            )
          } else if (this.state.available === "true" && this.state.loader === "false" && this.state.themeStatus === "Dark Theme") {
            return (
              <div>
                <DarkThemeCV dataState={this.state} />
              </div>
            )
          } else if (this.state.available === "false" && this.state.loader === "false" && this.state.themeStatus === "false") {
            return (
              <div className="container-fluid employee_section">
                <div className="row employe">
                  <div className="col-md-12">
                    <h1 className="employe_title text-danger" data-aos='fade-up' data-aos-duration="2000">Oops smething went wrong..!!</h1>
                    <h6 className="text-center text-dark mt-2 mb-2" data-aos='fade-up' data-aos-duration="2000">The page you are looking for is not found.</h6>
                    <p className="text-center mt-3" data-aos='fade-up' data-aos-duration="2000"><Link className="text-center mx-auto text-primary" to="/">Back to Home</Link></p>
                  </div>
                </div>
              </div>
            )
          }
        })()}
        <LightThemeCV dataState={this.state} />
      </>
    )
  }
}

// export default PreviewCV;