import React, { Component } from 'react';
import './shortlist.css';
import logo from "./company_logo.png";
import logo1 from "./images.png";
import Axios from 'axios';
import Spinner from '../../app/shared/Spinner';

class ShortList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      url: "https://www.digitizedresume.com/api/user/",
      data: [],
      dataStatus: "false"
    };
  }

  componentDidMount() {
    var id = sessionStorage.getItem("id");
    let formData = new FormData();
    formData.append("id", id);
    var url = this.state.url + "singleWishlistData.php";
    Axios.post(url, formData)
      .then((res) => {
        this.setState({
          data: res.data,
          dataStatus: "true"
        });
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    return (
      <div>
        {sessionStorage.getItem("id") ? (
          <>
            <div className="page-header">
              <h3 className="page-title">Shortlisted</h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Shortlisted</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Profile</li>
                </ol>
              </nav>
            </div>

            {this.state.dataStatus === "false" ? (
              <Spinner />
            ) : (
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card bg-white shadow-sm" style={{ marginTop: "10px" }}>
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Project Details</h4>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>SN.</th>
                              <th>Company Name</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.length === 0 ? (
                              <tr>
                                <td colSpan="5" className="text-center">
                                  No Records Found
                                </td>
                              </tr>
                            ) : (
                              this.state.data.map((ele, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{ele.companyName === null ? `Admin - ${ele.adminName}` : `Company - ${ele.companyName}`}</td>
                                    <td>{ele.sortlistDate}</td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : false}
      </div>
    )
  }
}
export default ShortList